import { gql } from '@apollo/client';

export const GET_CURRENCIES = gql`
query getAdminCurrencies {
  getAdminCurrencies {
    name
    description
    active
    currencyid
    lastupdatedby
    createdby
    datetimeupdated
    datetimecreated
    imgid
  }
}
`;