import { gql } from '@apollo/client';

export const CREATE_BIDPACK = gql`
mutation createBidpack($data : AdminBidpack!) {
  createBidpack(data: $data) 
}
`;

export const UPDATE_BIDPACK = gql`
mutation updateBidpack($data : AdminBidpack!) {
  updateBidpack(data: $data) 
}
`;

export const ADD_BIDPACK_IMAGE = gql`
mutation uploadBidpackImage($file: Upload!, $bidpackid: String!){
  uploadBidpackImage(file: $file, bidpackid: $bidpackid)
}
`;

export const DELETE_BIDPACK_IMAGE = gql`
mutation deleteBidpackImage($imageid: String!, $bidpackid: String!){
  deleteBidpackImage(imageid: $imageid, bidpackid: $bidpackid)
}
`;