import Logout from "views/logout";
import {SubDirectory} from "utils/defaults";

import Home from "views/customer/Home";
import AuctionDetails from "views/customer/AuctionDetails";
import Registration from "views/Registration";
import Login from "views/Login";
import Help from "views/Help";
import Contact from "views/Contact";
import Account from "views/customer/Account";
import BidPacks from "views/customer/BidPacks";
import Checkout from "views/customer/Checkout";
import Auctions from "views/admin/auctions";
import Affilates from "views/admin/affilates";
import Categories from "views/admin/categories";
import Prdoucts from "views/admin/products";
import Shipping from "views/admin/shipping";
import Statuses from "views/admin/statuses";
import Customers from "views/admin/customers";
import AdminBidders from "views/admin/adminbidders";
import Reports from "views/admin/reports";
import AdminBidpacks from "views/admin/bidpacks";
import Currency from "views/admin/currency";
import AdminFindUs from "views/admin/findus";

var routes = [
  //Everyone
  {
    showOnNav: true,
    path: "/",
    opath: "/",
    name: "Home",
    icon: "tim-icons icon-chart-pie-36",
    component: Home,
    layout: "/" + SubDirectory,
    loginrequired: false,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: true,
    path: "auctions",
    opath: "auctions",
    name: "Auctions",
    icon: "tim-icons icon-chart-pie-36",
    component: Home,
    layout: "/" + SubDirectory,
    loginrequired: false,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: true,
    path: "help",
    opath: "help",
    name: "Help",
    icon: "tim-icons icon-chart-pie-36",
    component: Help,
    layout: "/" + SubDirectory,
    loginrequired: false,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: true,
    path: "contact",
    opath: "contact",
    name: "Contact",
    icon: "tim-icons icon-chart-pie-36",
    component: Contact,
    layout: "/" + SubDirectory,
    loginrequired: false,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: false,
    path: "registration",
    opath: "registration",
    name: "Provider Login",
    icon: "tim-icons icon-align-center",
    component: Registration,
    layout: "/" + SubDirectory,
    loginrequired: false,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: false,
    path: "login",
    opath: "login",
    name: "Customer Login",
    icon: "tim-icons icon-align-center",
    component: Login,
    layout: "/" + SubDirectory,
    loginrequired: false,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: false,
    path: "auction-details/:id",
    opath: "auction-details/:id",
    name: "Auction Details",
    icon: "tim-icons icon-align-center",
    component: AuctionDetails,
    layout: "/" + SubDirectory,
    loginrequired: false,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: false,
    path: "logout",
    opath: "logout",
    name: "Logout",
    icon: "tim-icons icon-simple-remove",
    component: Logout,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: false,
    path: "account",
    opath: "account",
    name: "Account",
    icon: "tim-icons icon-simple-remove",
    component: Account,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: false,
    path: "checkout",
    opath: "checkout",
    name: "Checkout",
    icon: "tim-icons icon-simple-remove",
    component: Checkout,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  {
    showOnNav: false,
    path: "bidpack",
    opath: "bidpack",
    name: "BidPack",
    icon: "tim-icons icon-simple-remove",
    component: BidPacks,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: [
      "2c0870b4-cf53-4496-a360-a14505d9ec16",
      "7fbf8918-8156-454a-86b3-6e934213c27f",
      "5c83579c-1a7e-4417-85d9-60247a0f9aa5",
      "8e838172-011d-404e-8a73-2885659d2c1c",
      "8d220291-295a-49d5-84e6-6b81db9742ca"
      ],
  },
  //Admin
  {
    showOnNav: false,
    path: "admin/affilates",
    opath: "admin/affilates",
    name: "Affilates",
    icon: "tim-icons icon-align-center",
    component: Affilates,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/auctions",
    opath: "admin/auctions",
    name: "Auctions",
    icon: "tim-icons icon-align-center",
    component: Auctions,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/categories",
    opath: "admin/categories",
    name: "Categories",
    icon: "tim-icons icon-align-center",
    component: Categories,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/products",
    opath: "admin/products",
    name: "Products",
    icon: "tim-icons icon-align-center",
    component: Prdoucts,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/shipping",
    opath: "admin/shipping",
    name: "Shipping",
    icon: "tim-icons icon-align-center",
    component: Shipping,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/statuses",
    opath: "admin/statuses",
    name: "Status",
    icon: "tim-icons icon-align-center",
    component: Statuses,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/customers",
    opath: "admin/customers",
    name: "Customers",
    icon: "tim-icons icon-align-center",
    component: Customers,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/adminbidders",
    opath: "admin/adminbidders",
    name: "Admin Bidders",
    icon: "tim-icons icon-align-center",
    component: AdminBidders,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/reports",
    opath: "admin/reports",
    name: "Reports",
    icon: "tim-icons icon-align-center",
    component: Reports,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/currency",
    opath: "admin/currency",
    name: "Currencies",
    icon: "tim-icons icon-align-center",
    component: Currency,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/bidpacks",
    opath: "admin/bidpacks",
    name: "Bidpacks",
    icon: "tim-icons icon-align-center",
    component: AdminBidpacks,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
  {
    showOnNav: false,
    path: "admin/findus",
    opath: "admin/findus",
    name: "Findus",
    icon: "tim-icons icon-align-center",
    component: AdminFindUs,
    layout: "/" + SubDirectory,
    loginrequired: true,
    access: ["2c0870b4-cf53-4496-a360-a14505d9ec16"],
  },
];
export default routes;
