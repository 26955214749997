import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { useSubscription } from "@apollo/client";
import { UPDATED_CURRENCY } from "graphql/customer/subscriptions/customer";
import AuthProvider from "components/custom/authprovider";
import ProtectedRoute from "components/custom/protectedroute";
import { APPCOOKIE } from "utils/defaults";
import { useAuth } from "components/custom/authprovider";

function Admin(props) {
  const {userData, setUserData}  = useAuth();
  const mainPanelRef = React.useRef(null);

  const { data } = useSubscription(UPDATED_CURRENCY, {
    variables: { displayname: userData? userData.displayname: null },
  });


  useEffect(() => {
    if (data) {
      console.log("CURRENCY UPDATED", data)
      let currentBalances = userData.currency;
      let newBalances = currentBalances;
      let editBalance = currentBalances.find(a => a.currencyid === data.currencyUpdate.currencyid);
      newBalances = currentBalances.filter(a => a.currencyid !== data.currencyUpdate.currencyid);
      if(editBalance){
        editBalance.count = data.currencyUpdate.value
        newBalances.push(editBalance);
      }
      else{
        newBalances.push({
          name: data.currencyUpdate.currencyname,
          count: data.currencyUpdate.value,
          currencyid: data.currencyUpdate.currencyid
        })
      }

      newBalances.sort((a, b) => a.name.localeCompare(b.name));

      let tmp = [];
      newBalances.map((value, key) => {
        return tmp.push(value.count + " " + value.name);
      })

      setUserData({
        displayname: userData.displayname, 
        currency: newBalances, 
        sstring: userData.sstring
      })

      const oldCookie = JSON.parse(localStorage.getItem(APPCOOKIE));
      const TheCookie ={
        wt: oldCookie.wt,
        dt: oldCookie.dt,
        displayname: oldCookie.displayname,
        sstring: oldCookie.sstring,
        currency: newBalances,
      }
      localStorage.setItem( APPCOOKIE, JSON.stringify(TheCookie) );
    }
  }, [data]);
  //

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      // let  ThePath;
      // routes[key].path = prop.opath;
      // ThePath = prop.layout + routes[key].path;

      if(prop.loginrequired){
        return(
          <Route
          path={prop.layout + prop.path}
          key={key}
          render={(props) => (
            <ProtectedRoute 
              {...props} 
              Component={prop.component} 
              access={prop.access} 
            />
          )}
        />
        )
      }
      else{
        const TheComponent = prop.component;
        return(
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={() => <TheComponent />}
          />
        )
      }
    });
  };

  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
            <div id="mwTop">
              <AdminNavbar />
              <Sidebar routes={routes} />
            </div>

            <div className="wrapper">
              <div className={ ""} ref={mainPanelRef} data={color}>
                <div id="mw-main">
                    <Switch>
                      {getRoutes(routes)}
                    </Switch>
                </div>
              
                <Footer />
              </div>
            </div>
     
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
