import { gql } from '@apollo/client';

export const GET_ADMIN_CUSTOMERS = gql`
query getAdminCustomerProfile {
  getAdminCustomerProfile {
    customerid
    firstname
    lastname
    phone
    emailoptin
    birthdate
    gender
    country
    email
    address
    address2
    city
    state
    zip
    active
    securitylevel
    displayname
    findus
    refererid
  }
}
`;

export const GET_ADMIN_CUSTOMER_AUTOBIDS = gql`
query getTheAutoBidders($customerid: String!) {
  getTheAutoBidders(customerid:$customerid){
    currentprice
    currentbuyer
    auctionid
    productname
    value
    type
    datetimeadded
    active
    status
  }
}
`;

export const GET_ADMIN_CUSTOMER_WATCHLIST = gql`
query getTheWatchlist($customerid: String!) {
  getTheWatchlist(customerid:$customerid){
    currentprice
    currentbuyer
    auctionid
    productname
    datetimeadded
    active
    status
  }
}
`;

export const GET_ADMIN_CUSTOMER_RECENTBIDS = gql`
query getTheRecentBids($customerid: String!, $limit: Float!) {
  getTheRecentBids(customerid:$customerid, limit:$limit){
    currentprice
    currentbuyer
    auctionid
    productname
    datetimeadded
    active
    status
    bid
  }
}
`;

export const GET_ADMIN_CUSTOMER_CURRENCIES = gql`
query getAdminCustomerCurrencies($customerid: String!) {
  getAdminCustomerCurrencies(customerid:$customerid){
    currencyname
    currencyid
    exists
    amount
  }
}
`;
