import { useQuery, useSubscription } from "@apollo/client";
import { useAuth } from "components/custom/authprovider";
import XTable from "components/custom/table";
import { GET_RECENT_CUSTOMER_BIDS } from "graphql/customer/queries/auction";
import { GET_RECENT_AUCTION_BIDS } from "graphql/customer/queries/auction";
import { UPDATED_AUCTION } from "graphql/customer/subscriptions/auction";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDate } from "utils/date";
import { getDeviceFingerprint } from "utils/functions";
const { CardHeader, CardBody, Card,  CardTitle } = require("reactstrap");

function RecentBids(props){
  const history = useHistory();
  const {wt}  = useAuth();

  const { loading, error, data, onError, refetch } = useQuery( (props.mode === "auction" ? GET_RECENT_AUCTION_BIDS : GET_RECENT_CUSTOMER_BIDS),{
    variables: {
      auctionid: props.mode === "auction" ? props.auctionid : undefined,
      limit: props.mode === "auction" ? 10 : 100,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [recentbids, setRecentBids] = useState();

  const { data:sdata } = useSubscription(UPDATED_AUCTION, {
    variables: { auctionid: props.auctionid },
  });

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setRecentBids(() => data.getRecentBids);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  useEffect(() => {
    if (sdata) {
      refetch();
        // setAuctionInfo(prevState => { 
        //   return{ ...prevState, 
        //     currentbuyer:  sdata.newAuctionBid.currentbuyer,
        //     currentprice: sdata.newAuctionBid.currentprice,
        //   } 
      // })
    }
  }, [sdata, refetch]);

  const listHeader = [
    {
      Header: "DateTime",
      className: "t-cell-1 text-centered",
      accessor: "datetime",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatDate(props.value)}</span>
      )
    },
  ];

  if(props.mode === "auction"){
    listHeader.push(
      {
        Header: "User",
        accessor: "displayname",
        className: "t-cell-2 text-centered",
        disableFilters: true,
        disableSortBy: true,
        notShowSortingDisplay: true,
      }
    )
  }
  else if(props.mode === "customer"){
    listHeader.push(
      {
        Header: "Type",
        accessor: "bidtype",
        className: "t-cell-2 text-centered",
        disableFilters: true,
        disableSortBy: true,
        notShowSortingDisplay: true,
      }
    );
    listHeader.push(
      {
        Header: "Product",
        accessor: "product",
        className: "t-cell-2 text-centered",
        disableFilters: true,
        disableSortBy: true,
        notShowSortingDisplay: true,
      }
    );
  }

  listHeader.push(
    {
      Header: "Bid",
      accessor: "bid",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
    }
  )

  return (
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">Recent 10 Bids</CardTitle>
      </CardHeader>
      <CardBody className={props.mode === "customer" ? 'customerBids  recentBids' : ' recentBids'} >
        {recentbids &&
          <XTable columns={listHeader} loading={false} data={recentbids} filterbutton={false} />
        }
      </CardBody>
    </Card>
  )
}

export default RecentBids;