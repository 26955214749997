import { gql } from '@apollo/client';
export const PREREG1 = gql`
query preReg1($phone : String!, $displayname: String!, $email: String!) {
  preReg1(phone: $phone, displayname: $displayname, email: $email){
    data
    status
    error
  }
}
`;

export const REGISTER_CUSTOMER = gql`
query registerCustomer($code : String!, $data: CustomerInput!, $fingerprint: Float!) {
  registerCustomer(code: $code, data: $data, fingerprint: $fingerprint){
    status
    error
    token
    dt
    add
    sstring
    displayname
    currency{
      name
      count
      currencyid
    }
  }
}
`;

export const CUSTOMER_INFO = gql`
query customerProfile {
  customerProfile{
    customerData{
      firstname
      lastname
      phone
      address
      city
      state
      zip
      address2
      country
      email
      emailoptin
      gender
      birthdate
    }
    status
    error
  }
}
`;

export const GET_EXTERNAL_REFERENCES = gql`
query customerAffilates{
  customerAffilates{
    data
    status
    error
  }
}
`;

export const PUBLIC_SEND_SMS = gql`
mutation publicSendSMS($type : String!, $phone : String!) {
  publicSendSMS(type: $type, phone : $phone) {
    data
    status
    error
  }
}
`;

export const SEND_SMS = gql`
mutation sendSMS($type : String!) {
  sendSMS(type: $type) {
    data
    status
    error
  }
}
`;

export const VERIFY_SMS = gql`
mutation verifySMS($type : String!, $code : String!) {
  verifySMS(type: $type, code: $code) {
    data
    status
    error
  }
}
`;

export const IMPORT_ACCOUNT_PRIVATE = gql`
query getAAMDistListPrivate($phone : String){
  getAAMDistListPrivate(phone : $phone) {
    data{
      distlist{
        name
        distributorcode
      }
      customer{
        firstname
        lastname
        phone
        gender
        email
        address
        city
        state
        zip
      }
    }
  }
}
`;

export const IMPORT_ACCOUNT_PUBLIC = gql`
query getAAMDistListPublic($phone : String){
  getAAMDistListPublic(phone : $phone) {
    data{
      distlist{
        name
        distributorcode
      }
      customer{
        firstname
        lastname
        phone
        gender
        email
        address
        city
        state
        zip
      }
    }
  }
}
`;