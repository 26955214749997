import { gql } from '@apollo/client';

export const CREATE_AUCTION = gql`
mutation createAuction($data : AdminAuction!) {
  createAuction(data: $data) {
    auctionid
    adminbidders{
      name
      displayname
      customerid
      assigned
      remainingbids
    }
  }
}
`;

export const UPDATE_AUCTION = gql`
mutation updateAuction($data : AdminAuction!) {
  updateAuction(data: $data) 
}
`;

export const ADD_ADMIN_AUTOBIDDER = gql`
mutation addAdminAutobidder($customerid: String!, $auctionid: String!, $count: Float!) {
  addAdminAutobidder(customerid: $customerid, auctionid: $auctionid, count: $count) 
}
`;

export const DELETE_ADMIN_AUTOBIDDER = gql`
mutation deleteAdminAutobidder($customerid: String!, $auctionid: String!) {
  deleteAdminAutobidder(customerid: $customerid, auctionid: $auctionid) 
}
`;