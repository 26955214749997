import { useMutation } from "@apollo/client";
import AdminShipping from "components/admin/shipping/shipping";
import SideNav from "components/admin/shared/sidenav";
import { useState } from "react";
import { CREATE_SHIPPING } from "graphql/admin/mutations/shipping";
import { UPDATE_SHIPPING } from "graphql/admin/mutations/shipping";
import ShippingList from "components/admin/shipping/shippinglist";
import { getDeviceFingerprint } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "components/custom/authprovider";

const { CardBody, Card, Col, Row, Button } = require("reactstrap");

function Shipping(){
  const [view, setView] = useState("list");
  const [selectedRow, setSelectedRow] = useState();
  const [customerrors, setCustomErrors] = useState();
  const history = useHistory();
  const {wt}  = useAuth();

  const EditDetails = (data) => {
    setSelectedRow(data)
    setView("edit");
  }
  const [createShipping] = useMutation(CREATE_SHIPPING, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [updateShipping] = useMutation(UPDATE_SHIPPING, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const AddNew = (data) => {
    createShipping( { variables: 
      {
        data: {
          name:data.name,
          description: data.description,
          rate: data.rate,
          customerid: data.customerid,
          active: data.active
        }
      } 
    } )
    .then((res) => {
      if(res.data)
        setView("list")
    })
  }

  const Edit = (data) => {
    console.log("EDIT");
    updateShipping( { variables: 
      {
        data: {
          name:data.name,
          description: data.description,
          rate: data.rate,
          customerid: data.customerid,
          active: data.active,
          shippingid: data.shippingid
        }
      } 
    } )
    .then((res) => {
      if(res.data)
        setView("list")
    })
  }

  return (
    <div className="content card">
      <Row>
        <Col md="3" lg="2"> <SideNav /> </Col>
        <Col md="9" lg="10">
          <Row>
            <Col md="12">
              <Card className="auctionDetails">
                <CardBody>
                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick = {() => setView("add")}
                    >Add
                  </Button>

                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick = {() => setView("list")}
                    >View List
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              {
                view === "list" ? <ShippingList ViewDetails = { EditDetails } /> : 
                view === "add" ? <AdminShipping mode = "add" title = "Add New Shipping" customerrors = {customerrors} FinalizeFunction = {AddNew} /> :
                view === "edit" ? <AdminShipping mode = "edit" title = "Edit Shipping" customerrors = {customerrors} data = {selectedRow} FinalizeFunction = {Edit} /> :
                ""
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Shipping;