import React, { useEffect, useState } from "react";
import { formatNumber } from "utils/functions";
import { useHistory } from "react-router-dom";
import { makeid } from "utils/functions";
import ReactTooltip from "react-tooltip";
import { SERVERURL } from "utils/defaults";

const { CardHeader, CardBody, CardFooter, Card, Col, Row, Button, CardTitle } = require("reactstrap");

function BidPack(props){
  let history = useHistory();
  const tooltipid = makeid(10);
  const [bidpackinfo, setBidPackInfo] = useState({});
  
  const GoToCheckout = (bidpackid) =>{
    history.push({
      pathname: 'checkout',
      state: {
        type: "bidpack",
        data:{
          currencyname: bidpackinfo.currencyname,
          currencyimg: bidpackinfo.currencyimg,
          bidpackid: bidpackid,
          amount: bidpackinfo.amount,
          currentprice: bidpackinfo.currentprice,
          product: {
            name: bidpackinfo.name,
            shortdescription: bidpackinfo.shortdescription,
            longdescription: bidpackinfo.longdescription,
            mainimgid: bidpackinfo.mainimgid,
            additionalimgids: "",
            productcode: bidpackinfo.productcode,
            keywords: bidpackinfo.keywords,
          }
        }
      }
    })
  }

  useEffect(() => {
    if(props.data){
      setBidPackInfo({
        "name": props.data.name,
        "shortdescription": props.data.shortdescription,
        "longdescription": props.data.longdescription,
        "amount": props.data.amount,
        "currentprice": props.data.price,
        "productcode" : props.data.productcode,
        "bidpackid": props.data.bidpackid,
        "keywords": props.data.keywords,
        "mainimgid": props.data.mainimgid,
        "currencyname" : props.data.bidpackcurrency.name,
        "currencyimg" : props.data.bidpackcurrency.imgid,
        "currencyid" : props.data.bidpackcurrency.currencyid,
      })
    }
  }, [props.data]); //trigger - change to data for auction specific query

  return (
    <div className="content">
    <Row>
        <Col md="12">
          <Card className="miniAuction miniauction3"> 
            <CardHeader>
              <CardTitle tag="h4">{bidpackinfo.name && bidpackinfo.name}</CardTitle>
            </CardHeader>
            <CardBody>
              <img alt="Giftee Bids Bid Pack" src={SERVERURL + "/images/bidpacks/" + bidpackinfo.mainimgid } className="floatLeft mainimg"  />
              <div className="floatRight desc" style={{textAlign: 'right'}}>

                <img alt="Giftee Bids Bid Pack" data-tip data-for={tooltipid} src={SERVERURL + "/images/currency/" + bidpackinfo.currencyimg} className='auctionType' id="auctiontype" />
                <ReactTooltip id={tooltipid}>
                  This is a <span style={{color:'red'}}>{bidpackinfo.currencyname && bidpackinfo.currencyname.toUpperCase()} </span> BidPack. This currency can be used with corresponding auctions.
                </ReactTooltip>
                
                <span className="bold">Price: </span>
                {bidpackinfo.currentprice && formatNumber(bidpackinfo.currentprice / 100)}
                <br />
                <span className="bold">Bid Value: </span>
                {bidpackinfo.amount && bidpackinfo.amount} Bids
                <br />
                {bidpackinfo.shortdescription && bidpackinfo.shortdescription}
              </div>
              <br style={{clear:"both"}} />
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6">
                  <Button 
                  className="btn-fill specialbutton" 
                  color="info" 
                  type="button" 
                  onClick={ () => GoToCheckout(bidpackinfo.bidpackid) }
                  >
                    View
                  </Button>
                </Col>
                <Col md="6">
                  <span className='time floatRight' style={{marginTop:"15px"}}>{bidpackinfo.currencyname}</span>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default BidPack;