import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import {stateArray, countryArray} from "utils/arrays";
import { FormatPhone } from "utils/functions";
import { validateEmail } from "utils/functions";
import { validateZip } from "utils/functions";
import { formatDate } from "utils/date";
import React, { useEffect, useState } from "react";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import {  parseISO } from "date-fns";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { handleInputFocus } from "utils/functions";
import { AppTitle } from "utils/defaults";
import { SAText } from "utils/functions";
import { CUSTOMER_INFO } from "graphql/customer/queries/users";
import { useAuth } from "components/custom/authprovider";
import { getDeviceFingerprint } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UPDATE_CUSTOMER_PROFILE_EMAILPHONE } from "graphql/customer/mutations/users";
import { APPCOOKIE } from "utils/defaults";
import { IMPORT_ACCOUNT_PUBLIC } from "graphql/customer/queries/users";

function Customer(props){
  const {wt, setWT, setIsAuthenticated, userData}  = useAuth();
  const history = useHistory();
  const [view, setView] = useState("profile");
  const [showCode, setShowCode] = useState(false);
  const [code, setCode] = useState();
  const [oldValue, setOldValue] = useState();

  const { loading, error, data, onError } = useQuery(CUSTOMER_INFO, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    skip: (wt && wt.wt && props.mode === 'profile') ? false : true,
      context: {
        headers: {
          authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
          'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
        },
      },
      onError: (errors) => {
        if (errors.networkError) {
          const serverError = errors.networkError.result.errors[0].message;
          if(serverError === "Authentication required."){
            history.push({pathname:"/logout"})
          }
        } 
      },
  });

  const [customerEmailPhoneProfileUpdate] = useMutation(UPDATE_CUSTOMER_PROFILE_EMAILPHONE, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
      context: {
        headers: {
          authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
          'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
        },
      },
      onError: (errors) => {
        if (errors.networkError) {
          const serverError = errors.networkError.result.errors[0].message;
          if(serverError === "Authentication required."){
            history.push({pathname:"/logout"})
          }
        } 
      },
  });

  const [getAAMDistListPublic , { data:importedData}] = useLazyQuery(IMPORT_ACCOUNT_PUBLIC, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    skip: (wt && wt.wt && props.mode === 'profile') ? false : true,
      context: {
        headers: {
          authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
          'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
        },
      },
      onError: (errors) => {
        if (errors.networkError) {
          const serverError = errors.networkError.result.errors[0].message;
          if(serverError === "Authentication required."){
            history.push({pathname:"/logout"})
          }
        } 
      },
  });

  const [fielderrors, setFieldError] = useState([]);
  const [affiliatelinked, setAffiliateLink] = useState(false);

  const [affiliateinfo, setAffiliateInfo] = useState({
    "phone": "",
    "platform": ""
  });

  const [userinfo, setUserInfo] = useState({
    "firstname": "",
    "lastname": "",
    "address": "",
    "address2": "",
    "city": "",
    "state": "",
    "zip": "00000",
    "phone": "",
    "email": "",
    "displayname": "",
    "country": "US",
    "refererid": "",
    "emailoptin": false,
    "birthdate": "",
    "gender": "other",
    "findus": "",
    "existing" : false
  })

  const ResetForm = () => {
    setAffiliateInfo({
      "phone": "",
      "platform": ""
    })

    setUserInfo({
      "firstname": "",
      "lastname": "",
      "address": "",
      "address2": "",
      "city": "",
      "state": "",
      "zip": "00000",
      "phone": "",
      "email": "",
      "displayname": "",
      "country": "US",
      "refererid": "",
      "emailoptin": false,
      "birthdate": "",
      "gender": "other",
      "findus": "",
      "existing" : false
    })

    setAffiliateLink(false);
  }

  const UpdateEmailPhone = () => {
    if(oldValue !== userinfo.phone && oldValue !== userinfo.email){
      customerEmailPhoneProfileUpdate({variables:{
        code: code ? code.toString() : "",
        changeWhat: view,
        value: view === "phone" ? userinfo.phone : userinfo.email
      }})
      .then((result) => {
        if(result.data.customerEmailPhoneProfileUpdate.status){
          if(!code){
            setShowCode(true);
          }
          else{
            const oldCookie = JSON.parse(localStorage.getItem(APPCOOKIE));
            const TheCookie ={
              wt: result.data.customerEmailPhoneProfileUpdate.data,
              dt: oldCookie.dt,
              displayname: oldCookie.displayname,
              sstring: oldCookie.sstring,
              currency: oldCookie.currency,
            }
            const token = localStorage.setItem( APPCOOKIE, JSON.stringify(TheCookie) );
    
            setWT({ 
              wt: TheCookie.wt, 
              dt: TheCookie.dt 
            });
    
            setIsAuthenticated({ token });

            SAText("Success", "Successfully Updated", "success");

            setOldValue();
            setView("profile");
            setCode();
            setShowCode(false)
          }
        }
        else{
          SAText("Error", result.data.customerEmailPhoneProfileUpdate.error, "error");
        }
      })
      .catch((err) => {
        SAText("Error", err, "error");
      })
      .finally(() => {
        //TODO: set loading
      })
    }
  }

  const ImportAccount = () => {
    if(affiliateinfo.platform === "") 
      SAText("Error", "Affiliate is required", "error");
    else if(affiliateinfo.phone === "")
      SAText("Error", "Affiliate phone is required", "error");
    else{
      //TODO: SET LOADING
      getAAMDistListPublic({variables: { phone: affiliateinfo.phone, update:false }})
      .then((res) => {
        if(res.data.getAAMDistListPublic.data.customer){
          if(res.data.getAAMDistListPublic.data.customer.customerid === ""){
            SAText("Error", "No account found", "error");
            ResetForm();
          }
          else{
            //TODO: Make this dynamic and not aam specific
            const ExternalRef = "AAM";
            setUserInfo(prevState => {
              return { ...prevState, firstname: res.data.getAAMDistListPublic.data.customer.firstname,lastname: res.data.getAAMDistListPublic.data.customer.lastname, phone: res.data.getAAMDistListPublic.data.customer.phone, gender: res.data.getAAMDistListPublic.data.customer.gender, email: res.data.getAAMDistListPublic.data.customer.email, address: res.data.getAAMDistListPublic.data.customer.address, city: res.data.getAAMDistListPublic.data.customer.city, state: res.data.getAAMDistListPublic.data.customer.state, zip: parseFloat(res.data.getAAMDistListPublic.data.customer.zip), externalreference: ExternalRef}
            })
            setAffiliateLink(true);
          }
        }
      })
      .catch((error) => {
        SAText("Error", error.message, "error")
      })
    }
  }
  
  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        console.log("This Loads?", data);
        setUserInfo({
          "country": data.customerProfile.customerData.country,
          "firstname": data.customerProfile.customerData.firstname,
          "lastname": data.customerProfile.customerData.lastname,
          "address": data.customerProfile.customerData.address,
          "address2": data.customerProfile.customerData.address2,
          "city": data.customerProfile.customerData.city,
          "state": data.customerProfile.customerData.state,
          "zip": parseInt(data.customerProfile.customerData.zip),
          "phone": data.customerProfile.customerData.phone,
          "email": data.customerProfile.customerData.email,
          "country": data.customerProfile.customerData.country,
          "emailoptin": data.customerProfile.customerData.emailoptin === 'true' ? true : false,
          "birthdate": data.customerProfile.customerData.birthdate,
          "gender": data.customerProfile.customerData.gender,
          "externalreference": ""
        })
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  const UpdateCustomerOnChange = (e, type = '') => {
    if(type !== "array"){
      let value = e.target.value;
      if(e.target.type === 'number'){
        if(value === '') value = 0;
        else value = parseInt(value);
      }
  
      if(type === 'boolean') {
        if(typeof(value) === "string"){
          const a = (value === 'true')
          value = a;
        }
      }
  
      setUserInfo(prevState => {
        return { ...prevState, [e.target.name]: value}
      })
    }
    else{
      setUserInfo(prevState => {
        return { ...prevState, [e.name]:e.value}
      })   
    }
  }

  const CheckError = (e) =>{
    if(fielderrors.length > 0){
      const q = fielderrors.find((a)=>a.field === e);
      if(q) return q.error; else return false;
    }
    else return false;
  }

  const SubmitForm = () => {
    let valid = true;
    setFieldError(() => [])

    if(userinfo.firstname.trim() === "" || userinfo.firstname === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "firstname", error: "First Name is required"}] })
    }

    if(userinfo.lastname.trim() === "" || userinfo.lastname === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "lastname", error: "Last Name is required"}] })
    }

    if(userinfo.address.trim() === "" || userinfo.address === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "address", error: "Address is required"}] })
    }

    if(userinfo.city.trim() === "" || userinfo.city === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "city", error: "City is required"}] })
    }

    if(userinfo.state.trim() === "" || userinfo.state === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "state", error: "State is required"}] })
    }

    if(userinfo.zip <= 0 || userinfo.zip === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "zip", error: "Zip is required"}] })
    }

    if(userinfo.country.trim() === "" || userinfo.country === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "country", error: "Country is required"}] })
    }

    if(userinfo.phone.trim() === "" || userinfo.phone === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "phone", error: "Phone is required"}] })
    }

    if(userinfo.birthdate.trim() === "" || userinfo.birthdate === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "birthdate", error: "Birthdate is required"}] })
    }

    if(userinfo.email.trim() === "" || userinfo.email === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "email", error: "Email is required"}] })
    }
    
    if(!validateEmail(userinfo.email)) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "email", error: "Invalid Format"}] })
    }

    if(!validateZip(userinfo.zip)) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "zip", error: "Invalid Format"}] })
    }

    if(props.mode === "register"){
      if(userinfo.findus.trim() === "" || userinfo.findus === undefined) {
        valid = false;
        setFieldError(prevState => { return [ ...prevState, {field: "findus", error: "Answer is required"}] })
      }

      if(userinfo.displayname.trim() === "" || userinfo.displayname === undefined) {
        console.log("Hit");
        valid = false;
        setFieldError(prevState => { return [ ...prevState, {field: "displayname", error: "Username is required"}] })
      }
    }

    if(valid){
      props.FinalizeFunction(userinfo);
    }
  }

  useEffect(() => {
    if(props.customerrors !== undefined)
      setFieldError(prevState => { return [ ...prevState, props.customerrors] })
  }, [props.customerrors]);

  useEffect(() => {
    if(props.existing){
      console.log("Triggered Existing Customer", props);
      setUserInfo({
        "firstname": props.existing.first_name,
        "lastname":  props.existing.last_name,
        "address": props.existing.address1,
        "address2": props.existing.address2 ? props.existing.address2 : "",
        "city": props.existing.city,
        "state": props.existing.state,
        "zip": parseInt(props.existing.zip),
        "phone": props.existing.phone,
        "email": props.existing.email,
        "displayname": "",
        "country": props.existing.country,
        "refererid": "",
        "emailoptin": false,
        "birthdate": props.existing.birthday,
        "gender": "other",
        "findus": "",
        "existing" : true
      })
    }
  },[props.existing])

  return (
    <div className="content">
      {view === "profile" &&
      <Card className='auctionDetails'>
        <CardHeader>
          <CardTitle tag="h4">{props.title}</CardTitle>
        </CardHeader>
        <CardBody>
          {props.mode === "register" && !props.existing &&
            <div>
              <Row>
                <Col md="12">
                  <p style={{color:"lime", fontSize:"larger"}}>If you already have an account with one of our affiliates, please select it from the drop down and enter your phone number to connect your account. Some information will be imported and auto filled in below, but a {AppTitle} will still need to be created.</p>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label className="subTitle">* Affiliate:</label>
                    <select className={'form-control'}
                      name="platform"
                      value={affiliateinfo.platform}
                      readOnly = {affiliatelinked ? true : false}
                      onChange={ e => { 
                        setAffiliateInfo(prevState => {
                          return { ...prevState, [e.target.name]: e.target.value}
                        })
                      }}
                      maxLength={2}
                    >
                      <option value="">Select an Affiliate</option>
                      <option value="AAM">Active Action Media</option>
                    </select>
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <label className="subTitle">Phone:</label>
                    <Input
                      placeholder="Phone"
                      type="text"
                      name="phone"
                      readOnly = {affiliatelinked ? true : false}
                      value={affiliateinfo.phone}
                      autoComplete = "off"
                      maxLength={60}
                      onChange={ e => FormatPhone(e, setAffiliateInfo) }
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  {!affiliatelinked && 
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      style={{marginTop:"20px"}}
                      onClick = {(e) => ImportAccount()}
                      >
                        Import 
                    </Button>
                  }
                  {affiliatelinked &&
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      style={{marginTop:"20px"}}
                      onClick = {(e) => ResetForm()}
                      >
                        Reset 
                    </Button>
                  }
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  {affiliatelinked &&
                    <span style={{color:"lime", fontSize:"larger", fontWeight: "bold", display:"block", textAlign:"center"}}>ACCOUNT LINKED</span>
                  }
                </Col>
              </Row>
              <hr style={{border:"2px inset gold"}} />
            </div>
          }
        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* First Name:</label>
              <Input
                placeholder="First Name"
                type="text"
                name="firstname"
                className={CheckError("firstname") ? 'error' : ''}
                value={userinfo.firstname}
                readOnly = {props.mode === "register" && affiliatelinked || props.existing ? true : false}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => UpdateCustomerOnChange(e) }
              />
              <span className='errorMessage'>{CheckError("firstname") ? CheckError("firstname") : ''}</span>
            </FormGroup>
          </Col>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Last Name:</label>
              <Input
                placeholder="Last Name"
                type="text"
                name="lastname"
                className={CheckError("lastname") ? 'error' : ''}
                value={userinfo.lastname}
                readOnly = {props.mode === "register" && affiliatelinked || props.existing ? true : false}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => UpdateCustomerOnChange(e) }
              />
              <span className='errorMessage'>{CheckError("lastname") ? CheckError("lastname") : ''}</span>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="subTitle"> * Diplay Name: </label>
              <Input
                placeholder="Display Name" 
                type="text" 
                name="displayname"
                value={props.mode === "register" ? userinfo.displayname : props.existing ? props.existing.displayname : userData.displayname}
                autoComplete = "off"
                maxLength={60}
                readOnly={props.mode === "profile" ? true : props.mode === "register" ? false : false}
                className={CheckError("displayname") ? 'error' : ''}
                onChange={ e => UpdateCustomerOnChange(e) }
                />
                <span className='errorMessage'>{CheckError("displayname") ? CheckError("displayname") : ''}</span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Address:</label>
              <Input
                value={userinfo.address}
                placeholder="Address"
                type="text"
                name="address"
                autoComplete = "off"
                readOnly = {props.mode === "register" && affiliatelinked || props.existing ? true : false}
                className={CheckError("address") ? 'error' : ''}
                onChange={ e => UpdateCustomerOnChange(e) }
                maxLength={400}
              />
              <span className='errorMessage'>{CheckError("address") ? CheckError("address") : ''}</span>
            </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle">* City:</label>
              <Input
                value={userinfo.city}
                placeholder="City"
                type="text"
                name="city"
                autoComplete = "off"
                readOnly = {props.mode === "register" && affiliatelinked || props.existing ? true : false}
                className={CheckError("city") ? 'error' : ''}
                onChange={ e => UpdateCustomerOnChange(e) }
                maxLength={60}
              />
              <span className='errorMessage'>{CheckError("city") ? CheckError("city") : ''}</span>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="subTitle">* State:</label>
              <select className={(CheckError("state") ? 'error' : '') + ' form-control'}
                name="state"
                value={userinfo.state}
                onChange={ e => UpdateCustomerOnChange(e) }
                readOnly = {props.mode === "register" && affiliatelinked || props.existing ? true : false}
                maxLength={2}
              >
                <option value="">Select State</option>
                {stateArray.map((value, key) => {
                  return (
                    <option value={value.abbreviation} key={key}>
                      {value.name}
                    </option>
                  );
                })}
              </select>
              <span className='errorMessage'>{CheckError("state") ? CheckError("state") : ''}</span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Zip Code:</label>
              <Input
                placeholder="12345"
                type="number"
                name="zip"
                readOnly = {props.mode === "register" && affiliatelinked || props.existing ? true : false}
                value={userinfo.zip}
                autoComplete = "off"
                onFocus={handleInputFocus}
                className={CheckError("zip") ? 'error' : ''}
                onChange={ e => UpdateCustomerOnChange(e) }
              />
              <span className='errorMessage'>{CheckError("zip") ? CheckError("zip") : ''}</span>
            </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle"> Address 2: </label>
              <Input
                placeholder="Address 2" 
                type="text" 
                name="address2"
                value={userinfo.address2}
                autoComplete = "off"
                readOnly={props.existing ? true : false}
                onChange={ e => UpdateCustomerOnChange(e) }
                maxLength={400}
                />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="subTitle">* Country:</label>
              <select className={(CheckError("country") ? 'error' : '') + ' form-control'}
                name="country"
                value={userinfo.country}
                onChange={ e => UpdateCustomerOnChange(e) }
                readOnly = {props.existing ? true : false}
                maxLength={4}
              >
                {countryArray.map((value, key) => {
                  return (
                    <option value={value.code} key={key}>
                      {value.name}
                    </option>
                  );
                })}
              </select>
              <span className='errorMessage'>{CheckError("country") ? CheckError("country") : ''}</span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">
                  * Phone: 
                  {props.mode === "profile" && <span onClick={() => {setOldValue(userinfo.phone); setView("phone") }} style={{color:"lime", marginLeft:"100px"}}> Click to Update Phone</span> }
                </label>
              <Input
                placeholder="Phone"
                type="text"
                readOnly = {props.mode === "register" && affiliatelinked || props.existing || props.mode === "profile" ? true : false}
                name="phone"
                value={userinfo.phone}
                autoComplete = "off"
                maxLength={20}
                className={CheckError("phone") ? 'error' : ''}
                // onChange={ e => UpdateCustomerOnChange(e) }
                onChange={ e => FormatPhone(e, setUserInfo) }
              />
              <span className='errorMessage'>{CheckError("phone") ? CheckError("phone") : ''}</span>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="subTitle">
                * Email:
              {props.mode === "profile" && <span  onClick={() => {setOldValue(userinfo.email); setView("email") }} style={{color:"lime", marginLeft:"100px"}}> Click to Update Email</span> }
              </label>
              <Input
                value={userinfo.email}
                placeholder="Email Address"
                type="text"
                readOnly = {props.mode === "register" && affiliatelinked || props.existing || props.mode === "profile" ? true : false}
                name="email"
                autoComplete = "off"
                maxLength={100}
                className={CheckError("email") ? 'error' : ''}
                onChange={ e => UpdateCustomerOnChange(e) }
              />
              <span className='errorMessage'>{CheckError("email") ? CheckError("email") : ''}</span>
            </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle"> Email Opt In: </label>
              <select className='form-control'
                name="emailoptin"
                value={userinfo.emailoptin}
                onChange={ e => UpdateCustomerOnChange(e, 'boolean') }
              >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
              </select>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="pr-md-1" md="4">
          <FormGroup>
            <label className="subTitle"> * Birth Date </label>
            <Datetime 
              value={parseISO(userinfo.birthdate)}
              timeFormat = {false}
              className={CheckError("birthdate") ? 'error' : ''}
              inputProps = {{disabled: props.existing || props.mode === "profile" ? true : false}}
              onChange={(e) => {
                if(e._d !== undefined){
                  setUserInfo(prevState => {
                    return { ...prevState, birthdate: formatDate(e._d)}
                  })
                }
                else{
                  if(props.mode === "register"){
                    setUserInfo(prevState => {
                      return { ...prevState, birthdate: ''}
                    })
                  }
                }
              }}
            /> 
            <span className='errorMessage'>{CheckError("birthdate") ? CheckError("birthdate") : ''}</span>
          </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle"> Gender: </label>
              <select className='form-control'
                name="gender"
                value={userinfo.gender}
                onChange={ e => UpdateCustomerOnChange(e) }
              >
              <option value="other">Other</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              </select>
            </FormGroup>
          </Col>
          {props.mode === "register" &&
            <Col  md="4">
              <FormGroup>
                <label className="subTitle">Referrer Username:</label>
                <Input
                  value={userinfo.refererid}
                  placeholder="Referrer"
                  type="text"
                  name="refererid"
                  maxLength={60}
                  autoComplete = "off"
                  onChange={ e => UpdateCustomerOnChange(e) }
                />
              </FormGroup>
            </Col>
            }
        </Row>

        <Row>
          {props.mode === "register" && props.findusReason &&
            <Col  md="4">
            <FormGroup>
              <label className="subTitle">* How did you find us?:</label>
              <select className={(CheckError("findus") ? 'error' : '') + ' form-control'}
                name="findus"
                value={userinfo.findus}
                onChange={ e => UpdateCustomerOnChange(e) }
              >
              <option value="">select an answer</option>
              {props.findusReason.map((value, key) => {
                return(
                  <option value={value.name} key={key}>{value.name}</option>
                )
              })}
              </select>
              <span className='errorMessage'>{CheckError("findus") ? CheckError("findus") : ''}</span>
            </FormGroup>
            </Col>
            }
          
        </Row>
        </CardBody>
        <CardFooter>
        <Button 
          className="btn-fill floatRight" 
          color="info" 
          type="button" 
          onClick = {(e) => SubmitForm()}
          >
            {props.mode === "register" ? "Complete Registration" : "Update Account"}
          </Button>
        </CardFooter>
      </Card>
      }

      {view === "email" &&
        <Card className='auctionDetails'>
       <CardHeader>
         <CardTitle tag="h4">Update Profile Email Address</CardTitle>
       </CardHeader>
       <CardBody>
        <Row>
          <Col className="pr-md-1" md="12">
            <FormGroup>
              <label className="subTitle">
                  * Email: 
                </label>
              <Input
                placeholder="email"
                type="text"
                name="email"
                value={userinfo.email}
                autoComplete = "off"
                maxLength={100}
                onChange={ e => UpdateCustomerOnChange(e) }
              />
            </FormGroup>
          </Col>
        </Row>
        {showCode &&
          <Row>
            <Col className="pr-md-1" md="12">
              <FormGroup>
                <label className="subTitle">Access Code</label>
                <Input
                  placeholder="12345"
                  type="number"
                  name="code"
                  value={code}
                  autoComplete = "off"
                  onFocus={handleInputFocus}
                  onChange={ e => {
                    let value = e.target.value
                    if(value === '') value = 0
                    else value = parseInt(value);
                    setCode(value)
                  }
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        }
    </CardBody>
       <CardFooter>
       <Button 
         className="btn-fill floatLeft" 
         color="info" 
         type="button" 
         onClick = {(e) => setView("profile")}
         >
          Back to Full Profile
         </Button>
       <Button 
         className="btn-fill floatRight" 
         color="info" 
         type="button" 
         onClick = {(e) => UpdateEmailPhone()}
         >
          Update Email
         </Button>
       </CardFooter>
        </Card>
      } 
      {view === "phone" &&
        <Card className='auctionDetails'>
       <CardHeader>
         <CardTitle tag="h4">Update Profile Phone Number</CardTitle>
       </CardHeader>
       <CardBody>
        <Row>
          <Col className="pr-md-1" md="12">
            <FormGroup>
              <label className="subTitle">
                  * Phone: 
                </label>
              <Input
                placeholder="Phone"
                type="text"
                name="phone"
                value={userinfo.phone}
                autoComplete = "off"
                maxLength={20}
                className={CheckError("phone") ? 'error' : ''}
                onChange={ e => FormatPhone(e, setUserInfo) }
              />
              <span className='errorMessage'>{CheckError("phone") ? CheckError("phone") : ''}</span>
            </FormGroup>
          </Col>
        </Row>
        {showCode &&
          <Row>
            <Col className="pr-md-1" md="12">
              <FormGroup>
                <label className="subTitle">Access Code</label>
                <Input
                  placeholder="12345"
                  type="number"
                  name="code"
                  value={code}
                  autoComplete = "off"
                  onFocus={handleInputFocus}
                  onChange={ e => {
                    let value = e.target.value
                    if(value === '') value = 0
                    else value = parseInt(value);
                    setCode(value)
                  }
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        }
    </CardBody>
       <CardFooter>
       <Button 
         className="btn-fill floatLeft" 
         color="info" 
         type="button" 
         onClick = {(e) => setView("profile")}
         >
          Back to Full Profile
         </Button>
       <Button 
         className="btn-fill floatRight" 
         color="info" 
         type="button" 
         onClick = {(e) => UpdateEmailPhone()}
         >
          Update Phone Number
         </Button>
       </CardFooter>
        </Card>
      } 
    </div>
  )
}

export default Customer;