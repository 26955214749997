import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import request from "graphql-request";
import { useMutation } from "@apollo/client";
import { SERVERURL } from "utils/defaults";
import { DELETE_BIDPACK_IMAGE } from "graphql/admin/mutations/bidpacks";
import { ADD_BIDPACK_IMAGE } from "graphql/admin/mutations/bidpacks";
import { IMAGEUPLOAD } from "utils/defaults";
import { getDeviceFingerprint } from "utils/functions";
import { useAuth } from "components/custom/authprovider";

function AdminBidpackImage(props){
  const {wt}  = useAuth();
  const [picerror, setPicError] = useState();
  const [currentpic, setCurrentPic] = useState();

  const hiddenFileInput = React.useRef(null);
  
  const [deleteBidpackImage] = useMutation(DELETE_BIDPACK_IMAGE, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const requestHeaders = {
    authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
    'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
  }


  const deleteImage = (imgid) => {
    deleteBidpackImage( { variables: {imageid: imgid.toLowerCase(), bidpackid: props.bidpackid.toLowerCase()} } )
    .then((e)=> setCurrentPic(null))
  }

  const handleClick = event => hiddenFileInput.current.click();

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    if(fileUploaded.size < 20000000 && (fileUploaded.type === 'image/jpeg' || fileUploaded.type === 'image/jpg' || fileUploaded.type === 'image/png') ){
      request(
          IMAGEUPLOAD, 
          ADD_BIDPACK_IMAGE, 
          { file: fileUploaded, bidpackid: props.bidpackid},
          requestHeaders
        )
      .then((e)=> {
        setCurrentPic(e.uploadBidpackImage)
      })
    }
    else
      setPicError("Image must be less than 20mb and be a jpg or png file")
  };


  useEffect(() => {
    if(props.mainimgid !== undefined)
    setCurrentPic(props.mainimgid)
  }, [props.mainimgid]);

  return (
    <Row style={{marginTop:"20px"}}>
      <Col md="12">
        <Card className="auctionDetails" >
          <CardHeader>
            <CardTitle tag="h4">Image Mangement </CardTitle>
          </CardHeader>
          <CardBody>
            {props &&
              <Row>
                {currentpic != "" && currentpic && currentpic != null &&
                  <Col md="1">
                    <Button className="productImageDeleteButton" onClick={() => deleteImage(currentpic, true)}> Delete </Button>
                    <div className='mainproductimg'>
                      <img className = "productimg" src={SERVERURL + "/images/bidpacks/" + currentpic} />
                    </div>
                  </Col>
                }
              </Row>
            }

            <br /><br />
            <Button onClick={handleClick}
              className="btn-fill specialbutton" 
              color="info" 
              type="button" >
              Upload a New Picture
            </Button>
            <input type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              accept="image/jpeg, image/jpg, image/png" 
              style={{display:'none'}} 
            /> 
            <br />
            <small style={{color:"lime"}}>JPG and PNG Files only. Max Size = 20 mb</small>
            <br />
            <span className='errorMessage'>{picerror}</span> <br />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AdminBidpackImage;