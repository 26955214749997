const { CardHeader, CardBody, CardFooter, Card, Col, Row } = require("reactstrap");

function Contact(){
  return (
    <div className="content">
    <Row>
        <Col md="6">
          <Card>
            <CardHeader>Contact</CardHeader>

            <CardBody>Dev</CardBody>

            <CardBody>Hello World</CardBody>

            <CardBody>Hello Universe</CardBody>

            <CardFooter></CardFooter>
          </Card>
        </Col>
        <Col md="6">
        <Card>
            <CardHeader></CardHeader>
            <CardBody></CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Contact;