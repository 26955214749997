export const SubDirectory = "";

export const SMSAlert = {
  "title": "Confirm Phone",
  "label": "You have been sent an SMS Code. Please type the code in below to continue.",
  "icon": "info"
}

export const BidTimeout = 3;

export const AppTitle = "Giftee Bids";

export const status = {
  auctionactive: "7f253427-2402-ed11-a250-00155d069108",
  wonauction: "a8e21525-d95f-4adc-a6e7-c4d591045953",
  wonbuyitnow: "cbbaf5b7-9649-4d53-977b-d596ae903d1c",
  auctionexpired: "55bb3721-fbac-4387-b832-2d0223428eb8",
  auctionrejected: "a65df00f-c2ef-40b7-9937-b7a4b8addb74",
}

const SERVERURL =
  process.env.REACT_APP_APIURL === "localhost"
    ? `http://${process.env.REACT_APP_APIURL}:${process.env.REACT_APP_SERVERPORT}`
    : `https://${process.env.REACT_APP_APIURL}:${process.env.REACT_APP_SERVERPORT}`;

const IMAGEUPLOAD =
  process.env.REACT_APP_APIURL === "localhost"
    ? `http://${process.env.REACT_APP_APIURL}:${process.env.REACT_APP_SERVERPORT}/graphql`
    : `https://${process.env.REACT_APP_APIURL}:${process.env.REACT_APP_SERVERPORT}/graphql`;

export { SERVERURL, IMAGEUPLOAD };

export const APPCOOKIE = "giftee";