import { gql } from '@apollo/client';

export const GET_WON_AUCTIONS = gql`
query getWonAuctions($customerid : String) {
  getWonAuctions(customerid: $customerid) {
    auctionlist{
      auctionid
      product{
        productid
        name
        category{
          categoryid
          name
        }
        productcode
        shortdescription
        longdescription
        keywords
        retailprice
        qty
        mainimgid
        additionalimgids
      }
      shipping{
        shippingid
        name
        description
        rate
      }
      biddingcurrency{
        name
        imgid
      }
      currentbuyer{
        displayname
      }
      active
      currentprice
      status{
        name
        statusid
      }
      startdatetime
      enddatetime
      bidmultiplier
      auctionmode{
        auctionmodeid
        name
        description
      }
      startingprice,
      featured
      buyitnow
      buyitnowprice
      finalized
    },
    servertime
  }
}
`;