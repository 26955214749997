import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { ADD_PRODUCT_IMAGE } from "graphql/admin/mutations/products";
import request from "graphql-request";
import { GET_PRODUCT_IMAGES } from "graphql/admin/queries/products";
import { useMutation, useQuery } from "@apollo/client";
import { SERVERURL } from "utils/defaults";
import { SAText } from "utils/functions";
import { UPDATE_MAIN_IMAGE } from "graphql/admin/mutations/products";
import { DELETE_IMAGE } from "graphql/admin/mutations/products";
import { IMAGEUPLOAD } from "utils/defaults";
import { getDeviceFingerprint } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "components/custom/authprovider";

function AdminImage(props){
  const history = useHistory();
  const {wt}  = useAuth();
  const [picerror, setPicError] = useState();
  const hiddenFileInput = React.useRef(null);
  
  const [deleteProductImage] = useMutation(DELETE_IMAGE, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [updateMainImage] = useMutation(UPDATE_MAIN_IMAGE, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  
  const requestHeaders = {
    authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
    'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
  }

  const setAsMain = (imgid) => {
    updateMainImage( { variables: {imageid: imgid.toLowerCase(), productid: props.productid.toLowerCase()} } )
    .then((e) => {
      if(e) refetch();
    })
  }

  const deleteImage = (imgid, isMain = false) => {
    deleteProductImage( { variables: {imageid: imgid.toLowerCase(), productid: props.productid.toLowerCase(), main: isMain} } )
    .then((e) => {
      if(e) refetch();
    })
  }

  const handleClick = event => hiddenFileInput.current.click();

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    if(fileUploaded.size < 20000000 && (fileUploaded.type === 'image/jpeg' || fileUploaded.type === 'image/jpg' || fileUploaded.type === 'image/png') ){
      // console.log("FILE", fileUploaded)
      let isMain = false; 
      if(!productimages || !productimages.mainimgid || productimages.mainimgid === "" || productimages.mainimgid === null) isMain = true;

      request(
        IMAGEUPLOAD, 
        ADD_PRODUCT_IMAGE, 
        { file: fileUploaded, productid: props.productid, main: isMain},
        requestHeaders
      )
      .then((e) => refetch())
    }
    else
      setPicError("Image must be less than 20mb and be a jpg or png file")
  };

  const { loading, error, data, onError, refetch } = useQuery( GET_PRODUCT_IMAGES,{
    variables:{productid: props.productid},
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [productimages, setProductImages] = useState();

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setProductImages(() => data.getAdminProductImages);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  return (
    <Row style={{marginTop:"20px"}}>
      <Col md="12">
        <Card className="auctionDetails" >
          <CardHeader>
            <CardTitle tag="h4">Image Mangement  <span style={{color:"lime", fontSize:"smaller"}}> (Image with Green Border is the Main Product Image)</span></CardTitle>
          </CardHeader>
          <CardBody>
            {productimages &&
              <Row>
                {productimages.mainimgid != "" && productimages.mainimgid && productimages.mainimgid != null &&
                  <Col md="1">
                    <Button className="productImageDeleteButton" onClick={() => deleteImage(productimages.mainimgid, true)}> Delete </Button>
                    <div className='mainproductimg'>
                      <img className = "productimg" src={SERVERURL + "/images/products/" + props.productid + "/" + productimages.mainimgid} />
                    </div>
                  </Col>
                }
                {productimages.additionalimgids && productimages.additionalimgids.length > 0 &&
                  productimages.additionalimgids.map((value, key) => {
                    if(value !== "" && value !== null){
                      return (
                        <Col md="1" key={key}>
                          <Button className="productImageDeleteButton" onClick={() => deleteImage(value)}> Delete </Button>
                          <img className = "productimg" src={SERVERURL + "/images/products/" + props.productid + "/" + value} />
                          <Button className="productImageSetMainButton" onClick={() => setAsMain(value)}> Set As Main </Button>
                        </Col>
                      )
                    }
                    else return false
                  })
                }
              </Row>
            }

            <br /><br />
            <Button onClick={handleClick}
              className="btn-fill specialbutton" 
              color="info" 
              type="button" >
              Upload a New Picture
            </Button>
            <input type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              accept="image/jpeg, image/jpg, image/png" 
              style={{display:'none'}} 
            /> 
            <br />
            <small style={{color:"lime"}}>JPG and PNG Files only. Max Size = 20 mb</small>
            <br />
            <span className='errorMessage'>{picerror}</span> <br />
          </CardBody>
        </Card>

       
      </Col>
    </Row>
  )
}

export default AdminImage;