import { useMutation, useQuery } from "@apollo/client";
import { useAuth } from "components/custom/authprovider";
import XTable from "components/custom/table";
import { UPDATE_CUSTOMER_CURRENCY } from "graphql/admin/mutations/customer";
import { GET_ADMIN_CUSTOMER_CURRENCIES } from "graphql/admin/queries/customer";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDeviceFingerprint } from "utils/functions";
import { formatNumber } from "utils/functions";
const { CardHeader, CardBody, Card, CardTitle, Button, Input, Row, Col, FormGroup, CardFooter } = require("reactstrap");

function AdminCurrencyList(props){
  const history = useHistory();
  const {wt}  = useAuth();

  const { loading, error, data, onError, refetch } = useQuery( GET_ADMIN_CUSTOMER_CURRENCIES,{
    variables:{customerid: props.customerid}, 
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [displaylist, setList] = useState();
  const [editCurrency, setCurrencyOverride] = useState();
  const [fielderrors, setFieldError] = useState([]);

  const [updateAdminCustomerCurrency] = useMutation(UPDATE_CUSTOMER_CURRENCY, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const CheckError = (e) =>{
    if(fielderrors.length > 0){
      const q = fielderrors.find((a)=>a.field === e);
      if(q) return q.error; else return false;
    }
    else return false;
  }

  const CurrencyOverride = () => {
    let valid = true;
    setFieldError(() => [])

    if(editCurrency.notes.trim() === "" || editCurrency.notes === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "notes", error: "Notes are required"}] })
    }

    if(editCurrency.amount.toString().trim() === "" || editCurrency.amount === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "amount", error: "Amount is required"}] })
    }

    if(valid){
      updateAdminCustomerCurrency({variables: {
        data: editCurrency
      }})
      .then((e) => {
        if(e.data){
          refetch();
          setCurrencyOverride(undefined);
        }
      })
    }
  }

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setList(() => data.getAdminCustomerCurrencies);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);


  const listHeader = [
    {
      Header: "Currency",
      accessor: "currencyname",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Amount",
      accessor: "amount",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatNumber(props.value)}</span>
      )
    },
    {
      Header: "Actions",
      accessor: "currencyid",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (propsa) =>(
        <Button
        className="btn-fill specialbutton" 
        color="info" 
        type="button" 
        // onClick ={ (e) => CurrencyOverride(propsa.row.original) }
        onClick ={ (e) => {
          setCurrencyOverride(propsa.row.original) 
          setCurrencyOverride(prevState => {
            return { ...prevState, notes: "", customerid: props.customerid}
          })
        }}
        >Edit</Button>
      )
    },
  ];

  return (
  <>
  {editCurrency === undefined &&
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">Currency List | <span style={{color:"lime", fontSize:"smaller"}}>{props.title}</span></CardTitle>
      </CardHeader>
      <CardBody className={ ' wonauctions'} >
        {displaylist &&
          <XTable columns={listHeader} loading={false} data={displaylist} filterbutton={false} />
        }
      </CardBody>
    </Card>
  }

  {editCurrency !== undefined &&
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">Currency Override | <span style={{color:"lime", fontSize:"smaller"}}>{props.title + " - " + editCurrency}</span> </CardTitle> 
      </CardHeader>
      <CardBody className={ ' wonauctions'} >
        {/* EDIT CURRENCY - SHOW INPUT AND TEXTTAREA FOR REASON - SET editCURRENCY BACK TO UNDEFINED ON FINISH */}
        <Row>
          <Col md="6">
            <FormGroup>
            <label className="subTitle">Currency:</label>
              <Input
                value={editCurrency.currencyname}
                placeholder="currencyname"
                readOnly = {true}
                type="text"
                name="currencyname"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
            <label className="subTitle">Amount:</label>
              <Input
                value={editCurrency.amount}
                placeholder="amount"
                type="text"
                className={CheckError("amount") ? 'error' : ''}
                name="amount"
                onChange={(e) => {
                  setCurrencyOverride(prevState => {
                    return { ...prevState, amount: parseFloat(e.target.value)}
                  })
                }}
              />
              <span className='errorMessage'>{CheckError("amount") ? CheckError("amount") : ''}</span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="subTitle"> * Notes: </label>
              <Input
                placeholder="notes" 
                cols="80"
                rows="4"
                type="textarea"
                name="notes"
                value={editCurrency.notes}
                autoComplete = "off"
                className={CheckError("notes") ? 'error' : ''}
                onChange={ e => {
                  setCurrencyOverride(prevState => {
                    return { ...prevState, notes: e.target.value}
                  })
                } }
                />
                <span className='errorMessage'>{CheckError("notes") ? CheckError("notes") : ''}</span>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Button 
          className="btn-fill floatRight" 
          color="info" 
          type="button" 
          onClick = {(e) => CurrencyOverride()}
          >
            Update Currency
          </Button>
      </CardFooter>
    </Card>
  }
  </>
  )
}

export default AdminCurrencyList;