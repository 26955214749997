import { gql } from '@apollo/client';

export const CREATE_STATUS = gql`
mutation createStatus($data : AdminStatus!) {
  createStatus(data: $data) 
}
`;

export const UPDATE_STATUS = gql`
mutation updateStatus($data : AdminStatus!) {
  updateStatus(data: $data) 
}
`;