const { CardHeader, CardBody, CardFooter, Card, Col, Row } = require("reactstrap");

function Help(){
  return (
    <div className="content">
    <Row>
        <Col md="6">
          <Card>
            <CardHeader>HELP</CardHeader>
            <CardBody></CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
        <Col md="6">
        <Card>
            <CardHeader></CardHeader>
            <CardBody></CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Help;