import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import Datetime from 'react-datetime';
import { formatDate } from "utils/date";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { GET_CATEGORIES } from "graphql/admin/queries/categories";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "graphql/admin/queries/products";
import { format, parseISO } from "date-fns";
import { handleInputFocus } from "utils/functions";
import { GET_SHIPPING } from "graphql/admin/queries/shipping";
import { GET_CURRENCIES } from "graphql/admin/queries/currencies";
import { useHistory } from "react-router-dom";
import XTable from "components/custom/table";
import { ADD_ADMIN_AUTOBIDDER } from "graphql/admin/mutations/auctions";
import { DELETE_ADMIN_AUTOBIDDER } from "graphql/admin/mutations/auctions";
import { GET_PRODUCT_IMAGES } from "graphql/admin/queries/products";
import { SERVERURL } from "utils/defaults";
import { MultiSelect } from "react-multi-select-component";
import { getDeviceFingerprint } from "utils/functions";
import { useAuth } from "components/custom/authprovider";
import { IMPORT_ACCOUNT_ADMIN } from "graphql/admin/queries/auction";

function AdminAuction(props){
  let history = useHistory();
  const {wt}  = useAuth();

  const { data: importedData } = useQuery( IMPORT_ACCOUNT_ADMIN,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [distlist, setDistList] = useState([]);
  const [selected, setSelected] = useState([]);

  const [newautobidder, setNewAutobidder] = useState({
    "customerid": "",
    "value" : 0
  });

  const [fielderrors, setFieldError] = useState([]);  
  const [auctioninfo, setAuctionInfo] = useState({
    "auctionid": "",
    "product": "",
    "startdatetime": "",
    "enddatetime": "",
    "startingprice": "",
    "buyitnowprice": "",
    "bidmultiplier": "",
    "active": "",
    "buyitnow": "",
    "featured": "",
    "global": "",
    "platform": "",
    "distlist" : [],
    "shipping": "",
    // "status": "",
    "productinfo": {
      "name": "",
      "longdescription": "",
      "keywords": "",
      "retailprice": "",
      "qty": "",
      "productcode": "",
      "productid": "",
      "categoryid": "",
    },
    "biddingcurrency": "",
    "adminnotes": "",
  })
  
  const [currentcategory, setCategory] = useState(null)

  const { data, refetch} = useQuery( GET_PRODUCTS,{
    variables:{categoryid: currentcategory},
    skip: (currentcategory && currentcategory !== "bidpack") ? false : true,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const {  data:pimages, refetch:refetchpimages } = useQuery( GET_PRODUCT_IMAGES,{
    variables:{productid: auctioninfo.productinfo.productid},
    skip: (auctioninfo.productinfo.productid) ? false : true,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const { data: cdata } = useQuery( GET_CATEGORIES,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const {data: sdata } = useQuery( GET_SHIPPING,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });


  const {data: ccdata } = useQuery( GET_CURRENCIES,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  useEffect(() => {
    if(props.mode === "add"){
      setCategory("")
      setAuctionInfo({    
        "auctionid": "",
        "product": "",
        "startdatetime": "",
        "enddatetime": "",
        "startingprice": 0.01,
        "buyitnowprice": 1,
        "bidmultiplier": 1,
        "buyitnow": false,
        "featured": false,
        "global": true,
        "platform": "",
        "distlist" : [],
        "currentbuyer": "",
        "shipping": "",
        // "status": "",
        "productinfo": {
          "name": "",
          "longdescription": "",
          "keywords": "",
          "retailprice": "",
          "qty": "",
          "productcode": "",
          "productid": "",
          "categoryid": "",
        },
        "biddingcurrency": "",
        "adminnotes": "",
        "adminbidders":[],
        // "adminbidders":{
        //   "name":"",
        //   "displayname": "",
        //   "customerid": "",
        //   "assigned":"",
        //   "remainingbids": ""
        // },
        "active": true,
      })
    }
  }, [ props.mode]);

  //AUCTION EDIT
  useEffect(() => {
      if(props.data){
        setAuctionInfo(() => props.data);

      if(props.data.productinfo){
          if(props.data.productinfo.categoryid){
            setCategory(props.data.productinfo.categoryid);
            
            setAuctionInfo(prevState => {
              return { ...prevState,  product: props.data.productinfo.productid}
            })   

            if(props.data.distlist){
              let tmpSelected = []
              distlist.map((v) => {
                console.log(v);
                if(props.data.distlist.includes(v.value)){
                  return tmpSelected.push(v)
                }
                else return false;
              })
              setSelected(() => tmpSelected)
            }
          }
        }
      }
  }, [props.data, distlist]);

  useEffect(() => {
    let tmp = [];
    if(selected.length > 0){
      selected.map((value) => {
        return tmp.push(value.value)
      })
    }
    setAuctionInfo(prevState => {
      return { ...prevState,  distlist: tmp}
    })  
  }, [selected]);

  useEffect(() => {
    if(currentcategory) refetch()
  }, [currentcategory, refetch]);


  useEffect(() => {
    if(auctioninfo.productinfo.productid) refetchpimages()
  }, [auctioninfo.productinfo.productid, refetchpimages]);

  useEffect(() => {
    if(importedData){
      let tmpArray = [];
      importedData.getAAMDistListAdmin.data.distlist.map((value) => {
        return(
          tmpArray.push({
            label: value.name + "(" + value.distributorcode + ")",
            value: value.distributorid
          })
        )
      })
      setDistList( () => tmpArray);
    }
}, [importedData]);


  const UpdateChange = (e, type = '') => {
    if(type !== "array"){
      let value = e.target.value;
      if(e.target.type === 'number'){
        if(value === '') value = 0;
        else value = parseFloat(value);
      }
  
      if(type === 'boolean') {
        if(typeof(value) === "string"){
          const a = (value === 'true')
          value = a;
        }
      }
  
      setAuctionInfo(prevState => {
        return { ...prevState, [e.target.name]: value}
      })
    }
    else{
      setAuctionInfo(prevState => {
        return { ...prevState, [e.name]:e.value}
      })   
    }
  }

  const CheckError = (e) =>{
    if(fielderrors.length > 0){
      const q = fielderrors.find((a)=>a.field === e);
      if(q) return q.error; else return false;
    }
    else return false;
  }

  const SubmitForm = () => {
    let valid = true;
    setFieldError(() => [])

    if(auctioninfo.product.trim() === "" || auctioninfo.product === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "product", error: "Product is required"}] })
    }

    if(auctioninfo.startdatetime.trim() === "" || auctioninfo.startdatetime === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "startdatetime", error: "Start Date is required"}] })
    }

    if(auctioninfo.enddatetime.trim() === "" || auctioninfo.enddatetime === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "enddatetime", error: "End Date is required"}] })
    }

    if(auctioninfo.startingprice === 0 || auctioninfo.startingprice === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "startingprice", error: "Starting Price is required"}] })
    }

    if(auctioninfo.bidmultiplier === 0 || auctioninfo.bidmultiplier === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "bidmultiplier", error: "Bid Multiplier is required"}] })
    }

    if(auctioninfo.bidmultiplier === 0 || auctioninfo.bidmultiplier === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "bidmultiplier", error: "Bid Multiplier is required"}] })
    }

    if(auctioninfo.buyitnow && (auctioninfo.bidmultiplier === 0 || auctioninfo.bidmultiplier === undefined)) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "buyitnowpricde", error: "Price is required"}] })
    }

    if(auctioninfo.shipping.trim() === "" || auctioninfo.shipping === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "shipping", error: "Shipping is required"}] })
    }

    if(auctioninfo.biddingcurrency.trim() === "" || auctioninfo.biddingcurrency === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "biddingcurrency", error: "Currency is required"}] })
    }

    // if(auctioninfo.status.trim() === "" || auctioninfo.status === undefined) {
    //   valid = false;
    //   setFieldError(prevState => { return [ ...prevState, {field: "status", error: "Status is required"}] })
    // }

    if(valid){
      props.FinalizeFunction(auctioninfo);
    }
  }

  const [addAdminAutobidder] = useMutation(ADD_ADMIN_AUTOBIDDER, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [deleteAdminAutobidder] = useMutation(DELETE_ADMIN_AUTOBIDDER, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const AddAdminAutobidder = () => {
    addAdminAutobidder( { variables: 
      {
        auctionid: auctioninfo.auctionid,
        customerid: newautobidder.customerid,
        count: parseFloat(newautobidder.value)
      }
    }).then((res) => {
      if(res.data){
        let originalList = auctioninfo.adminbidders;
        let EditRecord = originalList.find((e) => e.customerid === newautobidder.customerid)
        let newList = originalList.filter((e) => e.customerid !== newautobidder.customerid)
    
        newList.push({
          "name": EditRecord.name,
          "displayname" : EditRecord.displayname,
          "customerid": EditRecord.customerid,
          "assigned": true,
          "remainingbids" : newautobidder.value
        })
    
        setAuctionInfo(prevState => {
          return { ...prevState,  adminbidders: newList}
        })  

        setNewAutobidder({
          "customerid": "",
          "value" : 0
        })
      }
    })
  }

  const DeleteAdminAutobidder = (customerid) =>{
    deleteAdminAutobidder( { variables: 
      {
        auctionid: auctioninfo.auctionid,
        customerid: customerid
      }
    }).then((res) => {
      if(res.data){
        let originalList = auctioninfo.adminbidders;
        let EditRecord = originalList.find((e) => e.customerid === customerid)
        let newList = originalList.filter((e) => e.customerid !== customerid)
    
        newList.push({
          "name": EditRecord.name,
          "displayname" : EditRecord.displayname,
          "customerid": EditRecord.customerid,
          "assigned": false,
          "remainingbids" : 0
        })
    
        setAuctionInfo(prevState => {
          return { ...prevState,  adminbidders: newList}
        })  
      }
    })
  }

  const setProductInfo = (productid) => {
    if(data){
      const theProduct = data.getAdminProducts.find((e) => e.productid === productid)

      setAuctionInfo(prevState => {
        return { ...prevState, productinfo: theProduct, product: theProduct.productid}
      })   
    }
  }

  useEffect(() => {
    if(props.customerrors !== undefined)
      setFieldError(prevState => { return [ ...prevState, props.customerrors] })
  }, [props.customerrors]);


  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered",
      accessor: "name",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Username",
      className: "t-cell-1 text-centered",
      accessor: "displayname",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Remaining Bids",
      className: "t-cell-1 text-centered",
      accessor: "remainingbids",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Delete",
      className: "t-cell-1 text-centered",
      accessor: "customerid",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>
          <span style={{color:"yellow", cursor:"pointer"}} onClick={() => DeleteAdminAutobidder(props.value)}>Delete</span>
        </span>
      )
    },
  ];
  return (
    <div className="content">
      <Row>
        <Col md="6">
          <Card className="auctionDetails">
            <CardHeader>
              <CardTitle tag="h4">{props.title}</CardTitle>
            </CardHeader>
            <CardBody>
              {props.mode === "edit" &&
                <Row>
                  <Col md="6">
                    <p>Created By: <span>{auctioninfo.createdby}</span> <br /> {formatDate(auctioninfo.datetimecreated)}</p>
                  </Col>
                  <Col md="6">
                    <p>Last Modified By: <span>{auctioninfo.lastupdatedby}</span> <br /> {formatDate(auctioninfo.datetimelastupdated)}</p>
                  </Col>
                </Row>
              }

            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle">* Category:</label>
                  <select name="category"
                    className="form-control"
                    value={currentcategory}
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    onChange={ (e) => { 
                    setCategory(e.target.value);
                  }}>
                    <option value={""}>Select Category</option>
                    {/* <option value={"bidpack"}>Bidpacks</option> */}
                    {cdata && cdata.getAdminCategories && cdata.getAdminCategories.length > 0 &&
                      cdata.getAdminCategories.map((value, key) => {
                        return <option value={value.categoryid} key={key}>{value.name}</option>
                      })
                    }
                  </select>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
              <FormGroup>
                  <label className="subTitle">* Product:</label>
                  <select name="product"
                    value={auctioninfo.productinfo.productid}
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    className={CheckError("product") ? 'error form-control' : 'form-control'}
                    onChange={ e => setProductInfo(e.target.value) }>
                    <option value={""}>Select Product</option>
                    {data && data.getAdminProducts && data.getAdminProducts.length > 0 &&
                      data.getAdminProducts.map((value, key) => {
                        return <option value={value.productid} key={key}>{value.name}</option>
                      })
                    }
                  </select>
                  <span className='errorMessage'>{CheckError("product") ? CheckError("product") : ''}</span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col  className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle"> * Start Date </label>
                  <Datetime 
                    value={parseISO(auctioninfo.startdatetime)}
                    inputProps={{readOnly: (auctioninfo.currentbuyer !== '') ? true : false}}
                    className={CheckError("startdatetime") ? 'error' : ''}
                    onChange={(e) => {
                      if(e._d !== undefined){
                        setAuctionInfo(prevState => {
                          return { ...prevState, startdatetime: format(e._d, "yyyy-MM-dd HH:mm")}
                        })
                      }
                      else{
                        setAuctionInfo(prevState => {
                          return { ...prevState, startdatetime: ''}
                        })
                      }
                    }}
                  /> 
                  <span className='errorMessage'>{CheckError("startdatetime") ? CheckError("startdatetime") : ''}</span>
                </FormGroup>
              </Col>

              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle"> * End Date </label>
                  <Datetime
                    value={parseISO(auctioninfo.enddatetime)}
                    inputProps={{readOnly: (auctioninfo.currentbuyer !== '') ? true : false}}
                    className={CheckError("enddatetime") ? 'error' : ''}
                    onChange={(e) => {
                      if(e._d !== undefined){
                        setAuctionInfo(prevState => {
                          return { ...prevState, enddatetime: format(e._d, "yyyy-MM-dd HH:mm")}
                        })
                      }
                      else{
                        setAuctionInfo(prevState => {
                          return { ...prevState, enddatetime: ''}
                        })
                      }
                    }}
                  /> 
                  <span className='errorMessage'>{CheckError("enddatetime") ? CheckError("enddatetime") : ''}</span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle">* Starting Price:</label>
                  <Input
                    placeholder="0.01"
                    type="number"
                    onFocus={handleInputFocus}
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    name="startingprice"
                    step="0.01"
                    className={CheckError("startingprice") ? 'error' : ''}
                    value={auctioninfo.startingprice}
                    autoComplete = "off"
                    maxLength={60}
                    onChange={ e => UpdateChange(e) }
                  />
                  <span className='errorMessage'>{CheckError("startingprice") ? CheckError("startingprice") : ''}</span>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle">* Bid Multiplier:</label>
                  <Input
                    placeholder="1"
                    type="number"
                    onFocus={handleInputFocus}
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    name="bidmultiplier"
                    step="1"
                    className={CheckError("bidmultiplier") ? 'error' : ''}
                    value={auctioninfo.bidmultiplier}
                    autoComplete = "off"
                    maxLength={60}
                    onChange={ e => UpdateChange(e) }
                  />
                  <span className='errorMessage'>{CheckError("bidmultiplier") ? CheckError("bidmultiplier") : ''}</span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <label className="subTitle">Global:</label>
                <Input 
                type="checkbox" 
                className = 'form-control mwCheckbox' 
                disabled={(auctioninfo.currentbuyer !== '') ? true : false}
                value = {1}
                checked={  auctioninfo.global }
                onChange = {() => {
                  setAuctionInfo(prevState => {
                    return { ...prevState, global: !auctioninfo.global}
                  })
                }}
              />
              </Col>

              <Col md="3">
                <label className="subTitle">Featured:</label>
                <Input 
                type="checkbox" 
                className = 'form-control mwCheckbox' 
                disabled={(auctioninfo.currentbuyer !== '') ? true : false}
                value = {1}
                checked={  auctioninfo.featured }
                onChange = {() => {
                  setAuctionInfo(prevState => {
                    return { ...prevState, featured: !auctioninfo.featured}
                  })
                }}
              />
              </Col>

              <Col md="3">
                <label className="subTitle">Buy It Now:</label>
                <Input 
                type="checkbox" 
                className = 'form-control mwCheckbox' 
                value = {1}
                disabled={(auctioninfo.currentbuyer !== '') ? true : false}
                checked={  auctioninfo.buyitnow }
                onChange = {() => {
                  setAuctionInfo(prevState => {
                    return { ...prevState, buyitnow: !auctioninfo.buyitnow}
                  })
                }}
              />
              </Col>

              <Col className="pr-md-1" md="3">
                <FormGroup>
                  <label className="subTitle">* Buy it now Price:</label>
                  <Input
                    placeholder="1"
                    type="number"
                    onFocus={handleInputFocus}
                    name="buyitnowprice"
                    step="1"
                    className={CheckError("buyitnowprice") ? 'error' : ''}
                    value={auctioninfo.buyitnowprice}
                    autoComplete = "off"
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : !auctioninfo.buyitnow}
                    maxLength={60}
                    onChange={ e => UpdateChange(e) }
                  />
                  <span className='errorMessage'>{CheckError("buyitnowprice") ? CheckError("buyitnowprice") : ''}</span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle">* Shipping:</label>
                  <select name="shipping"
                    className={CheckError("shipping") ? 'error form-control' : 'form-control'}
                    value={auctioninfo.shipping}
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    onChange={ e => UpdateChange(e) }>
                    <option value={""}>Select Shipping</option>
                    {sdata && sdata.getAdminShipping && sdata.getAdminShipping.length > 0 &&
                      sdata.getAdminShipping.map((value, key) => {
                        return <option value={value.shippingid} key={key}>{value.name}</option>
                      })
                    }
                  </select>
                  <span className='errorMessage'>{CheckError("shipping") ? CheckError("shipping") : ''}</span>
                </FormGroup>
              </Col>

              {/* <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle">* Status:</label>
                  <select name="status"
                    className={CheckError("status") ? 'error form-control' : 'form-control'}
                    value={auctioninfo.status}
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    onChange={ e => UpdateChange(e) }>
                    <option value={""}>Select Status</option>
                    {ssdata && ssdata.getAdminStatuses && ssdata.getAdminStatuses.length > 0 &&
                      ssdata.getAdminStatuses.map((value, key) => {
                        return <option value={value.statusid} key={key}>{value.name}</option>
                      })
                    }
                  </select>
                  <span className='errorMessage'>{CheckError("status") ? CheckError("status") : ''}</span>
                </FormGroup>
              </Col> */}
            </Row>
            
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle">* Bidding Currency:</label>
                  <select name="biddingcurrency"
                    className={CheckError("biddingcurrency") ? 'error form-control' : 'form-control'}
                    value={auctioninfo.biddingcurrency}
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    onChange={ e => UpdateChange(e) }>
                    <option value={""}>Select Bidding Currency</option>
                    {ccdata && ccdata.getAdminCurrencies && ccdata.getAdminCurrencies.length > 0 &&
                      ccdata.getAdminCurrencies.map((value, key) => {
                        return <option value={value.currencyid} key={key}>{value.name}</option>
                      })
                    }
                  </select>
                  <span className='errorMessage'>{CheckError("biddingcurrency") ? CheckError("biddingcurrency") : ''}</span>
                </FormGroup>
              </Col>

              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle">* Active: </label>
                  <select className='form-control'
                    name="active"
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    value={auctioninfo.active}
                    onChange={ e => UpdateChange(e, "boolean") }
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label className="subTitle"> Platform:</label>
                  <select name="platform"
                    className={CheckError("platform") ? 'error form-control' : 'form-control'}
                    value={auctioninfo.platform}
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    onChange={ e => UpdateChange(e) }>
                    <option value={""}>Select an Affiliate Platform</option>
                    <option value={"AAM"}>Active Action Media</option>
                  
                  </select>
                  <span className='errorMessage'>{CheckError("platform") ? CheckError("platform") : ''}</span>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                {console.log("DIST LIST", distlist)}
                <FormGroup>
                  <label className="subTitle">* Distributor: </label>
                  <MultiSelect
                    options={distlist}
                    className ={"mw-multiselect"}
                    value={selected}
                    onChange={ setSelected }
                    labelledBy="Select"
                  />
                  {/* <select className='form-control'
                    name="active"
                    multiple={true}
                    readOnly={(auctioninfo.currentbuyer !== '') ? true : false}
                    value={auctioninfo.active}
                    onChange={ e => UpdateChange(e, "boolean") }
                  >
                    <option value={""}>Select a Distributor</option>
                    {distlist && distlist.length > 0 &&
                      distlist.map((value, key) => {
                        return <option value={value.value} key={key}>{value.label}</option>
                      })
                    }
                  </select> */}
                </FormGroup>
              </Col>
            </Row>
            </CardBody>
            <CardFooter>
            {auctioninfo.currentbuyer === '' &&
            <Button 
              className="btn-fill floatRight" 
              color="info" 
              type="button" 
              onClick = {(e) => SubmitForm()}
              >
                {props.mode === "add" ? "Add New Aucion" : "Update Aucion"}
              </Button>
            }
            {auctioninfo.currentbuyer !== '' &&
            <>
              <Row>
                <Col className="pr-md-1" md="4">
                  <FormGroup>
                    <label className="subTitle">Current Buyer:</label>
                    <Input
                      type="text"
                      name="currentbuyer"
                      value={auctioninfo.currentbuyer}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-md-1" md="4">
                  <FormGroup>
                    <label className="subTitle">Current Price:</label>
                    <Input
                      type="number"
                      name="currentprice"
                      value={auctioninfo.currentprice}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>

                <Col className="pr-md-1" md="4">
                  <FormGroup>
                    <label className="subTitle">Finalized:</label>
                    <Input
                      type="text"
                      name="finalized"
                      value={auctioninfo.finalized ? "Yes" : "No"}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="pr-md-1" md="6">
                  <FormGroup>
                    <label className="subTitle">Admin Notes:</label>
                    <Input
                      type="textarea"
                      name="adminnotes"
                      value={auctioninfo.adminnotes}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>

                <Col className="pr-md-1" md="6">
                  <FormGroup>
                    <label className="subTitle">Rejection Reason:</label>
                    <Input
                      type="textarea"
                      name="adminnotes"
                      value={auctioninfo.rejectionreason}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
            }
            </CardFooter>
          </Card>
        </Col>
        <Col md="6">
          <Card className="auctionDetails">
          <CardHeader>
              <CardTitle tag="h4">Product Info</CardTitle>
            </CardHeader>
            <CardBody>
                {auctioninfo.productinfo && auctioninfo.productinfo.productid &&
                  <div>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label className="subTitle">Name:</label>
                          <Input
                            placeholder="Name"
                            type="text"
                            name="name"
                            readOnly = {true}
                            value={auctioninfo.productinfo.name}
                            autoComplete = "off"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label className="subTitle">Product Code:</label>
                          <Input
                            placeholder="Code"
                            type="text"
                            name="productcode"
                            readOnly = {true}
                            value={auctioninfo.productinfo.productcode}
                            autoComplete = "off"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label className="subTitle"> Qty:</label>
                          <Input
                            placeholder="1"
                            type="number"
                            name="qty"
                            readOnly = {true}
                            value={auctioninfo.productinfo.qty}
                            autoComplete = "off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="subTitle"> Long Description: </label>
                          <Input
                            placeholder="Long Description" 
                            cols="80"
                            rows="4"
                            type="textarea"
                            name="longdescription"
                            readOnly={true}
                            value={auctioninfo.productinfo.longdescription}
                            />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="subTitle">Keywords: </label>
                          <Input
                            placeholder="Keywords" 
                            cols="80"
                            rows="4"
                            type="textarea"
                            name="keywords"
                            readOnly={true}
                            value={auctioninfo.productinfo.keywords}
                            />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label className="subTitle">Retail Price:</label>
                          <Input
                            placeholder="1"
                            type="number"
                            name="retailprice"
                            step="0.01"
                            value={auctioninfo.productinfo.retailprice}
                            autoComplete = "off"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>

                    <Col md="4">

                    </Col>
                    {pimages && pimages.getAdminProductImages &&
                      <Col md="4">
                        <img className = "productimg" src={SERVERURL + "/images/products/" + auctioninfo.productinfo.productid + "/" + pimages.getAdminProductImages.mainimgid} />
                      </Col> 
                    }
                    </Row>
                  </div>
                }
            </CardBody>
          </Card>

          {auctioninfo.adminbidders && auctioninfo.adminbidders.length > 0 && 
          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              <Card className="auctionDetails">
              <CardHeader>
                  <CardTitle tag="h4">Admin Auto Bidders</CardTitle>
                </CardHeader>
                <CardBody className='recentBids'>
                      <>
                        <XTable columns={listHeader} loading={false} data={auctioninfo.adminbidders.filter((e) => e.assigned === true)} filterbutton={false} />

                      <Row>
                        <Col md="6">
                        <label className="subTitle">Add Admin Bidder:</label>
                          <select name="newadminbidder" 
                            className= {'form-control'}
                            value={newautobidder.customerid}
                            onChange={(e) =>{
                              setNewAutobidder(prevState => {
                                return { ...prevState,  customerid: e.target.value}
                              }) 
                            }}
                          >
                          <option value={""}>Select a Admin Bidder</option>
                            { auctioninfo.adminbidders.filter((e) => e.assigned === false).map((value, key) => {
                                return <option value={value.customerid} key={key}>{value.name} ({value.displayname})</option>
                              })
                            }
                        </select>
                        </Col>

                        <Col md="6">
                          <FormGroup>
                            <label className="subTitle">Bids:</label>
                            <Input
                              placeholder="1"
                              type="number"
                              onFocus={handleInputFocus}
                              name="bids"
                              value={newautobidder.value}
                              step="0.01"
                              autoComplete = "off"
                              onChange={(e) =>{
                                setNewAutobidder(prevState => {
                                  return { ...prevState,  value: e.target.value}
                                }) 
                              }}
                            />
                          </FormGroup>
                        </Col>
                        </Row>

                        <Col md="6">
                          <Button 
                          className="btn-fill" 
                          color="info" 
                          type="button" 
                          onClick = {(e) => AddAdminAutobidder()}
                          >
                            Add Admin Bidder to Auction
                          </Button>
                        </Col>
                      </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
        </Col>
      </Row>
    </div>
  )
}

export default AdminAuction;