import { useQuery } from "@apollo/client";
import MiniAuction2 from "./miniauction2";
import { GET_AUCTIONS } from "graphql/customer/queries/auction";
import React, { useEffect, useState } from "react";
import { useAuth } from "components/custom/authprovider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDeviceFingerprint } from "utils/functions";
import { GET_AUCTIONS_PRIVATE } from "graphql/customer/queries/auction";

const { Col, Row } = require("reactstrap");

function SimilarAuctions(props){
  const history = useHistory();
  const {wt}  = useAuth();

  const useConditionalQuery = () => {
    if (wt && wt.wt) {
      return useQuery(GET_AUCTIONS_PRIVATE, { 
        variables: {
          categoryid: props.category,
          limit: 4,
          random: true,
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'cache-and-network',
        context: {
          headers: {
            authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
            'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
          },
        },
        onError: (errors) => {
          if (errors.networkError) {
            const serverError = errors.networkError.result.errors[0].message;
            if(serverError === "Authentication required."){
              history.push({pathname:"/logout"})
            }
          } 
        }
      });
    }
    else 
      return useQuery(GET_AUCTIONS, {
        variables: {
          categoryid: props.category,
          limit: 4,
          random: true,
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'cache-and-network',
      });
  }
  const { loading, error, data, onError, refetch  } = useConditionalQuery();

  const [auctionlist, setAuctionList] = useState({});

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        let TheAuctionData;
        if(data.getAuctionsPrivate) TheAuctionData = data.getAuctionsPrivate;
        else if(data.getAuctions) TheAuctionData = data.getAuctions;
      
        setAuctionList(() => TheAuctionData);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  const removeAuctionFromList = (auctionid) => {
    console.log("REMOVING", auctionid);
    //TODO: Send Mutation to update AuctionID Active to False
  }

  return (
    <div className="mw-box">
      <span className="ComponentHeading">Similar Auctions</span>
        <Row>
          {auctionlist.auctionlist &&
            auctionlist.auctionlist.map((value, key) => {
              return (
                <Col key={key}  sm="12" md="6" lg="3">
                  <MiniAuction2 key={key}  data = {value} serverTime = {auctionlist.servertime} auctionFinished = {removeAuctionFromList} />
                </Col>
              );
            })
          }
      </Row>
    </div>
  )
}

export default SimilarAuctions;