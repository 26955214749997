import { useQuery } from "@apollo/client";
import { useAuth } from "components/custom/authprovider";
import XTable from "components/custom/table";
import { GET_BALANCE_CREDITS } from "graphql/customer/queries/balancecredits";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDate } from "utils/date";
import { getDeviceFingerprint } from "utils/functions";
import { formatNumber } from "utils/functions";
const { CardHeader, CardBody, Card,  CardTitle } = require("reactstrap");

function AdminBalanceCredits(props){
  const history = useHistory();
  const {wt}  = useAuth();

  const { loading, error, data, onError } = useQuery( GET_BALANCE_CREDITS,{
    variables: {
      customerid: props.customerid,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [balancecredits, setBalanceCredits] = useState();


  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setBalanceCredits(() => data.getBalanceCredits);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  const listHeader = [
    {
      Header: "DateTime",
      accessor: "datetime",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatDate(props.value)}</span>
      )
    },
    {
      Header: "Currency",
      accessor: "currency",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value.name}</span>
      )
    },
    {
      Header: "Amount Before",
      accessor: "amountbefore",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatNumber(props.value)}</span>
      )
    },
    {
      Header: "Amount After",
      accessor: "amountafter",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatNumber(props.value)}</span>
      )
    },
    {
      Header: "Notes",
      accessor: "notes",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
  ];

  return (
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">Recent 10 Bids | <span style={{color:"lime", fontSize:"smaller"}}>{props.title}</span></CardTitle>
      </CardHeader>
      <CardBody className={ ' recentBids'} >
        {balancecredits &&
          <XTable columns={listHeader} loading={false} data={balancecredits} filterbutton={false} />
        }
      </CardBody>
    </Card>
  )
}

export default AdminBalanceCredits;