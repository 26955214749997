import { useMutation } from "@apollo/client";
import BalanceCredits from "../../components/customer/Account/BalanceCredits";
import RecentBids from "../../components/customer/Account/RecentBids";
import WatchList from "../../components/customer/Account/WatchList";
import WonAuctions from "../../components/customer/Account/WonAuctions";
import Autobid from "../../components/customer/shared/autobids";
import Customer from "../../components/customer/shared/customer";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SAText } from "utils/functions";
import LinkedAccounts from "components/customer/Account/LinkedAccounts";
import { useAuth } from "components/custom/authprovider";
import { getDeviceFingerprint } from "utils/functions";
import { UPDATE_CUSTOMER_PROFILE } from "graphql/customer/mutations/users";
import { APPCOOKIE } from "utils/defaults";

const { CardHeader, CardBody, Card, Col, Row, CardTitle } = require("reactstrap");

function Account(){
  const location = useLocation();
  const history = useHistory();
  const {userData, wt, setWT, setIsAuthenticated}  = useAuth();
  
  const [view, setView] = useState(location.state ? location.state.page : "profile");
  const [customerrors, setCustomErrors] = useState();

  const [customerBasicProfileUpdate] = useMutation(UPDATE_CUSTOMER_PROFILE, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
      context: {
        headers: {
          authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
          'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
        },
      },
      onError: (errors) => {
        if (errors.networkError) {
          const serverError = errors.networkError.result.errors[0].message;
          if(serverError === "Authentication required."){
            history.push({pathname:"/logout"})
          }
        } 
      },
  });

  const UpdateCustomer = (userinfo) => {
    customerBasicProfileUpdate({ variables: {data: {
      firstname: userinfo.firstname,
      lastname: userinfo.lastname,
      phone: userinfo.phone,
      emailoptin: userinfo.emailoptin,
      gender: userinfo.gender,
      address: userinfo.address,
      email: userinfo.email,
      address2: userinfo.address2,
      city: userinfo.city,
      state: userinfo.state,
      zip: userinfo.zip,
      country: userinfo.country,
      password1: userinfo.password1,
      password2: userinfo.password2,
    } }})
    .then((result) => {
      if(result.data.customerBasicProfileUpdate.status){
        const oldCookie = JSON.parse(localStorage.getItem(APPCOOKIE));
        const TheCookie ={
          wt: result.data.customerBasicProfileUpdate.data,
          dt: oldCookie.dt,
          displayname: oldCookie.displayname,
          sstring: oldCookie.sstring,
          currency: oldCookie.currency,
        }
        const token = localStorage.setItem( APPCOOKIE, JSON.stringify(TheCookie) );

        setWT({ 
          wt: TheCookie.wt, 
          dt: TheCookie.dt 
        });

        setIsAuthenticated({ token });
      }
      else{
        SAText("Error", result.data.customerBasicProfileUpdate.error, "error")
      }
    })
    .catch((error) => {
      SAText("Error", error, "error")
    })
    .finally(() =>{
      //TODO: set loading
    })
  }

  return (
    <div className="content card">
    <Row>
        <Col sm="5" md="4" lg="3">
          <Card className="">
            <CardHeader>
              <CardTitle tag="h4">Account</CardTitle>
            </CardHeader>
            <CardBody>
              <ul className='accountNav'>
                <li onClick={() => setView('profile')}>Profile</li>
                <li onClick={() => setView('wonauctions')}>Won Auctions</li>
                <li onClick={() => setView('watchlist')}>Watchlist</li>
                <li onClick={() => setView('autobiders')}>Autobidders</li>
                <li onClick={() => setView('recentbids')}>Recent Bids</li>
                <li onClick={() => setView('balancecredits')}>Balance Credits</li>
                <li onClick={() => setView('linkedaccounts')}>Affiliate Accounts</li>
                {userData.sstring === "2c0870b4-cf53-4496-a360-a14505d9ec16" &&
                  <li onClick={() => history.push({pathname:"/admin/auctions"})}>Admin Section</li>
                }
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col sm="7" md="8" lg="9">
          {
            view === "profile" ?
              <Customer title={"Profile"} FinalizeFunction = {UpdateCustomer} customerrors={customerrors} mode={"profile"} /> : 
            view === "recentbids" ? 
              <RecentBids mode="customer" /> :
            view === "autobiders" ? 
              <Autobid  /> :
            view === "watchlist" ? 
              <WatchList  /> :
            view === "wonauctions" ? 
              <WonAuctions  /> :
            view === "balancecredits" ? 
              <BalanceCredits  /> :
            view === "linkedaccounts" ? 
              <LinkedAccounts  /> :
            ''
          }
          {/* <Card>
            <CardHeader></CardHeader>
            <CardBody></CardBody>
            <CardFooter></CardFooter>
          </Card> */}
        </Col>
      </Row>
    </div>
  )
}

export default Account;