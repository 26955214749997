import { gql } from '@apollo/client';

export const PLACE_BID = gql`
mutation placeBid($auctionid : String!, $autobid: Boolean) {
  placeBid(auctionid: $auctionid, autobid: $autobid) {
    data
    status
    error
  }
}
`;

export const BUY_IT_NOW = gql`
mutation buyItNow($auctionid : String!) {
  buyItNow(auctionid: $auctionid) {
    data
    status
    error
  }
}
`;

export const REJECT_AUCTION = gql`
mutation rejectAuction($auctionid : String!, $reason: String!) {
  rejectAuction(auctionid: $auctionid, reason: $reason) {
    data
    status
    error
  }
}
`;
