import { gql } from '@apollo/client';

export const CREATE_FINDUS = gql`
mutation createFindus($data : AdminFindus!) {
  createFindus(data: $data) 
}
`;

export const UPDATE_FINDUS = gql`
mutation updateFindus($data : AdminFindus!) {
  updateFindus(data: $data) 
}
`;