import { gql } from '@apollo/client';

export const GET_SHIPPING = gql`
query getAdminShipping {
  getAdminShipping {
    name
    description
    active
    rate
    shippingid
    lastupdatedby
    createdby
    datetimeupdated
    datetimecreated
  }
}
`;