
/*eslint-disable*/
import Affilates from "components/customer/shared/affilates";
import AuctionTypes from "components/customer/shared/auctiontypes";
import React from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import { Col, Container, Nav, NavbarText, NavItem, NavLink, Row } from "reactstrap";

function Footer() {
  let history = useHistory();
  const Goto = (page, data = undefined) =>{
    console.log("GOTO", data);
    history.push({ 
      pathname: page,
      state: data
    })
  }

  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col md="12">
            <Row>
              <Col md="2">
                <h4>GifteeBids</h4>
                <Nav>
                  <NavItem>
                    <NavLink href="https://gifteebids.com">
                      Home
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink onClick={() => Goto("/account")}>
                      My Account
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink onClick={() => Goto("/bidpack")}>
                      Buy Bid Packages
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="https://gifteebids.com">
                      About Us
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>

              <Col md="2">
                <h4>Company</h4>
                <Nav>
                  <NavItem>
                    <NavLink href="https://gifteebids.com">
                      Terms & Conditions
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="https://gifteebids.com">
                      Shipping & Returns
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="https://gifteebids.com">
                      Privacy Policy
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="https://gifteebids.com">
                      Contact Us
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="https://gifteebids.com">
                      FAQ
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>

              <Col md="2">
                <h4>Auctions</h4>
                <AuctionTypes goto={Goto} />
              </Col>

              <Col md="2">
                <h4>Affilates</h4>
                <Affilates />
              </Col>

              <Col md="2">
                <img src="/gifteebids_present.png" />
              </Col>
            </Row>
          </Col>
        </Row>

        
        <div className="copyright">
          © {new Date().getFullYear()} {" Copyright "}
          <a
            href="https://gifteebids.com"
            target="_blank"
          >
            GifteeBids
          </a>{" "}
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
