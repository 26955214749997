import { useHistory } from "react-router-dom";

function SideNav(){
  let history = useHistory();
  const Goto = (page) =>{
    history.push({
      pathname: "/admin/" + page,
    })
  }

  return (
    <>
      <ul className='accountNav'>
        <li onClick = {() => Goto("affilates")} className="nav-item">
          <span className="nav-link">Affiliates</span>
        </li>
        <li onClick = {() => Goto("findus")} className="nav-item">
          <span className="nav-link">Find Us</span>
        </li>
        <li onClick = {() => Goto("currency")}  className="nav-item">
          <span className="nav-link">Currencies</span>
        </li>
        <li onClick = {() => Goto("auctions")}  className="nav-item">
          <span className="nav-link">Auctions</span>
        </li>
        <li onClick = {() => Goto("categories")}  className="nav-item">
          <span className="nav-link">Categories</span>
        </li>
        <li onClick = {() => Goto("products")}  className="nav-item">
          <span className="nav-link">Products</span>
        </li>
        <li onClick = {() => Goto("bidpacks")}  className="nav-item">
          <span className="nav-link">Bidpacks</span>
        </li>
        <li onClick = {() => Goto("shipping")}  className="nav-item">
          <span className="nav-link">Shipping</span>
        </li>
        <li onClick = {() => Goto("statuses")}  className="nav-item">
          <span className="nav-link">Statuses</span>
        </li>
        <li onClick = {() => Goto("customers")}  className="nav-item">
          <span className="nav-link">Customers</span>
        </li>
        <li onClick = {() => Goto("adminbidders")}  className="nav-item">
          <span className="nav-link">A-Bidders</span>
        </li>
        <li onClick = {() => Goto("reports")}  className="nav-item">
          <span className="nav-link">Reports</span>
        </li>
      </ul>
    </>
  )
}

export default SideNav;