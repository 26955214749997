import React, { useEffect, useState } from "react";
import { formatDuration, intervalToDuration,  } from 'date-fns'
import {  normalize, subtract } from 'duration-fns';
import { checkDuration } from "utils/functions";
import { formatNumber } from "utils/functions";
import { formatDate } from "utils/date";
import { UPDATED_AUCTION } from "graphql/customer/subscriptions/auction";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { PLACE_BID } from "graphql/customer/mutations/auction";
import { BidTimeout } from "utils/defaults";
import { GET_AUCTIONS } from "graphql/customer/queries/auction";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Autobidder from "../../components/customer/Account/Autobidder";
import RecentBids from "../../components/customer/Account/RecentBids";
import SimilarAuctions from "../../components/customer/AuctionDetails/SimilarAuctions";
import FeaturedAuctions from "../../components/customer/AuctionDetails/FeaturedAuctions";
import { SAText } from "utils/functions";
import { ADD_WATCHLIST } from "graphql/customer/mutations/watchlist";
import { DELETE_WATCHLIST } from "graphql/customer/mutations/watchlist";
import ImageGallery from 'react-image-gallery';

// import "~react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import { SAConfirm } from "utils/functions";
import { BUY_IT_NOW } from "graphql/customer/mutations/auction";
import ReactTooltip from "react-tooltip";
import { makeid } from "utils/functions";
import { SERVERURL } from "utils/defaults";
import { SAlert } from "utils/functions";
import { SMSAlert } from "utils/defaults";
import { VERIFY_SMS } from "graphql/customer/queries/users";
import { useAuth } from "components/custom/authprovider";
import { getDeviceFingerprint } from "utils/functions";
import { GET_AUCTIONS_PRIVATE } from "graphql/customer/queries/auction";
import { SEND_SMS } from "graphql/customer/queries/users";

const { CardHeader, CardBody, CardFooter, Card, Col, Row, CardTitle,  Button } = require("reactstrap");

function AuctionDetails(props){
  const location = useLocation();
  let history = useHistory();
  const {wt}  = useAuth();
  //TODO: Get Autobid Settings

  const { id } = useParams();
  const useConditionalQuery = () => {
    if (wt && wt.wt) {
      return useQuery(GET_AUCTIONS_PRIVATE, { 
        variables: { auctionid: id},
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'cache-and-network',
        context: {
          headers: {
            authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
            'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
          },
        },
        onError: (errors) => {
          if (errors.networkError) {
            const serverError = errors.networkError.result.errors[0].message;
            if(serverError === "Authentication required."){
              history.push({pathname:"/logout"})
            }
          } 
        }
      });
    }
    else 
      return useQuery(GET_AUCTIONS, {
        variables: { auctionid: id},
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'cache-and-network',
      });
  }
  const { loading: al, error: ae, data: ad, onError:aoe, refetch  } = useConditionalQuery();

  const tooltipid = makeid(10);

  const [timeleft, setTimeLeft] = useState();
  const [timeout, setTimeOut] = useState(false);
  const [timeouttime, setTimeOutTime] = useState(false);
  const [auctioninfo, setAuctionInfo] = useState();
  const [imgarray, setImgArray] = useState([]);

  const [sendSMS] = useMutation(SEND_SMS, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });


  const [verifySMS] = useMutation(VERIFY_SMS, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const ConfirmBuyItNow = () => {
    SAlert(SMSAlert.title, "text", SMSAlert.label, SMSAlert.icon, (v) => {
      verifySMS({ variables: { code: v, type:"gifteebuyitnow"} })
      .then((r2) => {
        if(r2.data.verifySMS.status){
           //buy it now
            buyItNow({ variables: { auctionid: id.toLowerCase()} })
            .then( (buyitnowres) => {
              if(buyitnowres.data.buyItNow.status){
                //redirect to account - won auctions
                history.push({
                  pathname: '/account',
                  state: {
                    page: 'wonauctions',
                  }
                })
              }
              else{
                SAText("Error", buyitnowres.data.buyItNow.error, "error")            
              }
            })
            .catch( (err2) => {
              SAText("Error", err2, "error")          
            })
        }
        else
          SAText("Error", r2.data.verifySMS.error, "error", () => ConfirmBuyItNow())
      })
    })
  }

  const BuyItNow = () => {
    SAConfirm("Confirm Buy It Now", "By selecting Buy It Now for this auction, you are agreeing to purchase the product outright for the Buy It Now price listed on the item. Once an item is selected for Buy It Now, please continue to your account to proceed with the checkout process.", () => {
      //Agreed to buy it - execute mutation and delist auction
      sendSMS({ variables: {type: "gifteebuyitnow"}})
      .then((smsres) => {
        if(smsres.data.sendSMS.status){
          ConfirmBuyItNow()
        }
        else{
          SAText("Error", smsres.data.sendSMS.error, "error")
        }
      })
      .catch((err) => {
        SAText("Error", err, "error")          
      })
      .finally(() =>{
        //TODO: Set loading
      })
    })
  }

  const { data } = useSubscription(UPDATED_AUCTION, {
    variables: { auctionid: id },
  });

  const [placeBid] = useMutation(PLACE_BID, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [addWatchList] = useMutation(ADD_WATCHLIST, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });
  const [deleteWatchList] = useMutation(DELETE_WATCHLIST, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  
  const PlaceTheBid = () => {
    setTimeOutTime(BidTimeout);
    setTimeOut(true);
    
    placeBid({ variables: {auctionid: id.toLowerCase()} })
    .then((res) => {
      if(!res.data.placeBid.status){
        SAText("Error", res.data.placeBid.error, "error")
      }
    })
  }

  const [buyItNow] = useMutation(BUY_IT_NOW, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  useEffect(() => {
    const onCompleted = (ad) => { 
      let TheAuctionData;
      if(ad.getAuctionsPrivate) TheAuctionData = ad.getAuctionsPrivate;
      else if(ad.getAuctions) TheAuctionData = ad.getAuctions;

      if(TheAuctionData && TheAuctionData.auctionlist && TheAuctionData.auctionlist.length > 0){
        const startTime = new Date(TheAuctionData.servertime);
        const endTime = new Date(TheAuctionData.auctionlist[0].enddatetime);

        let duration = intervalToDuration({
          start: startTime, 
          end: endTime,
        })

        formatDuration(duration, { delimiter: ', ' })

        setTimeLeft(() => undefined);

        setAuctionInfo({
          "name": TheAuctionData.auctionlist[0].product.name,
          "shortdescription": TheAuctionData.auctionlist[0].product.shortdescription,
          "longdescription": TheAuctionData.auctionlist[0].product.longdescription,
          "productcode": TheAuctionData.auctionlist[0].product.productcode,
          "productid": TheAuctionData.auctionlist[0].product.productid,
          "mainimgid": TheAuctionData.auctionlist[0].product.mainimgid ? TheAuctionData.auctionlist[0].product.productid + "/" + TheAuctionData.auctionlist[0].product.mainimgid : "./img-ph.jpg",
          "keywords": TheAuctionData.auctionlist[0].product.keywords,
          "retailprice": TheAuctionData.auctionlist[0].product.retailprice,
          "category": TheAuctionData.auctionlist[0].product.category,
          "currentprice": TheAuctionData.auctionlist[0].currentprice,
          "status": TheAuctionData.auctionlist[0].status,
          "startdatetime": TheAuctionData.auctionlist[0].startdatetime,
          "active": TheAuctionData.auctionlist[0].active,
          "bidmultiplier" : TheAuctionData.auctionlist[0].bidmultiplier,
          "shipping" : TheAuctionData.auctionlist[0].shipping,
          "currentbuyer" : (TheAuctionData.auctionlist[0].currentbuyer ? TheAuctionData.auctionlist[0].currentbuyer : ''),
          "auctionid": TheAuctionData.auctionlist[0].auctionid,
          "currencyname" : TheAuctionData.auctionlist[0].biddingcurrency.name,
          "currencyimg" : TheAuctionData.auctionlist[0].biddingcurrency.imgid,
          "buyitnow": TheAuctionData.auctionlist[0].buyitnow,
          "buyitnowprice": TheAuctionData.auctionlist[0].buyitnowprice,
          "featured": TheAuctionData.auctionlist[0].featured,
          "finalized": TheAuctionData.auctionlist[0].finalized,
          "watchlistid": TheAuctionData.watchlistid
        })

        if(startTime.getTime() < endTime.getTime())
          setTimeLeft(() => duration);
        else
          setTimeLeft(() => undefined);

          // setImgArray
          let ImgArray = [];
          ImgArray.push({
              original: TheAuctionData.auctionlist[0].product.mainimgid ? SERVERURL + '/images/products/' + TheAuctionData.auctionlist[0].product.productid + "/" + TheAuctionData.auctionlist[0].product.mainimgid : SERVERURL + "/images/products/img-ph.jpg",
              thumbnail: TheAuctionData.auctionlist[0].product.mainimgid ? SERVERURL + '/images/products/' + TheAuctionData.auctionlist[0].product.productid + "/" + TheAuctionData.auctionlist[0].product.mainimgid : SERVERURL + "/images/products/img-ph.jpg",
              thumbnailWidth:"20px",
              // thumbnailHeight:"10px"
            })

          if(TheAuctionData.auctionlist[0].product.additionalimgids){
            const aImgs = TheAuctionData.auctionlist[0].product.additionalimgids.split(",")

            aImgs.map(value => {
              return (
                ImgArray.push({
                  original: SERVERURL + '/images/products/' + TheAuctionData.auctionlist[0].product.productid + "/" + value,
                  thumbnail: SERVERURL + '/images/products/' + TheAuctionData.auctionlist[0].product.productid + "/" + value,
                  thumbnailWidth:"20px",
                  // thumbnailHeight:"10px"
                })
              )
            })
          }
          setImgArray(ImgArray)
      }
      else{
        history.push({pathname:"/"})
      }
    }
    if (onCompleted || aoe) {
      if (onCompleted && !al && !ae) {
        onCompleted(ad);
      } else if (aoe && !al && ae) {
        aoe(ae);
      }
    }
  }, [al, ad, ae, aoe]);
  
  useEffect(() => {
    if(timeleft !== undefined){
      if(checkDuration(timeleft)) {
        let timer = setTimeout( () => {
          setTimeLeft( () => normalize(subtract( timeleft, {seconds:1} )) ) ;
            
          if(timeouttime === 1 && timeout) setTimeOut(false);
          if(timeouttime <= BidTimeout && timeouttime > 0 && timeout) setTimeOutTime(prevState => { return prevState-1 } )
        }, 1000)

        return () => {
          clearTimeout(timer);
        }
      }
      else{
        setTimeLeft( () => undefined) ;
        // TODO:
        // props.auctionFinished(props.data.auctionid);
      }
    }
    
  }, [timeleft, timeouttime, timeout]);


  useEffect(() => {
    if (data) {
      console.log("SUB DATA?", data)
        setAuctionInfo(prevState => { 
          return{ ...prevState, 
            currentbuyer:  data.newAuctionBid.currentbuyer,
            currentprice: data.newAuctionBid.currentprice,
          } 
      })
    }
  }, [data]);
  
  return (
    <div className="content card">
      {auctioninfo &&
        <div>
          <Row>
            <Col md="8">
              <Card className='auctionDetails'>
              <CardHeader>
                <CardTitle tag="h4">{auctioninfo.name && auctioninfo.name}</CardTitle>
              </CardHeader>
                <CardBody>
             
                  <img alt={auctioninfo.currencyname + " Auction"} data-tip data-for={tooltipid} src={SERVERURL + "/images/currency/" + auctioninfo.currencyimg} className='auctionType'  />
                  <ReactTooltip id={tooltipid}>
                    This is a <span style={{color:'red'}}>{auctioninfo.currencyname && auctioninfo.currencyname.toUpperCase()} </span> auction. You will need a valid corresponding currency balance before placing a bid.
                  </ReactTooltip>

                  <Row>
                    <Col md="3" style={{paddingRight:"0px"}}>
                    {/* <img src={"/products/" + auctioninfo.mainimgid} className="floatLeft mainimg"  />
                      <br style={{clear:"both"}} />
                      <div className='add'>
                        <img src="/img-ph.jpg" className="floatLeft"  />
                        <img src="/img-ph.jpg" className="floatLeft"  />
                        <img src="/img-ph.jpg" className="floatLeft"  />
                        <img src="/img-ph.jpg" className="floatLeft"  />
                      </div> */}

                      <ImageGallery items={imgarray} showPlayButton={false}  showNav={false}  />

                      <br style={{clear:"both"}} /><br />

                      <p>
                        <span className='bold subTitle'>Status: </span>  
                        {auctioninfo.status && auctioninfo.status.name}
                      </p>

                      <p>
                        <span className='bold subTitle'>Start Time: </span>
                        {auctioninfo.startdatetime && formatDate(auctioninfo.startdatetime)}
                      </p>

                      <p>
                        <span className="bold subTitle">Current Price: </span>
                        {auctioninfo.currentprice && formatNumber(auctioninfo.currentprice / 100)}
                      </p>

                      <p>
                        <span className="bold subTitle">Retail Price: </span>
                        {auctioninfo.retailprice && formatNumber(auctioninfo.retailprice )}
                      </p>
                      
                      <p>
                        <span className="bold subTitle">Bid Multiplier: </span>
                        x {auctioninfo.bidmultiplier && auctioninfo.bidmultiplier}
                      </p>
                      <p>
                        <span className="bold subTitle">Current Buyer: </span> {auctioninfo.currentbuyer && auctioninfo.currentbuyer.displayname}
                      </p>

                      <p className="time">
                        <span className="bold">Time Remaining: </span>
                        <br />
                        {timeleft ?
                          timeleft.days + " Days, " + 
                          timeleft.hours + " Hours, " + 
                          timeleft.minutes + " Minutes, " + 
                          timeleft.seconds + " Seconds"
                          :
                          "Auction Ended"
                        }
                      </p>

                      {timeleft && !timeout && wt && wt.wt &&
                          <Button 
                          className="btn-fill" 
                          color="info" 
                          type="button" 
                          onClick = {(e) => PlaceTheBid()}
                          >
                            Bid
                          </Button>
                        }

                        {timeout && 
                          <></>
                          // <span className='timeout'> Processing </span>
                        }
                    </Col>

                    <Col md="8" className="longdesc">
                      <p className='bold subTitle'>Full Description</p>
                      {auctioninfo.longdescription && auctioninfo.longdescription}

                      <hr />
                      <Row>
                        <Col  md="8">
                          <p>
                            <span className='bold subTitle'>Product Code</span> <br />
                            {auctioninfo.productcode && auctioninfo.productcode}
                          </p>

                          <p>
                            <span className='bold subTitle'>Keywords</span><br />
                            {auctioninfo.keywords && auctioninfo.keywords}
                          </p>

                          <p>
                            <span className='bold subTitle'>Category</span> <br />
                            {auctioninfo.category && auctioninfo.category.name}
                          </p>
                        </Col>

                        <Col md="4">
                          <p>
                            <span className='bold subTitle'>Shipping Information</span><br />
                            {auctioninfo.shipping && auctioninfo.shipping.name}<br />
                            <span>{auctioninfo.shipping && auctioninfo.shipping.description}</span><br/>
                            <span>{auctioninfo.shipping && formatNumber(auctioninfo.shipping.rate)}</span>
                          </p>
                        </Col>
                      </Row>

                      <Row>
                      <Col md="12">
                        {auctioninfo.buyitnow &&
                          <div>
                            <hr />
                            <p className='bold subTitle'>Buy It Now Information</p>

                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                            </p>

                            <p className="floatRight">
                              <span className="bold subTitle">Buy It Now Price: </span>
                              {auctioninfo.buyitnowprice && formatNumber(auctioninfo.buyitnowprice )}
                            </p>
                          <br style={{clear:"both"}} />
                          {wt && wt.wt &&
                            <Button 
                            className="btn-fill floatRight" 
                            color="info" 
                            type="button" 
                            onClick = {(e) => BuyItNow()}
                            >
                              Buy It Now
                            </Button>
                          }
                          </div>
                        }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter> 
                  <Row>
                    <Col md="4" style={{textAlign:"center"}}>
                      <p className='bold'>{auctioninfo.watchlistid ? "Watching" : "Not Watching"}</p>
                      <img alt = "Watch this auction" src= {`${auctioninfo.watchlistid ? '../fav2.png' : '../nofav2.png'}`} className='watchlistimg' 
                        onClick = {
                          () => {
                            !auctioninfo.watchlistid ? 
                              addWatchList({ variables: { auctionid: auctioninfo.auctionid} })
                              .then((e) => {
                                if(e)
                                  setAuctionInfo(prevState => { 
                                    return{ ...prevState,  watchlistid:  e.data.addWatchList } 
                              })
                            })
                            :
                              deleteWatchList({variables:{watchlistid: auctioninfo.watchlistid }})
                              .then((e) => {
                                setAuctionInfo(prevState => { 
                                  return{ ...prevState,  watchlistid:  undefined } 
                              })
                            })
                          }
                        }
                      />
                  
                    </Col>
                    <Col md="8">
                      <p className='bold' style={{marginTop:"20px"}}>
                        Want to keep an eye on this auction? Add it to your personal watchlist and easily watch it in your account section.
                      </p>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>

            <Col md="4">
              <Row>
                <Col md="12">
                  {id && <Autobidder auctionid = {id} active={auctioninfo.active} /> }
                </Col>
              </Row>

              <Row style={{marginTop:"20px"}}>
                <Col md="12">
                  {id && <RecentBids auctionid = {id} mode="auction" /> }
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              {auctioninfo.category && <SimilarAuctions category = {auctioninfo.category.categoryid} /> }
            </Col>
          </Row>

          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              {id && <FeaturedAuctions auctionid = {id} /> }
            </Col>
          </Row>
        </div>
      }
    </div>
  )
}

export default AuctionDetails;