import { gql } from '@apollo/client';

export const GET_BIDPACKS = gql`
query getBidPacks{
  getBidPacks {
  bidpackid
  name
  shortdescription
  longdescription
  productcode
  keywords
  mainimgid
  price
  amount
  bidpackcurrency{
      name
      currencyid
      imgid
    }
  }
}
`;