import { gql } from '@apollo/client';

export const GET_ABIDDER_LIST = gql`
query getABidderList {
  getABidderList {
    name
    displayname
    customerid
  }
}
`;