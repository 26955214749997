import { useQuery } from "@apollo/client";
import { useAuth } from "components/custom/authprovider";
import XTable from "components/custom/table";
import { GET_ADMIN_AUCTIONS } from "graphql/admin/queries/auction";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDate } from "utils/date";
import { getDeviceFingerprint } from "utils/functions";
const { CardHeader, CardBody, Card, CardTitle, Button } = require("reactstrap");

function AuctionList(props){
  const {wt}  = useAuth();
  const history = useHistory();

  const { loading, error, data, onError } = useQuery( GET_ADMIN_AUCTIONS,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [displaylist, setList] = useState();

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setList(() => data.getAdminAuctions);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);


  const listHeader = [
    {
      Header: "product",
      accessor: "productinfo",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value.name}</span>
      )
    },
    {
      Header: "Start",
      accessor: "startdatetime",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatDate(props.value)}</span>
      )
    },
    {
      Header: "End",
      accessor: "enddatetime",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatDate(props.value)}</span>
      )
    },
    {
      Header: "Auction Status",
      accessor: "active",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>
          {props.value ? "Active" : "Inactive"}
        </span>
      )
    },
    {
      Header: "Buyer",
      accessor: "currentbuyer",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Actions",
      accessor: "categoryid",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (propsa) =>(
        <Button
        className="btn-fill specialbutton" 
        color="info" 
        type="button" 
        onClick ={ (e) => props.ViewDetails(propsa.row.original) }
        >Edit</Button>
      )
    },
  ];

  return (
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">Auction List</CardTitle>
      </CardHeader>
      <CardBody className={ ' wonauctions'} >
        {displaylist &&
          <XTable columns={listHeader} loading={false} data={displaylist} filterbutton={false} />
        }
      </CardBody>
    </Card>
  )
}

export default AuctionList;