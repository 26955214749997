import { useMutation } from "@apollo/client";
import SideNav from "components/admin/shared/sidenav";
import { useState } from "react";
import { CREATE_BIDPACK } from "graphql/admin/mutations/bidpacks";
import { UPDATE_BIDPACK } from "graphql/admin/mutations/bidpacks";
import BidpackList from "components/admin/bidpacks/bidpacklist";
import AdminBidpack from "components/admin/bidpacks/bidpack";
import { getDeviceFingerprint } from "utils/functions";
import { useAuth } from "components/custom/authprovider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { CardBody, Card, Col, Row, Button } = require("reactstrap");

function AdminBidpacks(){
  const [view, setView] = useState("list");
  const [selectedRow, setSelectedRow] = useState();
  const [customerrors, setCustomErrors] = useState();
  const {wt}  = useAuth();
  const history = useHistory();

  const EditDetails = (data) => {
    setSelectedRow(data)
    setView("edit");
  }
  const [createBidpack] = useMutation(CREATE_BIDPACK, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [updateBidpack] = useMutation(UPDATE_BIDPACK, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const AddNew = (data) => {
    createBidpack( { variables: 
      {
        data: {
          name: data.name,
          shortdescription: data.shortdescription,
          longdescription: data.longdescription,
          keywords: data.keywords,
          amount: data.amount,
          price: data.price * 100,
          bidpackcurrency: data.bidpackcurrency,
          productcode: data.productcode,
          customerid: data.customerid,
          active: data.active
        }
      } 
    } )
    .then((res) => {
      if(res.data){ 
        data.bidpackid = res.data.createBidpack;
        data.datetimeupdated = Date().toLocaleString();
        data.datetimecreated = Date().toLocaleString();
        EditDetails(data)
      }
    })
  }

  const Edit = (data) => {
    updateBidpack( { variables: 
      {
        data: {
          name: data.name,
          shortdescription: data.shortdescription,
          longdescription: data.longdescription,
          keywords: data.keywords,
          amount: data.amount,
          price: data.price,
          bidpackcurrency: data.bidpackcurrency,
          productcode: data.productcode,
          customerid: data.customerid,
          active: data.active,
          bidpackid: data.bidpackid
        }
      } 
    } )
    .then((res) => {
      if(res.data)
        setView("list")
    })
  }

  return (
    <div className="content card">
      <Row>
        <Col md="3" lg="2"> <SideNav /> </Col>
        <Col md="9" lg="10">
          <Row>
            <Col md="12">
              <Card className="auctionDetails">
                <CardBody>
                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick = {() => setView("add")}
                    >Add
                  </Button>

                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick = {() => setView("list")}
                    >View List
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              {
                view === "list" ? <BidpackList ViewDetails = { EditDetails } /> : 
                view === "add" ? <AdminBidpack mode = "add" title = "Add New Bidpack" customerrors = {customerrors} FinalizeFunction = {AddNew} /> :
                view === "edit" ? <AdminBidpack mode = "edit" title = "Edit Bidpack" customerrors = {customerrors} data = {selectedRow} FinalizeFunction = {Edit} /> :
                ""
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default AdminBidpacks;