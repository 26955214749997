import { gql } from '@apollo/client';


export const UPDATE_CUSTOMER_CURRENCY = gql`
mutation updateAdminCustomerCurrency($data : AdminCustomerCurrencyUpdate!) {
  updateAdminCustomerCurrency(data: $data) 
}
`;

export const DISABLE_CUSTOMER = gql`
mutation disableCustomer($customerid : String!, $active: Boolean!) {
  disableCustomer(customerid: $customerid, active: $active) 
}
`;