import { useLazyQuery, useQuery } from "@apollo/client";
import { useAuth } from "components/custom/authprovider";
import XTable from "components/custom/table";
import { GET_EXTERNAL_REFERENCES } from "graphql/customer/queries/users";
import { IMPORT_ACCOUNT_PRIVATE } from "graphql/customer/queries/users";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AppTitle } from "utils/defaults";
import { getDeviceFingerprint } from "utils/functions";
import { SAText } from "utils/functions";
import { FormatPhone } from "utils/functions";
import { getLoggedInUser } from "utils/useToken";
const { CardHeader, CardBody, Card,  CardTitle, Button, Row, Col, FormGroup, Input } = require("reactstrap");

function LinkedAccounts(props){
  const history = useHistory();
  const {wt}  = useAuth();
  const [refs, setRefs] = useState([]);
  const [affiliatelinked, setAffiliateLink] = useState(false);
  const [affiliateinfo, setAffiliateInfo] = useState({
    "phone": "",
    "platform": ""
  });

  const  { data:affiliates, refetch } = useQuery(GET_EXTERNAL_REFERENCES, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });
  
  const [getAAMDistListPrivate, { data:importedData}] = useLazyQuery(IMPORT_ACCOUNT_PRIVATE, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  useEffect(() => {
    if(affiliates){
      console.log("a", affiliates)
      if(affiliates.customerAffilates.data){
        const externalreference = JSON.parse(affiliates.customerAffilates.data)
        let tmpArray = [];
        let refArray = [];
        if(externalreference){
          externalreference.ExternalReferences.map((value) => {
            return tmpArray.push(Object.keys(value)[0]);
          })
          
          if(tmpArray.length > 0){
            for(let x = 0; x < tmpArray.length; x++){
              externalreference.ExternalReferences.map((value) => {
                if(value[tmpArray[x]]){
                  return(
                    value[tmpArray[x]].DistList.map((a) => {
                      return (
                        refArray.push({
                          "platform": tmpArray[x],
                          "distname": a.name,
                          "distcode": a.distributorcode
                        })
                      )
                    })
                  )
                }
                else return false;
              })
            }
          }
        }

        setRefs(refArray);
      }
    }
  }, [affiliates]);

  const ResetForm = () => {
    setAffiliateInfo({
      "phone": "",
      "platform": ""
    })

    setAffiliateLink(false);
  }

  const ImportAccount = () => {
    if(affiliateinfo.platform === "") 
      SAText("Error", "Affiliate is required", "error");
    else if(affiliateinfo.phone === "")
      SAText("Error", "Affiliate phone is required", "error");
    else{
      getAAMDistListPrivate({variables: { phone: affiliateinfo.phone, update: true }})
      .then((res) => {
        if(res.data.getAAMDistListPrivate.data.customer){
          if(res.data.getAAMDistListPrivate.data.customer.customerid === ""){
            SAText("Error", "No account found", "error");
            ResetForm();
          }
          else{
            refetch();
            ResetForm();
          }
        }
      })
      .catch((error) => {
        SAText("Error", error.message, "error")
      })
    }
  }

  const listHeader = [
    {
      Header: "Platform",
      className: "t-cell-1 text-centered",
      accessor: "platform",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Distributor",
      accessor: "distname",
      className: "t-cell-2 text-centered",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
    },
    {
      Header: "Code",
      accessor: "distcode",
      className: "t-cell-2 text-centered",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
    }
  ];

  return (
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">Affiliate Accounts</CardTitle>
      </CardHeader>
      <CardBody className={props.mode === "customer" ? 'customerBids  recentBids' : ' recentBids'} >
      <div>
        <Row>
          <Col md="12">
            <p style={{color:"lime", fontSize:"larger"}}>If your affiliate account has changed in someway, please update by filling in the form below to update your {AppTitle} account.</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormGroup>
              <label className="subTitle">* Affiliate:</label>
              <select className={'form-control'}
                name="platform"
                value={affiliateinfo.platform}
                readOnly = {affiliatelinked ? true : false}
                onChange={ e => { 
                  setAffiliateInfo(prevState => {
                    return { ...prevState, [e.target.name]: e.target.value}
                  })
                }}
                maxLength={2}
              >
                <option value="">Select an Affiliate</option>
                <option value="AAM">Active Action Media</option>
              </select>
            </FormGroup>
          </Col>

          <Col md="4">
            <FormGroup>
              <label className="subTitle">Phone:</label>
              <Input
                placeholder="Phone"
                type="text"
                name="phone"
                readOnly = {affiliatelinked ? true : false}
                value={affiliateinfo.phone}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => FormatPhone(e, setAffiliateInfo) }
              />
            </FormGroup>
          </Col>

          <Col md="4">
            {!affiliatelinked && 
              <Button 
                className="btn-fill" 
                color="info" 
                type="button" 
                style={{marginTop:"20px"}}
                onClick = {(e) => ImportAccount()}
                >
                  Update 
              </Button>
            }
            {affiliatelinked &&
              <Button 
                className="btn-fill" 
                color="info" 
                type="button" 
                style={{marginTop:"20px"}}
                onClick = {(e) => ResetForm()}
                >
                  Reset 
              </Button>
            }
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {affiliatelinked &&
              <span style={{color:"lime", fontSize:"larger", fontWeight: "bold", display:"block", textAlign:"center"}}>ACCOUNT LINKED</span>
            }
          </Col>
        </Row>
        <hr style={{border:"2px inset gold"}} />
      </div>

        {refs &&
          <XTable columns={listHeader} loading={false} data={refs} filterbutton={false} />
        }
      </CardBody>
    </Card>
  )
}

export default LinkedAccounts;