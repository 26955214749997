import { gql } from '@apollo/client';

export const GET_ADMIN_BIDPACKS = gql`
query getAdminBidpacks {
  getAdminBidpacks {
    name
    shortdescription
    longdescription
    keywords
    active
    bidpackid
    mainimgid
    amount
    price
    bidpackcurrency
    currencyname
    productcode
    datetimeupdated
    datetimecreated
  }
}
`;