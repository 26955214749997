import React, { useState, useContext, useEffect } from 'react';
import { APPCOOKIE } from 'utils/defaults';
import AuthContext from './authcontext';


const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Initial state
  const [isLoaded, setIsLoaded] = useState(false);
  const [wt, setWT] = useState({});
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const checkToken = async () => {
      const tokenString = localStorage.getItem(APPCOOKIE);
      const userToken = JSON.parse(tokenString);
      if(userToken && userToken.wt) {
        setIsAuthenticated(true);
        setWT({wt: userToken.wt, dt: userToken.dt})
        setUserData({sstring: userToken.sstring, displayname: userToken.displayname, currency: userToken.currency})
      }
      setIsLoaded(true)
    };
    checkToken();
  }, []);

  // Add functions for login, logout, etc. using AsyncStorageSecure or other storage

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, isLoaded, setIsLoaded, wt, setWT, userData, setUserData /* other functions */ }}>
      {/* {children} */}
      {isLoaded ? children : null}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => React.useContext(AuthContext);