import { useMutation, useQuery } from "@apollo/client";
import { useAuth } from "components/custom/authprovider";
import XTable from "components/custom/table";
import { REJECT_AUCTION } from "graphql/customer/mutations/auction";
import { SEND_SMS } from "graphql/customer/queries/users";
import { VERIFY_SMS } from "graphql/customer/queries/users";
import { GET_WON_AUCTIONS } from "graphql/customer/queries/wonauctions";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/date";
import { SMSAlert } from "utils/defaults";
import { status } from "utils/defaults";
import { SAlert } from "utils/functions";
import { getDeviceFingerprint } from "utils/functions";
import { SAText } from "utils/functions";
import { SAConfirmReason } from "utils/functions";
import { formatNumber } from "utils/functions";
const { CardHeader, CardBody, Card, CardTitle, Button } = require("reactstrap");

function WonAuctions(props){
  let history = useHistory();
  const {wt}  = useAuth();

  const { loading, error, data, onError, refetch } = useQuery( GET_WON_AUCTIONS,{
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const GoToCheckout = (auctionid) =>{
    history.push({
      pathname: 'checkout',
      state: {
        type: 'auction',
        data: wonauctions.find(a => a.auctionid === auctionid)
      }
    })
  }

  const [verifySMS] = useMutation(VERIFY_SMS, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [sendSMS] = useMutation(SEND_SMS, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [rejectAuction] = useMutation(REJECT_AUCTION, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });


  const ConfirmRejectAuction = (auctionid, reason) => {
    SAlert(SMSAlert.title, "text", SMSAlert.label, SMSAlert.icon, (v) => {
      verifySMS({ variables: { code: v, type:"gifteerejectauction"} })
      .then((r2) => {
        if(r2.data.verifySMS.status){
          rejectAuction({ variables: { auctionid, reason} })
          .then((rejresult) => {
            if(rejresult.data.rejectAuction.status)
              refetch()   
            else
              SAText("Error", rejresult.data.rejectAuction.error, "error")
          })
          .catch((err) => {
            SAText("Error", err, "error")
          })
          .finally( () => {
            //TODO: Set Loading
          })
        }
        else
          SAText("Error", r2.data.verifySMS.error, "error", () => ConfirmRejectAuction(auctionid, reason))
      })
    })
  }

  const RejectAuction = (auctionid) => {
    SAConfirmReason("Confirm Rejection of Auction", "We are sorry to hear that you are rejecting this auction. Please provide a reason for the rejection to continue", (reason) => {
      if(reason.trim() !== ""){
        //Reason Provided - execute mutation and reject auction
        sendSMS({ variables: {type: "gifteerejectauction"}})
        .then((smsres) => {
          if(smsres.data.sendSMS.status){
            ConfirmRejectAuction(auctionid, reason)
          }
          else{
            SAText("Error", smsres.data.sendSMS.error, "error")
          }
        })
        .catch((err) => {
          SAText("Error", err, "error")          
        })
        .finally(() =>{
          //TODO: Set loading
        })
      }
    })
  }
  

  const [wonauctions, setWonAuctions] = useState();

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setWonAuctions(() => data.getWonAuctions.auctionlist);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);


  const listHeader = [
    {
      Header: "DateTime",
      accessor: "enddatetime",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatDate(props.value)}</span>
      )
    },
    {
      Header: "Product",
      accessor: "product",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value.name}</span>
      )
    },
    {
      Header: "Final Bid",
      accessor: "currentprice",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatNumber(props.value / 100)}</span>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value.name}</span>
      )
    },
    {
      Header: "Shipping",
      accessor: "shipping",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatNumber(props.value.rate)}</span>
      )
    },
    {
      Header: "Total",
      // accessor: "shipping",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatNumber( parseFloat(props.row.original.currentprice / 100) + parseFloat(props.row.original.shipping.rate) )} </span>
      )
    },
    {
      Header: "Actions",
      accessor: "auctionid",
      className: "t-cell-3 text-centered mwTable-30",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>
          {props.row.original.status.statusid.toLowerCase() !== status.auctionrejected &&
          <>
            <Button
            className="btn-fill floatLeft specialbutton" 
            color="info" 
            type="button" 
            onClick ={ (e) => GoToCheckout(props.value)}
            >Checkout</Button>

            <Button
            className="btn-fill floatRight specialbutton" 
            color="info" 
            type="button" 
            onClick = { (e) => RejectAuction(props.value) }
            >Reject</Button>
          </>
          }
        </span>
      )
    },
  ];

  return (
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">Won Auctions</CardTitle>
      </CardHeader>
      <CardBody className={ ' wonauctions'} >
        {wonauctions &&
          <XTable columns={listHeader} loading={false} data={wonauctions} filterbutton={false} />
        }
      </CardBody>
    </Card>
  )
}

export default WonAuctions;