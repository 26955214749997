import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext({
  isLoaded: false, 
  setIsLoaded: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  wt: null,
  setWT: () => {},
  userData: null,
  setUserData: () => {}
  // Add other auth-related state and functions (e.g., user data, login/logout)
});

export default AuthContext;
