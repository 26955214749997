import { useQuery } from "@apollo/client";
import { useAuth } from "components/custom/authprovider";
import XTable from "components/custom/table";
import { GET_ADMIN_CUSTOMER_WATCHLIST } from "graphql/admin/queries/customer";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDate } from "utils/date";
import { getDeviceFingerprint } from "utils/functions";
import { formatNumber } from "utils/functions";
const { CardHeader, CardBody, Card, CardTitle, Button } = require("reactstrap");

function AdminWatchlist(props){
const history = useHistory();
const {wt}  = useAuth();

  const { loading, error, data, onError, refetch } = useQuery( GET_ADMIN_CUSTOMER_WATCHLIST,{
    variables: {
      customerid:  props.customerid
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [watchlist, setCustomerWatchList] = useState();

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setCustomerWatchList(() => data.getTheWatchlist);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);


  const listHeader = [
    {
      Header: "DateTime",
      accessor: "datetimeadded",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatDate(props.value)}</span>
      )
    },
    {
      Header: "Product",
      accessor: "productname",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Current Bid",
      accessor: "currentprice",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatNumber(props.value)}</span>
      )
    },
    {
      Header: "Current Buyer",
      accessor: "currentbuyer",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Active",
      accessor: "active",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>
          {props.value ? "Active" : "Inactive"}
        </span>
      )
    },
    {
      Header: "View",
      accessor: "auctionid",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>
          <a title={"View Auction"} className="nav-link subTitle" href={"/auction-details/" + props.value} target="_blank" rel="noreferrer">
            View
          </a>
        </span>
      )
    },
  ];

  return (
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">Watch List | <span style={{color:"lime", fontSize:"smaller"}}>{props.title}</span></CardTitle>
      </CardHeader>
      <CardBody className={ ' recentBids'} >
        {watchlist &&
          <XTable columns={listHeader} loading={false} data={watchlist} filterbutton={false} />
        }
      </CardBody>
    </Card>
  )
}

export default AdminWatchlist;