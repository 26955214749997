import validator from 'validator' 
import Swal from 'sweetalert2'
import { APPCOOKIE } from './defaults'

export const checkDuration = (duration) => {
  if(duration.days === 0 && duration.hours === 0 && duration.minutes === 0 && duration.seconds === 0)
    return false
  else return true
}

export const validatePhoneNumber = (number) => {
  const isValidPhoneNumber = validator.isMobilePhone(number)
  return (isValidPhoneNumber)
}

export const validateEmail = (email) => {
  const isValidEmail = validator.isEmail(email)
  console.log(isValidEmail)
  return (isValidEmail)
}

export const validateZip = (zip) => {
  return /^\d{5}(-\d{4})?$/.test(zip);
}

export const handleInputFocus = (event) => event.target.select();

export const formatNumber = (q) => {
  if(q){
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(q);
  }
  else return 0
} 


export const SAlert = (title, input, label, icon, callback = undefined) => {
  Swal.fire({
    title: title,
    icon: icon,
    input: input,
    inputLabel: label,
    showCancelButton: true,
    allowOutsideClick: false,
    inputValidator: (value) => {
      if (!value) {
        return 'Please entered required information'
      }
      else{
        if(isNaN(value) || value.length < 4)
          return 'Please enter a numeric value that has a least four digits';
        else{
          if(callback){
            callback(value);
          }
        }
      }
    }
  })
}

export const SAText = (title, text, icon, callback = undefined) => {
  Swal.fire({
    icon: icon,
    title: title,
    text: text,
  })
  .then(() => {
    if(callback)
      callback();
  })
}

export const SAConfirmReason = (title, text, callback = undefined) => {
  Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    input: "textarea",
    showCancelButton: true,
    // confirmButtonColor: '#3085d6',
    // cancelButtonColor: '#d33',
    confirmButtonText: 'Agree and Continue',
    inputValidator: (value) => {
      if(value.trim() !== "")
        callback(value)
      else
        return 'Please provide a reason for the rejection of this auction.';
    }
  })
}

export const SAConfirm = (title, text, callback = undefined) => {
  Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    // confirmButtonColor: '#3085d6',
    // cancelButtonColor: '#d33',
    confirmButtonText: 'Agree and Continue'
  }).then((result) => {
    if (result.isConfirmed) {
      if(callback)
        callback();
    }
  })
}

export const FormatPhone = (e, stateFunc) => {
  let curval = e.target.value;
  
  // Remove all non-digit characters to simplify formatting
  let cleaned = curval.replace(/\D/g, '');

  // Format the phone number
  let formatted = '';
  if (cleaned.length <= 3) {
    formatted = cleaned;
  } else if (cleaned.length <= 6) {
    formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
  } else {
    formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  }

  stateFunc(prevState => ({ ...prevState, phone: formatted }));
};


export const SimpleFormatPhone = (value, stateFunc) =>{
  let curval = value;
  
  // Remove all non-digit characters to simplify formatting
  let cleaned = curval.replace(/\D/g, '');

  // Format the phone number
  let formatted = '';
  if (cleaned.length <= 3) {
    formatted = cleaned;
  } else if (cleaned.length <= 6) {
    formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
  } else {
    formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  }

  return formatted
}

export function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
charactersLength));
 }
 return result;
}

export const getDeviceFingerprint = () => {
  const { ClientJS } = require ('clientjs');
  const fingerprintClient = new ClientJS();
  const fingerprint = fingerprintClient.getFingerprint();
  return fingerprint
}

export const checkPasswordStrength = (password) => {
  const lowerCaseRegex = /[a-z]/;
  const upperCaseRegex = /[A-Z]/;
  const numberRegex = /[0-9]/;
  const specialCharacterRegex = /[^A-Za-zÀ-ÖØ-öø-ÿ]+/;

  if (!password.match(numberRegex)) {
    return { status: false, error: "Password must contain at least one number." }
  }

  if (!password.match(upperCaseRegex)) {
    return { status: false, error: "Password must contain at least one uppercase letter." }
  }

  if (!password.match(numberRegex)) {
    return { status: false, error: "Password must contain at least one lowercase letter." }
  }

  if (!password.match(specialCharacterRegex)) {
    return { status: false, error: "Password must contain at least one special character." }
  }

  return { status: true, error: null}
};

export const isTokenExpired = (time) => {
  // if(time === undefined || Date.now() > parseInt(time) + SessionTimeout) {
  if(time === undefined || Date.now() > parseInt(time)) {
    localStorage.removeItem(APPCOOKIE);
    return true;
  }
  else {
    return false;
  } 
}