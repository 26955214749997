import { useQuery } from "@apollo/client";
import BidPack from "../../components/customer/shared/bidpack";
import { GET_BIDPACKS } from "graphql/customer/queries/bidpacks";
import React, { useEffect, useState } from "react";
import { useAuth } from "components/custom/authprovider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDeviceFingerprint } from "utils/functions";

const { CardHeader, CardBody, Card, Col, Row } = require("reactstrap");

function BidPacks(){
  const history = useHistory();
  const {wt}  = useAuth();

  const [bidpacklist, setBidPacks] = useState();

  const { loading, error, data, onError } = useQuery(GET_BIDPACKS,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setBidPacks(() => data.getBidPacks);
      } 
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  return (
    <div className="content">
    <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <span className="pageHeader"> BidPacks </span>
            </CardHeader>
            <CardBody>
              <Row>
              {bidpacklist &&
                bidpacklist.map((value, key) => {
                  return (
                    <Col key={key}  sm="12" md="6" lg="3">
                      <BidPack key={key}  data = {value}  />
                    </Col>
                  );
                })
              }
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default BidPacks;