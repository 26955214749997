import { gql } from '@apollo/client';

export const CREATE_ABIDDER = gql`
mutation createABidder($data : ABidder!) {
  createABidder(data: $data) 
}
`;

export const UPDATE_ABIDDER = gql`
mutation updateABidder($data : ABidder!) {
  updateABidder(data: $data) 
}
`;