import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_AUTOBID_DETAILS } from "graphql/customer/queries/autobid";
import { GET_CUSTOMER_AUTOBIDS } from "graphql/customer/queries/autobid";
import MiniAuction3 from "../AuctionDetails/miniauction3";
import { UPDATE_AUTOBID } from "graphql/customer/mutations/autobid";
import { SAText } from "utils/functions";
import { DELETE_AUTOBID } from "graphql/customer/mutations/autobid";
import { ADD_AUTOBID } from "graphql/customer/mutations/autobid";
import { PLACE_BID } from "graphql/customer/mutations/auction";
import { SAConfirm } from "utils/functions";
import { getDeviceFingerprint } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "components/custom/authprovider";
const { CardHeader, CardBody, Card, Col, Row, FormGroup, Input, Button, CardTitle } = require("reactstrap");

function Autobid(props){
const history = useHistory();
const {wt}  = useAuth();

  const { loading, error, data, onError, refetch } = useQuery(props.auctionid ? GET_AUTOBID_DETAILS : GET_CUSTOMER_AUTOBIDS,{
    variables: {
      auctionid: props.auctionid ? props.auctionid : undefined,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [autobids, setAutoBids] = useState([]);
  const [newautobid, setNewAutoBid] = useState(0);

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setAutoBids(() => data.getAutobids);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  const [updateAutobidder] = useMutation(UPDATE_AUTOBID, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [deleteAutobidder] = useMutation(DELETE_AUTOBID, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [addAutobidder] = useMutation(ADD_AUTOBID, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [placeBid] = useMutation(PLACE_BID, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  return (
    <div className="content">
    <Row>
        <Col md="12">
        <Card className='auctionDetails'>
            {!props.auctionid &&
              <CardHeader>
                <CardTitle tag="h4">Your Current Autobidders</CardTitle>
              </CardHeader>
            }
            <CardBody>
              
              <p className='flashy'>The count doesn't automatically refresh. Click the Refresh All Button to get the most recent up to date autobidder counts for all auctions.</p>
              <Button 
                className='refreshbutton'
                onClick={(e) => refetch()}
              > Refresh All</Button>

              {props.auctionid && autobids.length === 0 && //Show form to add new autobidder for auction
                <>
                  <Row className="autobidBlock2">
                    <Col md="9">
                      <FormGroup>
                        <label className="subTitle">Set Bid Count:</label>
                        <Input
                          type="number"
                          name="count"
                          placeholder="0"
                          value={newautobid}
                          autoComplete = "off"
                          onChange={ e => {
                              setNewAutoBid(prevState => { return  parseFloat(e.target.value) }) 
                            } 
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <Button
                        className="btn-fill specialbutton" 
                        color="info " 
                        type="button" 
                        style={{marginTop:"22px"}}
                        onClick = { (e) => {
                          SAConfirm("Confirm Autobidder", "When finalizing this autobidder, a bid will be initiated on the current auction. If other users have an autobidder added to the same auction, then another bid will be placed and so on. This could result in an immediate use of all autobids.", () => {
                            addAutobidder({ variables: { auctionid: props.auctionid, count: newautobid} })
                            .then((e) => {
                              if(e.data){
                                placeBid({ variables: {  auctionid: props.auctionid.toLowerCase(), autobid: true} })
                                .then ((q) => {
                                  //set state of existing bids so screen changes
                                  SAText("Success", "Autobidder added successfully", "success")
                                  setNewAutoBid(0);
                                  refetch();
                                })
                              }
                            })
                          })
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </>
              }

              {autobids && autobids.length > 0 && //Show All current autobidders with updates to specific auctions
                <Row>
                  

                  {autobids.map((value, key) => {
                    return (
                      <React.Fragment key={key}>
                        <Col sm = {`${props.auctionid ? '12' : '12'}`} md = {`${props.auctionid ? '12' : '12'}`}  lg = {`${props.auctionid ? '12' : '4'}`} className={`${props.auctionid ? 'autobidBlock2' : 'autobidBlock'}`}>
                          
                          <Row>
                            <Col md = {`${props.auctionid ? '4' : '4'}`}>
                              <FormGroup >
                                <label className="subTitle">Remaining:</label>
                                <Input
                                  type="number"
                                  name="count"
                                  value={value.count}
                                  autoComplete = "off"
                                  onChange={ e => {
                                      let oldArray = autobids;
                                      let newArray = oldArray;

                                      let editValue = newArray.find(a => a.autobidid === value.autobidid);
                                      let index = newArray.findIndex(a => a.autobidid === value.autobidid);

                                      const a = Object.assign({}, editValue, {count: parseInt(e.target.value)})
                                      newArray = oldArray.filter(a => a.autobidid !== value.autobidid);
                                      // newArray.push(a);
                                      newArray.splice(index, 0, a)
                                      setAutoBids(prevState => { return  newArray }) 
                                    } 
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <Button
                                className="btn-fill specialbutton" 
                                color="info" 
                                type="button" 
                                style={{marginTop:"22px"}}
                                onClick = { (e) => {
                                  updateAutobidder({ variables: { autobidderid: value.autobidid, count: autobids.find(a => a.autobidid === value.autobidid).count} })
                                  .then((e) => {
                                    placeBid({ variables: { auctionid: props.auctionid ? props.auctionid.toLowerCase() : value.auctionid.toLowerCase, autobid: true} })
                                    .then ((q) => {
                                      //set state of existing bids so screen changes
                                      refetch();
                                    })
                                  })
                                }}
                              >
                                Update
                              </Button>
                            </Col>

                            <Col md="4">
                              <Button
                                className="btn-fill specialbutton" 
                                color="info" 
                                type="button" 
                                style={{marginTop:"22px"}}
                                onClick = { (e) => {
                                  deleteAutobidder({ variables: { autobidderid: value.autobidid} })
                                  .then((q) => {
                                    setNewAutoBid(prevState => { return  parseFloat(0) }) 
                                    refetch();
                                  })
                                }}
                              >
                                Delete
                              </Button>
                            </Col>
                            {!props.auctionid &&
                            <Row>
                              <Col md="12">
                                <MiniAuction3 auctionid={value.auctionid} showBidButton={false} />
                              </Col>
                            </Row>
                            }
                          </Row>
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>
              }

            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Autobid;