import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { formatDate } from "utils/date";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";

function AdminAffilate(props){
  const [fielderrors, setFieldError] = useState([]);
  const [affilateinfo, setAffilateInfo] = useState({
    "name": "",
    "description": "",
    "link": "",
    "active": true,
    "datetimecreated": "",
    "affilateid": "",
  })

  useEffect(() => {
    if(props.mode === "add"){
      setAffilateInfo({    
        "name": "",
        "description": "",
        "link": "",
        "active": true,
        "datetimecreated": "",
        "affilateid": "",
      })
    }
}, [ props.mode]);
  
  useEffect(() => {
      if(props.data){
        setAffilateInfo(() => props.data);
      }
  }, [props.data]);

  const UpdateChange = (e, type = '') => {
    if(type !== "array"){
      let value = e.target.value;
      if(e.target.type === 'number'){
        if(value === '') value = 0;
        else value = parseInt(value);
      }
  
      if(type === 'boolean') {
        if(typeof(value) === "string"){
          const a = (value === 'true')
          value = a;
        }
      }
  
      setAffilateInfo(prevState => {
        return { ...prevState, [e.target.name]: value}
      })
    }
    else{
      setAffilateInfo(prevState => {
        return { ...prevState, [e.name]:e.value}
      })   
    }
  }

  const CheckError = (e) =>{
    if(fielderrors.length > 0){
      const q = fielderrors.find((a)=>a.field === e);
      if(q) return q.error; else return false;
    }
    else return false;
  }

  const SubmitForm = () => {
    let valid = true;
    setFieldError(() => [])

    if(affilateinfo.name.trim() === "" || affilateinfo.name === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "name", error: "Name is required"}] })
    }

    if(affilateinfo.description.trim() === "" || affilateinfo.description === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "description", error: "Description is required"}] })
    }

    if(affilateinfo.link.trim() === "" || affilateinfo.link === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "link", error: "Link is required"}] })
    }

    if(valid){
      props.FinalizeFunction(affilateinfo);
    }
  }

  useEffect(() => {
    if(props.customerrors !== undefined)
      setFieldError(prevState => { return [ ...prevState, props.customerrors] })
  }, [props.customerrors]);

  return (
    <div className="content">
      <Card className="auctionDetails">
        <CardHeader>
          <CardTitle tag="h4">{props.title}</CardTitle>
        </CardHeader>
        <CardBody>
          {props.mode === "edit" &&
            <Row>
              <Col md="6">
                <p>Added On: {formatDate(affilateinfo.datetimecreated)}</p>
              </Col>
            </Row>
          }
        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Name:</label>
              <Input
                placeholder="Name"
                type="text"
                name="name"
                className={CheckError("name") ? 'error' : ''}
                value={affilateinfo.name}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => UpdateChange(e) }
              />
              <span className='errorMessage'>{CheckError("name") ? CheckError("name") : ''}</span>
            </FormGroup>
          </Col>
          <Col className="pr-md-1" md="6">
            <FormGroup>
              <label className="subTitle">* Link:</label>
              <Input
                placeholder="Link"
                type="text"
                name="link"
                className={CheckError("link") ? 'error' : ''}
                value={affilateinfo.link}
                autoComplete = "off"
                maxLength={400}
                onChange={ e => UpdateChange(e) }
              />
              <span className='errorMessage'>{CheckError("link") ? CheckError("link") : ''}</span>
            </FormGroup>
          </Col>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Status: </label>
              <select className='form-control'
                name="active"
                value={affilateinfo.active}
                onChange={ e => UpdateChange(e, "boolean") }
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="subTitle"> * Description: </label>
              <Input
                placeholder="Description" 
                cols="80"
                rows="4"
                type="textarea"
                name="description"
                value={affilateinfo.description}
                autoComplete = "off"
                className={CheckError("description") ? 'error' : ''}
                onChange={ e => UpdateChange(e) }
                />
                <span className='errorMessage'>{CheckError("description") ? CheckError("description") : ''}</span>
            </FormGroup>
          </Col>
        </Row>
        </CardBody>
        <CardFooter>
        <Button 
          className="btn-fill floatRight" 
          color="info" 
          type="button" 
          onClick = {(e) => SubmitForm()}
          >
            {props.mode === "add" ? "Add New Affiliate" : "Update Affiliate"}
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}

export default AdminAffilate;