import { useQuery } from "@apollo/client";
import MiniAuction from "../../components/customer/AuctionDetails/miniauction";
import { GET_AUCTIONS, AUCTION_TYPES } from "graphql/customer/queries/auction";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDeviceFingerprint } from "utils/functions";
import { useAuth } from "components/custom/authprovider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GET_AUCTIONS_PRIVATE } from "graphql/customer/queries/auction";

const { CardHeader, CardBody,  Card, Col, Row } = require("reactstrap");

function Home(){
  const [auctionlist, setAuctionList] = useState({});
  const [auctionTypeList, setAuctionTypeList] = useState();
  const [currentAuctionType, setCurrentAuctionType] = useState("0");
  const history = useHistory();
  const {wt}  = useAuth();

  let location = useLocation();

  //TODO: ADDITION
  const useConditionalQuery = () => {
    if (wt && wt.wt) {
    console.log("AuctionType", currentAuctionType)

      return useQuery(GET_AUCTIONS_PRIVATE, { 
        variables: {
          currencyid: (currentAuctionType !== "0" && currentAuctionType !== "all" && currentAuctionType !== "featured") ? currentAuctionType : '',
          featured: (currentAuctionType === "featured") ? true : false
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'cache-and-network',
        context: {
          headers: {
            authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
            'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
          },
        },
        onError: (errors) => {
          if (errors.networkError) {
            const serverError = errors.networkError.result.errors[0].message;
            if(serverError === "Authentication required."){
              history.push({pathname:"/logout"})
            }
          } 
        }
      });
    }
    else 
      return useQuery(GET_AUCTIONS);
  }
  const { loading, error, data, onError, refetch  } = useConditionalQuery();
    
  const { loading: l2, error: e2, data:d2, onError:oe2 } = useQuery(AUCTION_TYPES,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  useEffect(() => {
    if(location.state !== undefined){
      let tmp = location.state;
      if(tmp === "all") tmp = "";
      setCurrentAuctionType(tmp);
      refetch()        
    }
  }, [location.state, refetch]);

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        //TODO: ADDITION
        let TheAuctionData;
        if(data.getAuctionsPrivate) TheAuctionData = data.getAuctionsPrivate;
        else if(data.getAuctions) TheAuctionData = data.getAuctions;

        setAuctionList(() => TheAuctionData);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  useEffect(() => {
    const onCompleted = (d2) => { 
      if(d2)  setAuctionTypeList(() => d2.getAuctionCurrencies);
    }
    if (onCompleted || oe2) {
      if (onCompleted && !l2 && !e2) {
        onCompleted(d2);
      } else if (oe2 && !l2 && e2) {
        oe2(e2);
      }
    }
  }, [l2, d2, e2, oe2]);

  const removeAuctionFromList = (auctionid) => {
    console.log("REMOVING", auctionid);
    //TODO: Send Mutation to update AuctionID Active to False
  }

  return (
    <div className="content">
    <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <span className="pageHeader">
                { currentAuctionType && auctionTypeList &&
                  (currentAuctionType === "0" ? 'All' :
                  currentAuctionType === "all" ? 'All' : 
                  currentAuctionType === "featured" ? 'Featured' :
                  auctionTypeList.find((e)=>e.currencyid === currentAuctionType).name)
                } Auctions </span>
              <span className='floatRight'>
                <select className='form-control'
                style={{width:"200px",border: "1px solid #FFF", color:"#FFF", fontSize: "16px"}}
                  name="auctiontype"
                  onChange={ (e) => { 
                    setCurrentAuctionType(e.target.value);
                    refetch();
                  }}
                >
                  <option value='0'>Filter Auction Type</option>
                  <option value='all'>All Auctions</option>
                  <option value='featured'>Featured</option>
                  {auctionTypeList && 
                    auctionTypeList.map((c, key) => {
                      return(
                        <option key={key} value={c.currencyid}>{c.name}  ({c.count})</option>
                      )
                    })
                  }
                </select>
              </span>
            </CardHeader>
            <CardBody>
              <Row>
                {console.log("AUCTIONLIST", auctionTypeList)}
              {auctionlist.auctionlist && auctionTypeList &&
                auctionlist.auctionlist.map((value, key) => {
                  return (
                    <Col key={key}  sm="12" md="6" lg="3">
                      <MiniAuction key={key}  data = {value} serverTime = {auctionlist.servertime} auctionFinished = {removeAuctionFromList} />
                    </Col>
                  );
                })
              }
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Home;