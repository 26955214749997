import { gql } from '@apollo/client';

export const CREATE_AFFILATE = gql`
mutation createAffilate($data : AdminAffiliate!) {
  createAffilate(data: $data) 
}
`;

export const UPDATE_AFFILATE = gql`
mutation updateAffilate($data : AdminAffiliate!) {
  updateAffilate(data: $data) 
}
`;