import { gql } from '@apollo/client';

export const GET_AFFILATES = gql`
query getAdminAffilates {
  getAdminAffilates {
    name
    description
    active
    affilateid
    link
    datetimecreated
  }
}
`;