import React, { useContext } from 'react';
import { isTokenExpired } from 'utils/functions';
import Login from 'views/Login';
import AuthContext from './authcontext';
// ProtectedRoute(prop.component, prop.access, navbardata, setNavbarData)
const ProtectedRoute = (props) => {
  const TheComponent = props.Component;
  const authContext = useContext(AuthContext);
  if (!authContext.isAuthenticated || isTokenExpired(authContext.wt.dt) || !props.access.includes(authContext.userData.sstring)) {
    // console.log("Protected Route - isLoaded", authContext.isLoaded);
    // console.log("Protected Route - isAuth", authContext.isAuthenticated);
    // console.log("Protected Route - Expired", isTokenExpired(authContext.wt.dt));
    // console.log("Protected Route - Access",  props.access.includes(authContext.userData.sstring))
    // console.log("Protected Route - Access",  authContext.userData.sstring)
    // return <Login  NavBarData={props.navbardata} SetNavBarData={props.setNavbarData} />;
    return <Login  />;
  }
  // console.log("Protected Route - Component", Component);
  // console.log("AUTH CONTEXT", authContext)
  // return <TheComponent  NavBarData={props.navbardata} SetNavBarData={props.setNavbarData} />;
  return <TheComponent/>;
};

export default ProtectedRoute;