import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
mutation createCategory($data : AdminCategory!) {
  createCategory(data: $data) 
}
`;

export const UPDATE_CATEGORY = gql`
mutation updateCategory($data : AdminCategory!) {
  updateCategory(data: $data) 
}
`;