import { gql } from '@apollo/client';

export const CREATE_SHIPPING = gql`
mutation createShipping($data : AdminShipping!) {
  createShipping(data: $data) 
}
`;

export const UPDATE_SHIPPING = gql`
mutation updateShipping($data : AdminShipping!) {
  updateShipping(data: $data) 
}
`;