import { gql } from '@apollo/client';

export const GET_BALANCE_CREDITS = gql`
query getBalanceCredits($customerid : String) {
  getBalanceCredits(customerid: $customerid) {
    datetime
    amountafter
    amountbefore
    notes
    currency{
    name
  }
}
}
`;