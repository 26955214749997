import { useQuery } from "@apollo/client";
import { useAuth } from "components/custom/authprovider";
import { AUCTION_TYPES } from "graphql/customer/queries/auction";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDeviceFingerprint } from "utils/functions";

function AuctionTypes(props){
  const history = useHistory();
  const {wt}  = useAuth();

  const { loading: l2, error: e2, data:d2, onError:oe2 } = useQuery(AUCTION_TYPES,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [auctionTypeList, setAuctionTypeList] = useState([{}]);

  useEffect(() => {
    const onCompleted = (d2) => { 
      if(d2)  setAuctionTypeList(() => d2.getAuctionCurrencies);
    }
    if (onCompleted || oe2) {
      if (onCompleted && !l2 && !e2) {
        onCompleted(d2);
      } else if (oe2 && !l2 && e2) {
        oe2(e2);
      }
    }
  }, [l2, d2, e2, oe2]);

  return (
    <>
      {auctionTypeList && auctionTypeList.length > 0 &&
        <ul className="nav">
          <li className="nav-item" key="all" ><span className="nav-link" onClick={() => props.goto("/auctions", "all")}>All Auctions</span></li>
          <li className="nav-item" key="featured" ><span className="nav-link"onClick={() => props.goto("/auctions", "featured")}>Featured Auctions</span></li>
            {auctionTypeList && 
              auctionTypeList.map((c, key) => {
                return(
                  <li className="nav-item" key={key}><span className="nav-link" onClick={() => props.goto("/auctions", c.currencyid)}>{c.name}  ({c.count})</span></li>
                )
              })
            }
        </ul>
      }
    </>
  )
}

export default AuctionTypes;