import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Admin from "layouts/Admin/Admin.js";

import "assets/scss/gifteebids.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/custom.css";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from 'apollo-link-error';
import {SubDirectory} from "utils/defaults";
import AuthProvider from "components/custom/authprovider";

// import { createUploadLink } from 'apollo-upload-client'

let BaseURL = process.env.REACT_APP_APIURL + ":" + process.env.REACT_APP_SERVERPORT;
let WebSocketURL, HttpURL;


if(process.env.REACT_APP_APIURL === "localhost"){
  WebSocketURL = "ws://" + BaseURL + "/subscriptions";
  HttpURL = "http://" + BaseURL + "/graphql";
}
else{
  WebSocketURL = "wss://" + BaseURL + "/subscriptions";
  HttpURL = "https://" + BaseURL;
}

const wsLink = new WebSocketLink({
  uri: WebSocketURL,
  options: {
    reconnect: true,
  },
});

const httpLink = new HttpLink({
  uri: HttpURL,
  credentials: "include",
});


// const uploadLink = createUploadLink({
//   uri: 'http://localhost:9000/graphql',
//   headers: {
//     "keep-alive": "true",
//     "Content-Type": "application/json"
//   }
// })
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
  // uploadLink 
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors);
  }
  if (networkError) {
    console.log('networkError', networkError);
  }
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  errorLink
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route path={"/" + SubDirectory} render={(props) => <Admin {...props} />} />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  </ApolloProvider>,
)
// ReactDOM.render(
//   <ApolloProvider client={client}>
//         <BrowserRouter>
//           <Switch>
//             <Route path={"/" + SubDirectory} render={(props) => <Admin {...props} />} />
//           </Switch>
//         </BrowserRouter>
//   </ApolloProvider>,
//   document.getElementById("root")
// );
