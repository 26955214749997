import { gql } from '@apollo/client';

export const ADD_WATCHLIST = gql`
mutation addWatchList($auctionid : String!) {
  addWatchList(auctionid: $auctionid)
}
`;

export const DELETE_WATCHLIST = gql`
mutation deleteWatchList($watchlistid : String!) {
  deleteWatchList(watchlistid: $watchlistid) 
}
`;