import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { SubDirectory } from "utils/defaults";
import { SAlert } from "utils/functions";
import { SMSAlert } from "utils/defaults";
import { AppTitle } from "utils/defaults";
import { SAText } from "utils/functions";
import { CUSTOMER_LOGIN1 } from "graphql/customer/mutations/users";
import { CUSTOMER_LOGIN2 } from "graphql/customer/mutations/users";
import { getDeviceFingerprint } from "utils/functions";
import { APPCOOKIE } from "utils/defaults";
import { useAuth } from "components/custom/authprovider";
import { SimpleFormatPhone } from "utils/functions";
import { validatePhoneNumber } from "utils/functions";

function Login(props)  { 
const {setWT, setIsAuthenticated, setUserData}  = useAuth();
const [customerLogin1] = useMutation(CUSTOMER_LOGIN1); 
const [customerLogin2] = useMutation(CUSTOMER_LOGIN2); 
const [displayname, setUserName] = useState("");
const [loginmode, setLoginMode] = useState("phone")

let history = useHistory();

  const TryLogin = () => {
    if(displayname !== undefined && validatePhoneNumber(displayname)){
      customerLogin1({ variables: {loginHandle: displayname, loginmode: loginmode}})
      .then((r1) => {
        if(r1.data.customerLogin1.status){
          SendAlert()
        }
        else{
          SAText("Error", r1.data.customerLogin1.error, "error")
        }
      })
      .catch((err) => {
        SAText("Error", err, "error")
      })
      .finally(() =>{
        //TODO: set loading
      })
    }
    else{
      SAText("Invalid Phone Number", "A valid phone number is requried to login", "error")
    }
  }

  const SendAlert = () => {
    SAlert(SMSAlert.title, "text", SMSAlert.label, SMSAlert.icon, (v) => {
      customerLogin2({ variables: {loginHandle: displayname, loginmode: loginmode, code: v, fingerprint: getDeviceFingerprint()} })
      .then((r2) => {
        if(r2.data.customerLogin2.status){
          if(r2.data.customerLogin2.add){
            SAText("Additional Info Needed", "An account has been found for you but additional information is needed in order to continue", "success", () => {
              history.push('/' + SubDirectory + 'registration', { existing: JSON.parse(r2.data.customerLogin2.add) });
            })  
          }
          else{
            const TheCookie ={
              wt: r2.data.customerLogin2.token,
              dt: r2.data.customerLogin2.dt,
              displayname: r2.data.customerLogin2.displayname,
              sstring: r2.data.customerLogin2.sstring,
              currency: r2.data.customerLogin2.currency,
            }
            const token = localStorage.setItem( APPCOOKIE, JSON.stringify(TheCookie) );

            setWT({ 
              wt: r2.data.customerLogin2.token, 
              dt: r2.data.customerLogin2.dt 
            });
            setUserData({
              displayname: r2.data.customerLogin2.displayname, 
              currency: r2.data.customerLogin2.currency, 
              sstring: r2.data.customerLogin2.sstring
            })

            setIsAuthenticated({ token });

            let tmp = [];
            r2.data.customerLogin2.currency.map((value, key) => {
              return tmp.push(value.count + " " + value.name);
            })

            history.push("/" + SubDirectory + "auctions");
          }
        }
        else
          SAText("Error", r2.data.customerLogin2.error, "error", () => SendAlert())
      })
    })
  };
  const onEnter = (event) => event.key === 'Enter' && TryLogin()

    return (
        <div className="content card">
          <Row style={{'margin': '0'}}>
            <Col sm="12" md="6" lg="3" className="mw-Login">
              <Card className="auctionDetails">
                <CardHeader>
                  <CardTitle tag="h4">{AppTitle} Login</CardTitle>
                </CardHeader>
                <CardBody>
                      <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label className="subTitle">Phone</label>
                          <Input
                            placeholder="Phone"
                            type="text"
                            name="displayname"
                            autoComplete = "off"
                            onChange={e => setUserName(SimpleFormatPhone(e.target.value))}
                            value={displayname}
                            onKeyDown={onEnter}
                            autoFocus 
                            />
                        </FormGroup>
                      </Col>
                      </Row>
                     
                </CardBody>
                <CardFooter>

                    <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick={
                      () => { TryLogin() }
                    }
                    >
                      Login
                    </Button> 

                  <Button 
                  className="btn-fill" 
                  color="info" 
                  type="button" 
                  style={{float: 'right'}}
                  >
                    More Info
                  </Button>
                  <br style={{clear:'both'}} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }

export default Login;
