import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { formatDate } from "utils/date";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import AdminImage from "./images";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "graphql/admin/queries/categories";
import { handleInputFocus } from "utils/functions";
import { getDeviceFingerprint } from "utils/functions";
import { useAuth } from "components/custom/authprovider";

function AdminProduct(props){
  const {wt}  = useAuth();
  const [fielderrors, setFieldError] = useState([]);
  const [productinfo, setProductInfo] = useState({
    "name": "",
    "productcode": "",
    "longdescription": "",
    "shortdescription": "",
    "keywords": "",
    "qty": 1,
    "retailprice": 1,
    "active": true,
    "datetimecreated": "",
    "datetimeupdated": "",
    "lastupdatedby": "",
    "createdby": "",
    "category": "",
  })

  const { loading: cloading, error: cerror, data: cdata, onError: conerror } = useQuery( GET_CATEGORIES,{
    variables:{ignorebp: true},
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [categorylist, setCategoryList] = useState();

  useEffect(() => {
    const onCompleted = (cdata) => { 
      if(cdata){
        setCategoryList(() => cdata.getAdminCategories);
      }
    }
    if (onCompleted || conerror) {
      if (onCompleted && !cloading && !cerror) {
        onCompleted(cdata);
      } else if (conerror && !cloading && cerror) {
        conerror(cerror);
      }
    }
  }, [cloading, cdata, cerror, conerror]);
  
  useEffect(() => {
      if(props.data && props.mode === "edit"){
        setProductInfo(() => props.data);
      }
  }, [props.data, props.mode]);

  useEffect(() => {
    if(props.mode === "add"){
      setProductInfo({    
        "name": "",
        "productcode": "",
        "longdescription": "",
        "shortdescription": "",
        "keywords": "",
        "qty": 1,
        "retailprice": 1,
        "active": true,
        "datetimecreated": "",
        "datetimeupdated": "",
        "lastupdatedby": "",
        "createdby": "",
        "category": "",
      })
    }
}, [ props.mode]);

  const UpdateChange = (e, type = '') => {
    if(type !== "array"){
      let value = e.target.value;
      if(e.target.type === 'number'){
        if(value === '') value = 0;
        else value = parseFloat(value);
      }
  
      if(type === 'boolean') {
        if(typeof(value) === "string"){
          const a = (value === 'true')
          value = a;
        }
      }
  
      setProductInfo(prevState => {
        return { ...prevState, [e.target.name]: value}
      })
    }
    else{
      setProductInfo(prevState => {
        return { ...prevState, [e.name]:e.value}
      })   
    }
  }

  const CheckError = (e) =>{
    if(fielderrors.length > 0){
      const q = fielderrors.find((a)=>a.field === e);
      if(q) return q.error; else return false;
    }
    else return false;
  }

  const SubmitForm = () => {
    let valid = true;
    setFieldError(() => [])

    if(productinfo.name.trim() === "" || productinfo.name === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "name", error: "Name is required"}] })

    }
    if(productinfo.productcode.trim() === "" || productinfo.productcode === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "productcode", error: "Product Code is required"}] })
    }

    if(productinfo.longdescription.trim() === "" || productinfo.longdescription === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "longdescription", error: "Description is required"}] })
    }

    if(productinfo.shortdescription.trim() === "" || productinfo.shortdescription === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "shortdescription", error: "Description is required"}] })
    }

    if(productinfo.keywords.trim() === "" || productinfo.keywords === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "keywords", error: "Keywords are  required"}] })
    }

    if(productinfo.category.trim() === "" || productinfo.category === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "category", error: "Category is required"}] })
    }
    
    if(valid){
      props.FinalizeFunction(productinfo);
    }
  }

  useEffect(() => {
    if(props.customerrors !== undefined)
      setFieldError(prevState => { return [ ...prevState, props.customerrors] })
  }, [props.customerrors]);



  return (
    <div className="content">
      <Card className="auctionDetails">
        <CardHeader>
          <CardTitle tag="h4">{props.title} 
            <span style={{color:"lime", fontSize:"smaller"}}> {props.mode === "edit" ? "(Changing the status will not affect active auctions)" : "(Once the product is added, you will be able to go back and add images)"}</span>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {props.mode === "edit" &&
            <Row>
              <Col md="6">
                <p>Created By: <span>{productinfo.createdby}</span> <br /> {formatDate(productinfo.datetimecreated)}</p>
              </Col>
              <Col md="6">
                <p>Last Modified By: <span>{productinfo.lastupdatedby}</span> <br /> {formatDate(productinfo.datetimeupdated)}</p>
              </Col>
            </Row>
          }
        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Name:</label>
              <Input
                placeholder="Name"
                type="text"
                name="name"
                className={CheckError("name") ? 'error' : ''}
                value={productinfo.name}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => UpdateChange(e) }
              />
              <span className='errorMessage'>{CheckError("name") ? CheckError("name") : ''}</span>
            </FormGroup>
          </Col>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Product Code:</label>
              <Input
                placeholder="Code"
                type="text"
                name="productcode"
                className={CheckError("productcode") ? 'error' : ''}
                value={productinfo.productcode}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => UpdateChange(e) }
              />
              <span className='errorMessage'>{CheckError("productcode") ? CheckError("productcode") : ''}</span>
            </FormGroup>
          </Col>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Qty:</label>
              <Input
                placeholder="1"
                type="number"
                name="qty"
                className={CheckError("qty") ? 'error' : ''}
                value={productinfo.qty}
                onFocus={handleInputFocus}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => UpdateChange(e) }
              />
              <span className='errorMessage'>{CheckError("qty") ? CheckError("qty") : ''}</span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="subTitle"> * Short Description: </label>
              <Input
                placeholder="Short Description" 
                cols="80"
                rows="4"
                type="textarea"
                name="shortdescription"
                value={productinfo.shortdescription}
                autoComplete = "off"
                className={CheckError("shortdescription") ? 'error' : ''}
                onChange={ e => UpdateChange(e) }
                />
                <span className='errorMessage'>{CheckError("shortdescription") ? CheckError("shortdescription") : ''}</span>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="subTitle"> * Long Description: </label>
              <Input
                placeholder="Long Description" 
                cols="80"
                rows="4"
                type="textarea"
                name="longdescription"
                value={productinfo.longdescription}
                autoComplete = "off"
                className={CheckError("longdescription") ? 'error' : ''}
                onChange={ e => UpdateChange(e) }
                />
                <span className='errorMessage'>{CheckError("longdescription") ? CheckError("longdescription") : ''}</span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="subTitle"> * Keywords (Seperate with commas): </label>
              <Input
                placeholder="Keywords" 
                cols="80"
                rows="2"
                type="textarea"
                name="keywords"
                value={productinfo.keywords}
                autoComplete = "off"
                className={CheckError("keywords") ? 'error' : ''}
                onChange={ e => UpdateChange(e) }
                />
                <span className='errorMessage'>{CheckError("keywords") ? CheckError("keywords") : ''}</span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Category: </label>
                <select name="category" 
                  className={CheckError("category") ? 'form-control error' : 'form-control'}
                  onChange={ e => UpdateChange(e) }
                  value={productinfo.category}
                >
                <option value={""}>Select a Category</option>
                {categorylist && categorylist.length > 0 &&
                  categorylist.map((value, key) => {
                    return <option value={value.categoryid} key={key}>{value.name}</option>
                  })
                }
              </select>
              <span className='errorMessage'>{CheckError("category") ? CheckError("category") : ''}</span>
            </FormGroup>
            </Col>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Retail Price:</label>
              <Input
                placeholder="1"
                type="number"
                onFocus={handleInputFocus}
                name="retailprice"
                step="0.01"
                className={CheckError("retailprice") ? 'error' : ''}
                value={productinfo.retailprice}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => UpdateChange(e) }
              />
              <span className='errorMessage'>{CheckError("retailprice") ? CheckError("retailprice") : ''}</span>
            </FormGroup>
          </Col>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Status: </label>
              <select className='form-control'
                name="active"
                value={productinfo.active}
                onChange={ e => UpdateChange(e, "boolean") }
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </FormGroup>
            </Col>
        </Row>
        </CardBody>
        <CardFooter>
        <Button 
          className="btn-fill floatRight" 
          color="info" 
          type="button" 
          onClick = {(e) => SubmitForm()}
          >
            {props.mode === "add" ? "Add New Product" : "Update Product"}
          </Button>
        </CardFooter>
      </Card>
      {props.mode === "edit" &&  props.data.productid && <AdminImage productid = {props.data.productid} /> }
    </div>
  )
}

export default AdminProduct;