import { gql } from '@apollo/client';

export const UPDATED_CURRENCY = gql`
subscription currencyUpdate($displayname: String!) {
  currencyUpdate(displayname: $displayname) {
    currencyid
    value
    currencyname
  }
}
`;