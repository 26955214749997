
import RecentBids from "../../components/customer/Account/RecentBids";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/functions";
import ImageGallery from 'react-image-gallery';
import { makeid } from "utils/functions";
import ReactTooltip from "react-tooltip";
import { SERVERURL } from "utils/defaults";

const { CardHeader, CardBody,  Card, Col, Row, CardTitle, Button } = require("reactstrap");

function Checkout(){
  let location = useLocation();
  const [imgarray, setImgArray] = useState([]);
  const tooltipid = makeid(10);

  const GoToPurchase = () => {
    let GoID = 0;
    if(location.state.type === "auction") GoID = location.state.data.auctionid;
    else GoID = location.state.data.bidpackid;

    console.log("WAITING FOR PAYMENT GATEWAY", GoID);
  }

  useEffect(() => {
    if (location.state.data) {
      // setImgArray

      let ImgArray = [];
      if(location.state.type === "auction"){
        ImgArray.push({
          original: location.state.data.product.mainimgid ? SERVERURL + '/images/products/' + location.state.data.product.productid + "/" + location.state.data.product.mainimgid : SERVERURL + "/images/products/img-ph.jpg",
              thumbnail: location.state.data.product.mainimgid ? SERVERURL + '/images/products/' + location.state.data.product.productid + "/" + location.state.data.product.mainimgid : SERVERURL + "/images/products/img-ph.jpg",
          thumbnailWidth:"20px",
          // thumbnailHeight:"10px"
        })
      }
      else{
        //bidpacks
        ImgArray.push({
          original: SERVERURL + '/images/bidpacks/' + location.state.data.product.mainimgid,
          thumbnail: SERVERURL + '/images/bidpacks/' + location.state.data.product.mainimgid,
          thumbnailWidth:"20px",
          // thumbnailHeight:"10px"
        })
      }

      console.log(location.state.data.product.additionalimgids)
      if(location.state.data.product.additionalimgids !== "" && location.state.data.product.additionalimgids !== null){
        const aImgs = location.state.data.product.additionalimgids.split(",")

        aImgs.map(value => {
          return(
            ImgArray.push({
              original: SERVERURL + '/images/products/' + location.state.data.product.productid + "/" + value,
              thumbnail: SERVERURL + '/images/products/' + location.state.data.product.productid + "/" + value,
              thumbnailWidth:"20px",
              // thumbnailHeight:"10px"
            })
          )
        })
      }

      setImgArray(ImgArray)
    }
  }, [location.state.data, location.state.type]);

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <Card className='auctionDetails'>
          <CardHeader>
            <CardTitle tag="h4">{location.state.data.product && location.state.data.product.name}</CardTitle>
          </CardHeader>
            <CardBody>
            
              <img alt="Product Type" data-tip data-for={tooltipid} src={SERVERURL + "/images/currency/" + (location.state.type === "auction" ? location.state.data.biddingcurrency.imgid : location.state.data.currencyimg)} className='auctionType'  />
                <ReactTooltip id={tooltipid}>
                {location.state.type === "auction" ?
                  <span>This is a <span style={{color:'red'}}>{location.state.data.biddingcurrency && location.state.data.biddingcurrency.name.toUpperCase()} </span> auction. You will need a valid corresponding currency balance before placing a bid.</span>
                  :
                  <span>This is a <span style={{color:'red'}}>{location.state.data.currencyname && location.state.data.currencyname.toUpperCase()} </span> BidPack. This currency can be used with corresponding auctions.</span>
                }
                </ReactTooltip>

              <Row>
                <Col md="3" style={{paddingRight:"0px"}}>
                <ImageGallery items={imgarray} showPlayButton={false}  showNav={false}  />

                  <br style={{clear:"both"}} /><br />

                {location.state.type === "auction" &&
                <>
                  <p>
                    <span className='bold subTitle'>Status: </span>  
                    {location.state.data.status && location.state.data.status.name}
                  </p>

                  <p>
                    <span className='bold subTitle'>Start Time: </span>
                    {location.state.data.startdatetime && formatDate(location.state.data.startdatetime)}
                  </p>

                  <p>
                    <span className="bold subTitle">Final Price: </span>
                    {location.state.data.currentprice && formatNumber(location.state.data.currentprice / 100)}
                  </p>

                  <p>
                    <span className="bold subTitle">Retail Price: </span>
                    {location.state.data.product.retailprice && formatNumber(location.state.data.product.retailprice )}
                  </p>
                  
                  <p>
                    <span className="bold subTitle">Bid Multiplier: </span>
                    x {location.state.data.bidmultiplier && location.state.data.bidmultiplier}
                  </p>
                  <p>
                    <span className="bold subTitle">Winner: </span> {location.state.data.currentbuyer && location.state.data.currentbuyer.displayname}
                  </p>

                  <p className="time">
                    <span className="bold">Date Time Ended: </span>
                    <br />
                    {formatDate(location.state.data.enddatetime)}
                  </p>
                </>
                }

                {location.state.type === "bidpack" &&
                  <>
                    <p>
                      <span className="bold subTitle"> Price: </span>
                      {location.state.data.currentprice && formatNumber(location.state.data.currentprice / 100)}
                    </p>

                    <p>
                      <span className="bold subTitle">Amount of Bids: </span>
                      {location.state.data.amount && location.state.data.amount}
                    </p>
                  </>
                }

                </Col>

                <Col md="8" className="longdesc">
                  <p className='bold subTitle'>Full Description</p>
                  {location.state.data.product.longdescription && location.state.data.product.longdescription}

                  <hr />
                  <Row>
                    <Col  md="8">
                      <p>
                        <span className='bold subTitle'>Product Code</span> <br />
                        {location.state.data.product.productcode && location.state.data.product.productcode}
                      </p>

                      <p>
                        <span className='bold subTitle'>Keywords</span> <br />
                        {location.state.data.product.keywords && location.state.data.product.keywords}
                      </p>

                      <p>
                        <span className='bold subTitle'>Category</span> <br />
                        {location.state.type === "auction" ? location.state.data.product.category && location.state.data.product.category.name : "Bid Pack"}
                      </p>
                    </Col>

                    <Col md="4">
                      <p>
                        <span className='bold subTitle'>Shipping Information</span> <br />
                        {location.state.type === "auction" ?
                          <>
                            {location.state.data.shipping && location.state.data.shipping.name}
                            <span>{location.state.data.shipping && location.state.data.shipping.description}</span> <br />
                            <span>{location.state.data.shipping && formatNumber(location.state.data.shipping.rate)}</span> <br />
                          </>
                          :
                          "Free Digital Delivery"
                        }
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
    
          </Card>
        </Col>

        <Col md="4">
          <Row>
            <Col md="12">
              <Card className="auctionDetails">
                <CardHeader>
                  <CardTitle tag="h4">Checkout</CardTitle>
                </CardHeader>
                <CardBody>
                  <p>
                    <span className='bold subTitle'>{location.state.type === "auction" ? "Final Bid Price: " : "Price: " }</span>
                    {formatNumber(location.state.data.currentprice / 100)}
                  </p>

                  <p>
                    <span className='bold subTitle'>Shipping Price: </span>
                    {location.state.type === "auction" ? formatNumber(location.state.data.shipping.rate) : formatNumber("0")}
                  </p>

                  <p>
                    <span className='bold subTitle'>Total: </span>
                    {location.state.type === "auction" ? formatNumber(parseFloat(location.state.data.shipping.rate) + parseFloat(location.state.data.currentprice / 100)) : formatNumber(location.state.data.currentprice / 100)}
                  </p>

                  <Button 
                    className="btn-fill specialbutton" 
                    color="info" 
                    type="button" 
                    onClick={ () => GoToPurchase() }
                  >
                    Continue To Purchase
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop:"20px"}}>
            <Col md="12">
            {location.state.type === "auction" && location.state.data.auctionid && <RecentBids auctionid = {location.state.data.auctionid} mode="auction" /> }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Checkout;