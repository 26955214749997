import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
query getAdminCategories($ignorebp : Boolean) {
  getAdminCategories(ignorebp : $ignorebp) {
    name
    description
    active
    categoryid
    lastupdatedby
    createdby
    datetimeupdated
    datetimecreated
  }
}
`;