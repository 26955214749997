import { useMutation, useQuery } from "@apollo/client";
import MiniAuction3 from "../AuctionDetails/miniauction3";
import { DELETE_WATCHLIST } from "graphql/customer/mutations/watchlist";
import { GET_WATCHLIST } from "graphql/customer/queries/watchlist";
import React, { useEffect, useState } from "react";
import { getDeviceFingerprint } from "utils/functions";
import { useAuth } from "components/custom/authprovider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Col, Row, Button } = require("reactstrap");

function WatchList(){
  const history = useHistory();
  const {wt}  = useAuth();

  const { loading, error, data, onError, refetch } = useQuery(GET_WATCHLIST,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [auctionlist, setAuctionList] = useState({});

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setAuctionList(() => data.getWatchList);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  const removeAuctionFromList = (auctionid) => {
    console.log("REMOVING", auctionid);
    //TODO: Send Mutation to update AuctionID Active to False
  }

  const [deleteWatchList] = useMutation(DELETE_WATCHLIST, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  return (
    <div className="mw-box">
      <span className="ComponentHeading">Watch List</span>
        <Row>
          {auctionlist && auctionlist.auctionlist &&
            auctionlist.auctionlist.map((value, key) => {
              return (
                <Col key={key}  sm="12" md="12" lg="4">
                  <Button
                    className="btn-fill specialbutton" 
                    color="info " 
                    type="button" 
                    style={{marginTop:"22px"}}
                    onClick = {
                      (e) => {
                        deleteWatchList({variables:{watchlistid: value.watchlistid }})
                        .then((e) => {
                          refetch();
                        })
                      }
                    }
                  >Remove </Button>
                  <MiniAuction3 key={key}  data = {value} serverTime = {auctionlist.servertime} showBidButton={true} auctionFinished = {removeAuctionFromList} />
                </Col>
              );
            })
          }
      </Row>
    </div>
  )
}

export default WatchList;