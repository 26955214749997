import { gql } from '@apollo/client';

export const GET_AUCTIONS = gql`
query getAuctions($auctionid : String, $categoryid : String, $currencyid : String, $featured: Boolean, $limit: Float, $random: Boolean) {
  getAuctions(auctionid: $auctionid, categoryid: $categoryid, currencyid: $currencyid, featured: $featured, limit: $limit, random: $random) {
    auctionlist{
      auctionid
      product{
        productid
        name
        category{
          categoryid
          name
        }
        productcode
        shortdescription
        longdescription
        keywords
        retailprice
        qty
        mainimgid
        additionalimgids
      }
      shipping{
        shippingid
        name
        description
        rate
      }
      biddingcurrency{
        name
        imgid
      }
      currentbuyer{
        displayname
      }
      active
      currentprice
      status{
        name
      }
      startdatetime
      enddatetime
      bidmultiplier
      auctionmode{
        auctionmodeid
        name
        description
      }
      startingprice,
      featured
      buyitnow
      buyitnowprice
      finalized
    },
    servertime
    watchlistid
  }
}
`;

export const GET_AUCTIONS_PRIVATE = gql`
query getAuctionsPrivate($auctionid : String, $categoryid : String, $currencyid : String, $featured: Boolean, $limit: Float, $random: Boolean) {
  getAuctionsPrivate(auctionid: $auctionid, categoryid: $categoryid, currencyid: $currencyid, featured: $featured, limit: $limit, random: $random) {
    auctionlist{
      auctionid
      product{
        productid
        name
        category{
          categoryid
          name
        }
        productcode
        shortdescription
        longdescription
        keywords
        retailprice
        qty
        mainimgid
        additionalimgids
      }
      shipping{
        shippingid
        name
        description
        rate
      }
      biddingcurrency{
        name
        imgid
      }
      currentbuyer{
        displayname
      }
      active
      currentprice
      status{
        name
      }
      startdatetime
      enddatetime
      bidmultiplier
      auctionmode{
        auctionmodeid
        name
        description
      }
      startingprice,
      featured
      buyitnow
      buyitnowprice
      finalized
    },
    servertime
    watchlistid
  }
}
`;

export const AUCTION_TYPES = gql`
query getAuctionCurrencies{
  getAuctionCurrencies {
    name
		count
    currencyid
  }
}
`;

export const GET_RECENT_AUCTION_BIDS = gql`
query getRecentBids($auctionid : String!, $limit:Float!) {
  getRecentBids(auctionid: $auctionid, limit:$limit) {
    bid
    displayname
    datetime
  }
}
`;

export const GET_RECENT_CUSTOMER_BIDS = gql`
query getRecentBids( $limit:Float!) {
  getRecentBids(limit:$limit) {
  	bid
    displayname
    datetime
    bidtype
    product
  }
  
}
`;