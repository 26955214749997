import React, { useEffect, useState } from "react";
import { formatDuration, intervalToDuration } from 'date-fns'
import { normalize, subtract } from 'duration-fns';
import { checkDuration } from "utils/functions";
import { formatNumber } from "utils/functions";
import { UPDATED_AUCTION } from "graphql/customer/subscriptions/auction";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { PLACE_BID } from "graphql/customer/mutations/auction";
import { BidTimeout } from "utils/defaults";
import { useHistory } from "react-router-dom";
import { GET_AUCTIONS } from "graphql/customer/queries/auction";
import { makeid } from "utils/functions";
import ReactTooltip from "react-tooltip";
import { SERVERURL } from "utils/defaults";
import { getDeviceFingerprint } from "utils/functions";
import { useAuth } from "components/custom/authprovider";
import { SAText } from "utils/functions";
import { GET_AUCTIONS_PRIVATE } from "graphql/customer/queries/auction";

const { CardHeader, CardBody, CardFooter, Card, Col, Row, Button, CardTitle } = require("reactstrap");

function MiniAuction3(props){
  const history = useHistory();
  const {wt}  = useAuth();

  const useConditionalQuery = () => {
    if (wt && wt.wt) {
      return useQuery(GET_AUCTIONS_PRIVATE, { 
        variables: { auctionid: props.auctionid  },
        skip: props.auctionid ? false : true,
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'cache-and-network',
        context: {
          headers: {
            authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
            'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
          },
        },
        onError: (errors) => {
          if (errors.networkError) {
            const serverError = errors.networkError.result.errors[0].message;
            if(serverError === "Authentication required."){
              history.push({pathname:"/logout"})
            }
          } 
        }
      });
    }
    else 
      return useQuery(GET_AUCTIONS, {
        variables: { auctionid: props.auctionid  },
        skip: props.auctionid ? false : true,     
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'cache-and-network',  
      });
  }
  const {data: ad } = useConditionalQuery();

  const tooltipid = makeid(10);


  const [timeout, setTimeOut] = useState(false);
  const [timeouttime, setTimeOutTime] = useState(false);
  const [timeleft, setTimeLeft] = useState();
  const [auctioninfo, setAuctionInfo] = useState({});

  const { data } = useSubscription(UPDATED_AUCTION, {
    variables: { auctionid: props.auctionid  ? props.auctionid : auctioninfo.auctionid},
  });

  const [placeBid] = useMutation(PLACE_BID, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });
  
  const ViewAuctionDetails = (auctionid) =>{
    history.push({
      pathname: '/auction-details/' + auctionid,
      // state: {auctionid}
    })
  }

  const PlaceTheBid = () => {
    setTimeOutTime(BidTimeout);
    setTimeOut(true);

    placeBid({ variables: { auctionid: props.auctionid ? props.auctionid.toLowerCase() : auctioninfo.auctionid} })
    .then((res) => {
      if(!res.data.placeBid.status){
        SAText("Error", res.data.placeBid.error, "error")
      }
    })
  }

  useEffect(() => {
    if(ad){
      let TheAuctionData;
      if(ad.getAuctionsPrivate) TheAuctionData = ad.getAuctionsPrivate;
      else if(ad.getAuctions) TheAuctionData = ad.getAuctions;
    
      const startTime = new Date(ad.getAuctionsPrivate.servertime);
      const endTime = new Date(ad.getAuctionsPrivate.auctionlist[0].enddatetime);

      let duration = intervalToDuration({
        start: startTime, 
        end: endTime,
      })

      formatDuration(duration, { delimiter: ', ' })

      setTimeLeft(() => undefined);

      setAuctionInfo({
        "name": TheAuctionData.auctionlist[0].product.name,
        "shortdescription": TheAuctionData.auctionlist[0].product.shortdescription,
        "currentprice": TheAuctionData.auctionlist[0].currentprice,
        "bidmultiplier" : TheAuctionData.auctionlist[0].bidmultiplier,
        "currentbuyer" : (TheAuctionData.auctionlist[0].currentbuyer ? TheAuctionData.auctionlist[0].currentbuyer : ''),
        "auctionid": TheAuctionData.auctionlist[0].auctionid,
        "currencyname" : TheAuctionData.auctionlist[0].biddingcurrency.name,
        "currencyimg" : TheAuctionData.auctionlist[0].biddingcurrency.imgid,
        "mainimgid" : TheAuctionData.auctionlist[0].product.productid + "/" +TheAuctionData.auctionlist[0].product.mainimgid,
      })

      if(startTime.getTime() < endTime.getTime())
        setTimeLeft(() => duration);
      else
        setTimeLeft(() => undefined);
    }
  }, [ad]); //trigger - change to data for auction specific query

  useEffect(() => {
    if(props.data){

      const startTime = new Date(props.serverTime);
      const endTime = new Date(props.data.enddatetime);

      let duration = intervalToDuration({
        start: startTime, 
        end: endTime,
      })

      formatDuration(duration, { delimiter: ', ' })

      setTimeLeft(() => undefined);

      setAuctionInfo({
        "name": props.data.product.name,
        "shortdescription": props.data.product.shortdescription,
        "currentprice": props.data.currentprice,
        "bidmultiplier" : props.data.bidmultiplier,
        "currentbuyer" : (props.data.currentbuyer ? props.data.currentbuyer.displayname : ''),
        "auctionid": props.data.auctionid,
        "currencyname" : props.data.biddingcurrency.name,
        "currencyimg" : props.data.biddingcurrency.imgid,
        "mainimgid" : props.data.product.mainimgid ? props.data.product.productid + "/" + props.data.product.mainimgid : "./img-ph.jpg"
      })

      if(startTime.getTime() < endTime.getTime())
        setTimeLeft(() => duration);
      else
        setTimeLeft(() => undefined);
    }
  }, [props.data, props.serverTime]); //trigger - change to data for auction specific query

  useEffect(() => {
    if(timeleft !== undefined){
      if(checkDuration(timeleft)) {
        let timer = setTimeout( () => {
          setTimeLeft( () => normalize(subtract( timeleft, {seconds:1} )) ) ;

          if(timeouttime === 1 && timeout) setTimeOut(false);
          if(timeouttime <= BidTimeout && timeouttime > 0 && timeout) setTimeOutTime(prevState => { return prevState-1 } )
        }, 1000)

        return () => {
          clearTimeout(timer);
        }
      }
      else{
        setTimeLeft( () => undefined) ;
        // props.auctionFinished(props.auctionid ? props.auctionid :  auctioninfo.auctionid);
      }
    }
    
  }, [timeleft, timeout, timeouttime, props.auctionid, auctioninfo.auctionid]);

  useEffect(() => {
    if (data) {
        setAuctionInfo(prevState => { 
          return{ ...prevState, 
            currentbuyer:  data.newAuctionBid.currentbuyer,
            currentprice: data.newAuctionBid.currentprice,
          } 
      })
    }
  }, [ data]);


  return (
    <div className="content">
    <Row>
        <Col md="12">
          <Card className="miniAuction miniauction3"> 
            <CardHeader>
              <CardTitle tag="h4">{auctioninfo.name && auctioninfo.name}</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="floatLeft mainimg">
                <img alt = {"Giftee Bids - " + auctioninfo.name}  src={SERVERURL + "/images/products/" + auctioninfo.mainimgid}/>
              </div>
              <div className="floatRight desc" style={{textAlign: 'right'}}>
                
              <img alt = {auctioninfo.currencyname + " Auction"} data-tip data-for={tooltipid} src={SERVERURL + "/images/currency/" + auctioninfo.currencyimg} className='auctionType'  />
                <ReactTooltip id={tooltipid}>
                  This is a <span style={{color:'red'}}>{auctioninfo.currencyname && auctioninfo.currencyname.toUpperCase()} </span> auction. You will need a valid corresponding currency balance before placing a bid.
                </ReactTooltip>
                
                <span className="bold">Current Price: </span>
                {auctioninfo.currentprice && formatNumber(auctioninfo.currentprice / 100)}
                <br />
                <span className="bold">Bid Multiplier: </span>
                x {auctioninfo.bidmultiplier && auctioninfo.bidmultiplier}

                <p className="time">
                  <span className="bold">Time Remaining: </span>
                  <br />
                  {timeleft ?
                    timeleft.days + " Days, " + 
                    timeleft.hours + " Hours, " + 
                    timeleft.minutes + " Minutes, " + 
                    timeleft.seconds + " Seconds"
                    :
                    "Auction Ended"
                  }
                </p>
              </div>
              <br style={{clear:"both"}} />
             
            </CardBody>
            <CardFooter>
            <Row>
                <Col md="12" className="currentBuyer">
                  <span className="bold">Current Buyer: <br /> {auctioninfo.currentbuyer && auctioninfo.currentbuyer.displayname}</span>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  {timeleft && !timeout && wt && wt.wt && props.showBidButton &&
                    <Button 
                    className="btn-fill floatLeft" 
                    color="info" 
                    type="button" 
                    onClick = {(e) => PlaceTheBid()}
                    >
                      Bid
                    </Button>
                  }

                  {timeout && 
                    <></>
                    // <span className='timeout'> Processing </span>
                  }
                </Col>
              
                
                <Col md="6">
                  <Button 
                  className="btn-fill" 
                  color="info" 
                  type="button" 
                  onClick={ () => ViewAuctionDetails(auctioninfo.auctionid) }
                  >
                    View
                  </Button>
                </Col>
              </Row>

             
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default MiniAuction3;