import { gql } from '@apollo/client';

export const UPDATED_AUCTION = gql`
subscription newAuctionBid($auctionid: String!) {
  newAuctionBid(auctionid: $auctionid) {
    currentprice
    auctionid
    currentbuyer{
      displayname
    }
  }
}
`;