import { useMutation } from "@apollo/client";
import SideNav from "components/admin/shared/sidenav";
import { useState } from "react";
import { CREATE_AUCTION } from "graphql/admin/mutations/auctions";
import { UPDATE_AUCTION } from "graphql/admin/mutations/auctions";
import AuctionList from "components/admin/auction/auctionlist";
import AdminAuction from "components/admin/auction/auction";
import { useAuth } from "components/custom/authprovider";
import { getDeviceFingerprint } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { CardBody, Card, Col, Row, Button } = require("reactstrap");

function AdminAuctions(){
  const {wt}  = useAuth();
  const history = useHistory();
  const [view, setView] = useState("list");
  const [selectedRow, setSelectedRow] = useState();
  const [customerrors, setCustomErrors] = useState();

  const EditDetails = (data) => {
    setSelectedRow(data)
    setView("edit");
  }
  const [createAuction] = useMutation(CREATE_AUCTION, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [updateAuction] = useMutation(UPDATE_AUCTION, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const AddNew = (data) => {
    createAuction( { variables: 
      {
        data: {
          product: data.product,
          startdatetime: data.startdatetime,
          enddatetime: data.enddatetime,
          startingprice: parseFloat(data.startingprice) * 100,
          bidmultiplier: data.bidmultiplier,
          active: data.active,
          buyitnow: data.buyitnow,
          global: data.global,
          platform: data.platform,
          distlist: data.distlist,
          buyitnowprice: data.buyitnowprice,
          featured: data.featured,
          shipping: data.shipping,
          status: data.status,
          biddingcurrency: data.biddingcurrency,
          adminnotes: data.adminnotes,
          customerid: data.customerid,
        }
      } 
    } )
    .then((res) => {
      if(res.data){ 
        data.auctionid = res.data.createAuction.auctionid;
        data.adminbidders = res.data.createAuction.adminbidders;
        data.lastupdatedby = "";
        data.createdby = "";
        data.datetimelastupdated = Date().toLocaleString();
        data.datetimecreated = Date().toLocaleString();
        EditDetails(data)
      }
    })
  }

  const Edit = (data) => {
    console.log("EDIT");
    updateAuction( { variables: 
      {
        data: {
          product: data.product,
          startdatetime: data.startdatetime,
          enddatetime: data.enddatetime,
          startingprice: parseFloat(data.startingprice) * 100,
          buyitnowprice: data.buyitnow,
          bidmultiplier: data.bidmultiplier,
          active: data.active,
          buyitnow: data.buyitnow,
          buyitnowprice: data.buyitnowprice,
          featured: data.featured,
          shipping: data.shipping,
          status: data.status,
          biddingcurrency: data.biddingcurrency,
          adminnotes: data.adminnotes,
          auctionid: data.auctionid,
          customerid: data.customerid,
        }
      } 
    } )
    .then((res) => {
      if(res.data)
        setView("list")
    })
  }

  return (
    <div className="content card">
      <Row>
        <Col md="3" lg="2"> <SideNav /> </Col>
        <Col md="9" lg="10">
          <Row>
            <Col md="12">
              <Card className="auctionDetails">
                <CardBody>
                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick = {() => setView("add")}
                    >Add
                  </Button>

                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick = {() => setView("list")}
                    >View List
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              {
                view === "list" ? <AuctionList ViewDetails = { EditDetails } /> : 
                view === "add" ? <AdminAuction mode = "add" title = "Add New Auction" customerrors = {customerrors} FinalizeFunction = {AddNew} /> :
                view === "edit" ? <AdminAuction mode = "edit" title = "Edit Auction" customerrors = {customerrors} data = {selectedRow} FinalizeFunction = {Edit} /> :
                ""
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default AdminAuctions;