import { gql } from '@apollo/client';

export const ADD_AUTOBID = gql`
mutation addAutobidder($auctionid : String!, $count:Float!) {
  addAutobidder(auctionid: $auctionid, count:$count) 
}
`;

export const DELETE_AUTOBID = gql`
mutation deleteAutobidder($autobidderid : String!) {
  deleteAutobidder(autobidderid: $autobidderid) 
}
`;

export const UPDATE_AUTOBID = gql`
mutation updateAutobidder($autobidderid : String!, $count:Float!) {
  updateAutobidder(autobidderid: $autobidderid, count:$count) 
}
`;