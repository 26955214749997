import { useMutation } from "@apollo/client";
import AdminCategory from "components/admin/category/category";
import CategoryList from "components/admin/category/categorylist";
import SideNav from "components/admin/shared/sidenav";
import { useAuth } from "components/custom/authprovider";
import { UPDATE_CATEGORY } from "graphql/admin/mutations/categories";
import { CREATE_CATEGORY } from "graphql/admin/mutations/categories";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDeviceFingerprint } from "utils/functions";

const { CardBody, Card, Col, Row, Button } = require("reactstrap");

function Categories(){
  const [view, setView] = useState("list");
  const [selectedRow, setSelectedRow] = useState();
  const [customerrors, setCustomErrors] = useState();
  const {wt}  = useAuth();
  const history = useHistory();

  const EditDetails = (data) => {
    setSelectedRow(data)
    setView("edit");
  }
  const [createCategory] = useMutation(CREATE_CATEGORY, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const AddNew = (data) => {
    createCategory( { variables: 
      {
        data: {
          name:data.name,
          description: data.description,
          customerid: data.customerid,
          active: data.active
        }
      } 
    } )
    .then((res) => {
      if(res.data)
        setView("list")
    })
  }

  const Edit = (data) => {
    console.log("EDIT");
    updateCategory( { variables: 
      {
        data: {
          name:data.name,
          description: data.description,
          customerid: data.customerid,
          active: data.active,
          categoryid: data.categoryid
        }
      } 
    } )
    .then((res) => {
      if(res.data)
        setView("list")
    })
  }

  return (
    <div className="content card">
      <Row>
        <Col md="3" lg="2"> <SideNav /> </Col>
        <Col md="9" lg="10">
          <Row>
            <Col md="12">
              <Card className="auctionDetails">
                <CardBody>
                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick = {() => setView("add")}
                    >Add
                  </Button>

                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick = {() => setView("list")}
                    >View List
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              {
                view === "list" ? <CategoryList ViewDetails = { EditDetails } /> : 
                view === "add" ? <AdminCategory mode = "add" title = "Add New Category" customerrors = {customerrors} FinalizeFunction = {AddNew} /> :
                view === "edit" ? <AdminCategory mode = "edit" title = "Edit Category" customerrors = {customerrors} data = {selectedRow} FinalizeFunction = {Edit} /> :
                ""
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Categories;