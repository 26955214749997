import { useMutation, useQuery } from "@apollo/client";
import SideNav from "components/admin/shared/sidenav";
import { useState } from "react";
import { SAText } from "utils/functions";
import AdminCustomerList from "components/admin/customer/customerlist";
import AdminCustomerProfile from "components/admin/customer/profile";
import AdminRecentBids from "components/admin/customer/recentbids";
import AdminWonAuctions from "components/admin/customer/wonauctions";
import AdminAutobids from "components/admin/customer/autobids";
import AdminWatchlist from "components/admin/customer/watchlist";
import AdminCurrencyList from "components/admin/customer/currencylist";
import { DISABLE_CUSTOMER } from "graphql/admin/mutations/customer";
import AdminBalanceCredits from "components/admin/customer/balancecredits";
import { getDeviceFingerprint } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "components/custom/authprovider";
import { FINDUS } from "graphql/customer/queries/findus";

const { CardBody, Card, Col, Row, Button } = require("reactstrap");

function Customers(){
  const [view, setView] = useState("list");
  const [selectedRow, setSelectedRow] = useState();
  const [customerrors, setCustomErrors] = useState();
  const history = useHistory();
  const {wt}  = useAuth();

  const EditDetails = (data) => {
    setSelectedRow(data)
    setView("profile");
  }
  
  const [disableCustomer] = useMutation(DISABLE_CUSTOMER, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const {data} = useQuery(FINDUS,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const DisableCustomer = (data) => {
    disableCustomer({variables:{customerid: data.customerid, active: !data.active}})
    .then((e) => {
      setSelectedRow(prevState => {
        return { ...prevState, "active": !data.active}
      })
    } )
  }

  return (
    <div className="content card">
      <Row>
        <Col md="3" lg="2"> <SideNav /> </Col>
        <Col md="9" lg="10">
          <Row>
            <Col md="12">
              <Card className="auctionDetails">
                <CardBody>
                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick = {() => setView("list")}
                    >View List
                  </Button>

                  {view !== "list" &&
                  <>
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      onClick = {() => setView("profile")}
                      >Profile
                    </Button>
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      onClick = {() => setView("recentbids")}
                      >Recent Bids
                    </Button>
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      onClick = {() => setView("wonauctions")}
                      >Won Auctions
                    </Button>
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      onClick = {() => setView("autobidders")}
                      >Autobidders
                    </Button>
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      onClick = {() => setView("watchlist")}
                      >Watch List
                    </Button>
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      onClick = {() => setView("currencylist")}
                      >Currencies
                    </Button>
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      onClick = {() => setView("override")}
                      >Overrides
                    </Button>
                  </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              {
                view === "list" ? <AdminCustomerList ViewDetails = { EditDetails } /> : 
                view === "profile" ? <AdminCustomerProfile customerdata={selectedRow} DisableCustomer = { DisableCustomer } customerrors = {customerrors} findusReason = {data.getFindUs}  /> :
                view === "recentbids" ? <AdminRecentBids customerid={selectedRow.customerid} title={selectedRow.displayname + " (" + selectedRow.firstname + " " + selectedRow.lastname +")"} /> :
                view === "wonauctions" ? <AdminWonAuctions customerid={selectedRow.customerid}  title={selectedRow.displayname + " (" + selectedRow.firstname + " " + selectedRow.lastname +")"} /> :
                view === "autobidders" ? <AdminAutobids customerid={selectedRow.customerid}  title={selectedRow.displayname + " (" + selectedRow.firstname + " " + selectedRow.lastname +")"} /> :
                view === "watchlist" ? <AdminWatchlist customerid={selectedRow.customerid}  title={selectedRow.displayname + " (" + selectedRow.firstname + " " + selectedRow.lastname +")"} /> :
                view === "currencylist" ? <AdminCurrencyList customerid={selectedRow.customerid}  title={selectedRow.displayname + " (" + selectedRow.firstname + " " + selectedRow.lastname +")"} /> :
                view === "override" ? <AdminBalanceCredits customerid={selectedRow.customerid}  title={selectedRow.displayname + " (" + selectedRow.firstname + " " + selectedRow.lastname +")"} /> :
                ""
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Customers;