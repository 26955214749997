import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import {
  NavbarBrand,
  Navbar,
  Container,
  Button,
} from "reactstrap";
import { useAuth } from "components/custom/authprovider";

function AdminNavbar(props) {
  const [color, setcolor] = React.useState("navbar-black");
  const {userData, wt}  = useAuth();
  let history = useHistory();

  return (
    <>
      <Navbar id="mainNav" className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
            </div>
            <NavbarBrand onClick={(e) => e.preventDefault()}>
              <img alt="Giftee Bids Auction" src="/logo-tm.png" className="theLogo" />
            </NavbarBrand>
          </div>
            <div className='mw-profileStats'>
              <span className='mw-NavTitle'>
                {userData != null && userData.displayname && userData.displayname !== "" ? "Logged in as: " + userData.displayname : <span className='notLoggedInText'>Not Logged In. <br /> You must be logged in to be able to bid on auctions.</span> }
                {userData != null && userData.displayname && userData.displayname !== "" &&
                  <>
                  <br style={{clear:"both"}} />
                    <span className='nav-link floatLeft' onClick ={ () =>  history.push({ pathname: "/account"})}>View Account</span>
                    <span className='nav-link floatRight' onClick ={ () =>  history.push({ pathname: "/logout"})}>Logout</span>
                  </>
                }                       
                {userData != null && userData.currency && userData.currency.length > 0 &&
                  <div className='customerBalances'>
                    <select className='form-control'>
                      {
                        userData.currency.map((value, key) => {
                          return (<option value={value.currencyid} key={key}>{value.count + " " + value.name}</option>);
                        })
                      }
                    </select>
                  </div>
                }
                {userData.displayname === "" || !userData.displayname &&
                  <div className='notLoggedIn'>
                    <Button 
                      className="btn-fill" 
                      color="info" 
                      type="button" 
                      onClick ={ () =>  history.push({ pathname: "/login"})}
                    >
                      Login
                    </Button>
                    <Button 
                      className="btn-fill floatRight" 
                      color="info" 
                      type="button" 
                      onClick ={ () =>  history.push({ pathname: "/registration"})}
                    >
                      Register
                    </Button>
                  </div>
                } 
              </span> 
            </div>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
