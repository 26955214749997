import { gql } from '@apollo/client';

export const ADD_PRODUCT_IMAGE = gql`
mutation upload($file: Upload!, $productid: String!, $main: Boolean){
  upload(file: $file, productid: $productid, main: $main)
}
`;

export const CREATE_PRODUCT_INFO = gql`
mutation createProduct($data: AdminProduct!){
  createProduct(data: $data)
}
`;

export const UPDATE_PRODUCT_INFO = gql`
mutation updateProduct($data: AdminProduct!){
  updateProduct(data: $data)
}
`;

export const DELETE_IMAGE = gql`
mutation deleteProductImage($imageid: String!, $productid: String!, $main: Boolean){
  deleteProductImage(imageid: $imageid, productid: $productid, main: $main)
}
`;


export const UPDATE_MAIN_IMAGE = gql`
mutation updateMainImage($imageid: String!, $productid: String!){
  updateMainImage(imageid: $imageid, productid: $productid)
}
`;
