import { gql } from '@apollo/client';

export const GET_ADMIN_AUCTIONS = gql`
query getAdminAuctions {
  getAdminAuctions {
    auctionid
    startdatetime
    enddatetime
    startingprice
    buyitnowprice
    bidmultiplier
    active
    buyitnow
    featured
    global
    distlist
    platform
    shipping
    status
    biddingcurrency
    adminnotes
    currentbuyer
    finalized
    rejectionreason
    lastupdatedby
    currentprice
    productinfo{
      name
      longdescription
      keywords
      retailprice
      qty
      productcode
      productid
      categoryid
    }
    adminbidders{
      name
      displayname
      customerid
      assigned
      remainingbids
    }
    createdby
    datetimelastupdated
    datetimecreated
  }
}
`;

export const IMPORT_ACCOUNT_ADMIN = gql`
query getAAMDistListAdmin{
  getAAMDistListAdmin {
    data{
      distlist{
        name
        distributorid
        distributorcode
      }
    }
  }
}
`;