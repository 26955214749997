import React, { useEffect, useState } from "react";
import { formatDuration, intervalToDuration} from 'date-fns'
import { normalize, subtract } from 'duration-fns';
import { checkDuration } from "utils/functions";
import { formatNumber } from "utils/functions";
import { UPDATED_AUCTION } from "graphql/customer/subscriptions/auction";
import { useMutation, useSubscription } from "@apollo/client";
import { PLACE_BID } from "graphql/customer/mutations/auction";
import { BidTimeout } from "utils/defaults";
import { useHistory } from "react-router-dom";

import ReactTooltip from 'react-tooltip';
import { makeid } from "utils/functions";
import { SERVERURL } from "utils/defaults";
import { getDeviceFingerprint } from "utils/functions";
import { useAuth } from "components/custom/authprovider";
import { SAText } from "utils/functions";

const { CardHeader, CardBody, CardFooter, Card, Col, Row, Button, CardTitle } = require("reactstrap");

function MiniAuction(props){
  let history = useHistory();
  const {wt}  = useAuth();

  const tooltipid = makeid(10);

  const [timeleft, setTimeLeft] = useState();
  const [timeout, setTimeOut] = useState(false);
  const [timeouttime, setTimeOutTime] = useState(false);
  const [auctioninfo, setAuctionInfo] = useState({});
  
  const ViewAuctionDetails = (auctionid) =>{
    history.push({
      pathname: '/auction-details/' + auctionid,
      // state: {auctionid}
    })
  }

  const { data } = useSubscription(UPDATED_AUCTION, {
    variables: { auctionid: props.data.auctionid },
  });

  const [placeBid] = useMutation(PLACE_BID, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const PlaceTheBid = () => {
      setTimeOutTime(BidTimeout);
      setTimeOut(true);
      placeBid({ variables: {auctionid: props.data.auctionid.toLowerCase()} })
      .then((res) => {
        if(!res.data.placeBid.status){
          SAText("Error", res.data.placeBid.error, "error")
        }
      })
  }

  useEffect(() => {
    const startTime = new Date(props.serverTime);
    const endTime = new Date(props.data.enddatetime);

    let duration = intervalToDuration({
      start: startTime, 
      end: endTime,
    })

    formatDuration(duration, { delimiter: ', ' })

    setTimeLeft(() => undefined);

    setAuctionInfo({
      "name": props.data.product.name,
      "shortdescription": props.data.product.shortdescription,
      "currentprice": props.data.currentprice,
      "bidmultiplier" : props.data.bidmultiplier,
      "currentbuyer" : (props.data.currentbuyer ? props.data.currentbuyer : ''),
      "auctionid": props.data.auctionid,
      "currencyname" : props.data.biddingcurrency.name,
      "currencyimg" : props.data.biddingcurrency.imgid,
      "mainimgid" : props.data.product.mainimgid ? props.data.product.productid + "/" + props.data.product.mainimgid : "./img-ph.jpg"
    })

    if(startTime.getTime() < endTime.getTime())
      setTimeLeft(() => duration);
    else
      setTimeLeft(() => undefined);
  }, [props.serverTime,props.data.product.name, props.data.product.shortdescription, props.data.currentprice, props.data.bidmultiplier, props.data.currentbuyer, props.data.auctionid, props.data.biddingcurrency.name, props.data.biddingcurrency.imgid, props.data.enddatetime, props.data.product.mainimgid, props.data.product.productid ]);

  useEffect(() => {
    if(timeleft !== undefined){
      if(checkDuration(timeleft)) {
        let timer = setTimeout( () => {
          setTimeLeft( () => normalize(subtract( timeleft, {seconds:1} )) ) ;
            
          if(timeouttime === 1 && timeout) setTimeOut(false);
          if(timeouttime <= BidTimeout && timeouttime > 0 && timeout) setTimeOutTime(prevState => { return prevState-1 } )
        }, 1000)

        return () => {
          clearTimeout(timer);
        }
      }
      else{
        setTimeLeft( () => undefined) ;
        // props.auctionFinished(props.data.auctionid);
      }
    }
    
  }, [timeleft, timeout, timeouttime]);

  useEffect(() => {
    if (data) {
        setAuctionInfo(prevState => { 
          return{ ...prevState, 
            currentbuyer:  data.newAuctionBid.currentbuyer,
            currentprice: data.newAuctionBid.currentprice,
          } 
      })
    }
  }, [data]);

  
  return (
    <div className="content">
    <Row>
        <Col md="12">
          <Card className="miniAuction"> 
            <CardHeader>
              <CardTitle tag="h4">{auctioninfo.name && auctioninfo.name}</CardTitle>
            </CardHeader>
            <CardBody>
              <img alt = {"Giftee Bids - " + auctioninfo.name} src={SERVERURL + "/images/products/" + auctioninfo.mainimgid} className="floatLeft mainimg"  />

              <img alt = {auctioninfo.currencyname + " Auction"} data-tip data-for={tooltipid} src={SERVERURL + "/images/currency/" + auctioninfo.currencyimg} className='auctionType'  />
                <ReactTooltip id={tooltipid}>
                  This is a <span style={{color:'red'}}>{auctioninfo.currencyname && auctioninfo.currencyname.toUpperCase()} </span> auction. You will need a valid corresponding currency balance before placing a bid.
                </ReactTooltip>

              <span className='auctiondesc floatRight'>{auctioninfo.shortdescription && auctioninfo.shortdescription}</span>
              <br style={{clear:"both"}} />
              <div className='floatLeft'>
                <span className="bold">Current Price: </span>
                {auctioninfo.currentprice && formatNumber(auctioninfo.currentprice / 100)}
              </div>

              <div className='floatRight'>
                <span className="bold">Bid Multiplier: </span>
                x {auctioninfo.bidmultiplier && auctioninfo.bidmultiplier}
              </div>
              <br />
              
              <p className="time">
                <span className="bold">Time Remaining: </span>
                <br />
                {timeleft ?
                  timeleft.days + " Days, " + 
                  timeleft.hours + " Hours, " + 
                  timeleft.minutes + " Minutes, " + 
                  timeleft.seconds + " Seconds"
                  :
                  "Auction Ended"
                }
              </p>
            </CardBody>
            <CardFooter>
              <Row>
                <Col lg="12" className="currentBuyer">
                  <span className="bold">Current Buyer: {auctioninfo.currentbuyer && auctioninfo.currentbuyer.displayname}</span>
                </Col>
              </Row>

              <Row>
                <Col lg="6" md="6">
                  {timeleft && !timeout && wt && wt.wt &&
                    <Button 
                    className="btn-fill floatLeft" 
                    color="info" 
                    type="button" 
                    onClick = {(e) => PlaceTheBid()}
                    >
                      Bid
                    </Button>
                  }

                  {timeout && 
                    <></>
                    // <span className='timeout'> Processing </span>
                  }
                </Col>
            
                <Col lg="6" md="6">
                  <Button 
                  className="btn-fill" 
                  color="info" 
                  type="button" 
                  onClick={ () => ViewAuctionDetails(auctioninfo.auctionid) }
                  >
                    View
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default MiniAuction;