import { gql } from '@apollo/client';

export const GET_AFFILATES = gql`
query getAffilateLinks{
  getAffilateLinks {
    name
		link
    description
  }
}
`;