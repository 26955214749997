import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import { AppTitle } from "utils/defaults";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SAlert } from "utils/functions";
import { SMSAlert } from "utils/defaults";
import { useHistory } from "react-router-dom";
import Affilates from "components/customer/shared/affilates";
import { SAText } from "utils/functions";
import Customer from "components/customer/shared/customer";
import { FINDUS } from "graphql/customer/queries/findus";
import { PREREG1 } from "graphql/customer/queries/users";
import { REGISTER_CUSTOMER } from "graphql/customer/queries/users";
import { getDeviceFingerprint } from "utils/functions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { APPCOOKIE } from "utils/defaults";
import { useAuth } from "components/custom/authprovider";
import { SubDirectory } from "utils/defaults";
import { PUBLIC_SEND_SMS } from "graphql/customer/queries/users";

function Registration(props){
  const location = useLocation();
  const {setWT, setIsAuthenticated, setUserData, wt}  = useAuth();
  const [customerrors, setCustomErrors] = useState([]);
  let history = useHistory();
  const Goto = (page) =>{
    history.push({ pathname: page})
  }

  const [preReg1] = useLazyQuery(PREREG1, { fetchPolicy: 'no-cache' });
  const [registerCustomer] = useLazyQuery(REGISTER_CUSTOMER, { fetchPolicy: 'no-cache' });

  const [publicSendSMS] = useMutation(PUBLIC_SEND_SMS, {
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const {data} = useQuery(FINDUS,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const FinalizeRegisterCustomer = (userinfo, code) =>{
    registerCustomer({variables: {data: userinfo, code: code, fingerprint: getDeviceFingerprint()}})
    .then( (fData) => {
      if(fData.data.registerCustomer.status){
        const TheCookie ={
          wt: fData.data.registerCustomer.token,
          dt: fData.data.registerCustomer.dt,
          displayname: fData.data.registerCustomer.displayname,
          sstring: fData.data.registerCustomer.sstring,
          currency: fData.data.registerCustomer.currency,
        }
        const token = localStorage.setItem( APPCOOKIE, JSON.stringify(TheCookie) );

        setWT({ 
          wt: fData.data.registerCustomer.token, 
          dt: fData.data.registerCustomer.dt 
        });
        setUserData({
          displayname: fData.data.registerCustomer.displayname, 
          currency: fData.data.registerCustomer.currency, 
          sstring: fData.data.registerCustomer.sstring
        })

        setIsAuthenticated({ token });

        let tmp = [];
        fData.data.registerCustomer.currency.map((value, key) => {
          return tmp.push(value.count + " " + value.name);
        })

        SAText("Success", "Successfully Registered.", "success", () => history.push("/" + SubDirectory + "auctions"))
      }
      else
        SAText("Error", fData.data.registerCustomer.error, "error", () => CheckAccessCode(userinfo))
    })
    .catch((err) => {
      SAText("Error", err, "error")
    })
    .finally (() => {
      //TODO:set loading
    })
  }

  const CheckAccessCode = (userinfo) =>{
    SAlert(SMSAlert.title, "text", SMSAlert.label, SMSAlert.icon, (v) => {
      FinalizeRegisterCustomer(userinfo, v)
    })
  }

  // const VerifySMS = () => {
  //   SAlert(SMSAlert.title, "text", SMSAlert.label, SMSAlert.icon, (v) => {
  //     verifySMS({ variables: { code: v, type:"gifteeregistration"} })
  //     .then((r2) => {
  //       if(r2.data.verifySMS.status){
  //      ///TODO: What is this
  //       }
  //       else
  //         SAText("Error", r2.data.verifySMS.error, "error", () => ConfirmRejectAuction(auctionid, reason))
  //     })
  //   })
  // }

  const TryRegister = (userinfo) => {
    if(userinfo.existing){
      publicSendSMS({ variables: {type: "gifteeregistration", phone: userinfo.phone}})
      .then((smsres) => {
        if(smsres.data.publicSendSMS.status){
          SAlert(SMSAlert.title, "text", SMSAlert.label, SMSAlert.icon, (v) => {
            FinalizeRegisterCustomer(userinfo, v);
          })
        }
        else{
          SAText("Error", smsres.data.publicSendSMS.error, "error")
        }
      })
      .catch((err) => {
        SAText("Error", err, "error")          
      })
      .finally(() =>{
        //TODO: Set loading
      })
    }
    else{
      preReg1({variables: {displayname:userinfo.displayname, phone:userinfo.phone, email: userinfo.email}})
      .then((data1) => {
        if(data1.data.preReg1.status){
          CheckAccessCode(userinfo)
        }
        else{
          SAText("Error", data1.data.preReg1.error, "error")
        }
      })
      .catch((err1) => {
        SAText("Error", err1, "error")
      })
      .finally(() => { 
        //TODO: set loading
      })
    }
  }
  return (
    <div className="content card">
    <Row>
        <Col md="8">
          {data &&
            <Customer title={"Registration"} existing={location.state ? location.state.existing : null} findusReason = {data.getFindUs} FinalizeFunction = {TryRegister} customerrors={customerrors} mode={"register"} />
          }
        </Col>

        <Col md="4">
          <Card className='auctionDetails'>
            <CardHeader>
              <CardTitle tag="h4">Looking for something else?</CardTitle>
            </CardHeader>
            <CardBody>
              <p>If you are already registered with {AppTitle} and want to login, <span className='mw-navLink' onClick={() => Goto("login")}>click here</span></p>
              <p>To learn more about {AppTitle} check out our, <span className='mw-navLink' onClick={() => Goto("about")}>about section</span></p>
              <p>Maybe you're just looking for more information on how {AppTitle} works, check out our <span className='mw-navLink' onClick={() => Goto("help")}>help section</span></p>
              <p>If you have an inquiry and want to contact us, please fill out the form in our <span className='mw-navLink' onClick={() => Goto("contact")}>contact section</span></p>
              <p>Or Check out one of our affilates</p>
              
              <div className="linkAlt">
                <Affilates />
              </div>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Registration;