/*eslint-disable*/
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
import {
  BackgroundColorContext,
} from "contexts/BackgroundColorContext";
import { SubDirectory } from "utils/defaults";

function Sidebar(props) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  const { routes } = props;
  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="mwNav">
          <div className="sidebar bold" data={color}>
            <div className="sidebar-wrapper" >
              <Nav>
                {routes.map((prop, key) => {
                  const ToLink = prop.layout + SubDirectory + prop.path;
                  if(prop.showOnNav)
                  return (
                    <li
                      className={
                        activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                      }
                      key={key}
                    >
                      <NavLink
                        to={ToLink == "//" ? "/" : ToLink}
                        className="nav-link"
                        activeClassName="active"
                      >
                        <p>
                          <i className={prop.icon} /> 
                          {prop.name}
                        </p>
                      </NavLink>
                    </li>
                  );
                })}
              </Nav>
            </div>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Sidebar;
