import { gql } from '@apollo/client';

export const CUSTOMER_LOGIN1 = gql`
mutation customerLogin1($loginHandle : String!,$loginmode:String!) {
  customerLogin1(loginHandle: $loginHandle, loginmode:$loginmode) {
    status
    data
    error
  }
}
`;

export const CUSTOMER_LOGIN2 = gql`
mutation customerLogin2($loginHandle : String!, $loginmode : String!, $code : String!, $fingerprint : Float!) {
  customerLogin2(loginHandle: $loginHandle, code : $code, loginmode: $loginmode, fingerprint : $fingerprint) {
    status
    error
    token
    dt
    add
    sstring
    displayname
    currency{
      name
      count
      currencyid
    }
}
}
`;

export const UPDATE_CUSTOMER_PROFILE = gql`
mutation customerBasicProfileUpdate($data : CustomerProfileUpdate!) {
  customerBasicProfileUpdate(data: $data) {
    status
    data
    error
  }
}
`;

export const UPDATE_CUSTOMER_PROFILE_EMAILPHONE = gql`
mutation customerEmailPhoneProfileUpdate($changeWhat : String!, $code : String, $value: String!) {
  customerEmailPhoneProfileUpdate(changeWhat: $changeWhat, code : $code, value:$value) {
    status
    data
    error
  }
}
`;
