import { gql } from '@apollo/client';

export const GET_AUTOBID_DETAILS = gql`
query getAutobids($auctionid : String) {
  getAutobids(auctionid: $auctionid) {
    autobidid
    count
    auctionid
  }
}
`;

export const GET_CUSTOMER_AUTOBIDS = gql`
query getAutobids {
  getAutobids {
    autobidid
    product
    count
    auctionid
  }
}
`;

