import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import {stateArray, countryArray} from "utils/arrays";
import { formatDate } from "utils/date";
import React, { useEffect, useState } from "react";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import {  parseISO } from "date-fns";
import { DISABLE_CUSTOMER } from "graphql/admin/mutations/customer";
import { SAText } from "utils/functions";

function AdminCustomerProfile(props){

  const [fielderrors, setFieldError] = useState([]);
  const [userinfo, setUserInfo] = useState({
    "name": "",
    "address": "",
    "address2": "",
    "city": "",
    "state": "",
    "zip": "00000",
    "phone": "",
    "email": "",
    "displayname": "",
    "country": "US",
    "refererid": "",
    "emailoptin": false,
    "birthdate": "",
    "gender": "other",
    "password1": "",
    "password2": "",
    "findus": "",
    "active": true
  })

  
  useEffect(() => {
      if(props.customerdata){
        setUserInfo(() => props.customerdata);
        setUserInfo(prevState => {
          return { ...prevState, "customerid": props.customerdata.customerid, "password1": "", "password2": ""}
        })
      }
  }, [props.customerdata]);

  const UpdateCustomerOnChange = (e, type = '') => {
    if(type !== "array"){
      let value = e.target.value;
      if(e.target.type === 'number'){
        if(value === '') value = 0;
        else value = parseInt(value);
      }
  
      if(type === 'boolean') {
        if(typeof(value) === "string"){
          const a = (value === 'true')
          value = a;
        }
      }
  
      setUserInfo(prevState => {
        return { ...prevState, [e.target.name]: value}
      })
    }
    else{
      setUserInfo(prevState => {
        return { ...prevState, [e.name]:e.value}
      })   
    }
  }

  const CheckError = (e) =>{
    if(fielderrors.length > 0){
      const q = fielderrors.find((a)=>a.field === e);
      if(q) return q.error; else return false;
    }
    else return false;
  }

  return (
    <div className="content">
      <Card className='auctionDetails'>
        <CardHeader>
          <CardTitle tag="h4">{props.title}</CardTitle>
        </CardHeader>
        <CardBody>
        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* First Name:</label>
              <Input
                placeholder="First Name"
                type="text"
                name="firstname"
                value={userinfo.firstname}
                autoComplete = "off"
                readOnly = {true}
                maxLength={60}
              />
            </FormGroup>
          </Col>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Last Name:</label>
              <Input
                placeholder="Last Name"
                type="text"
                name="lastname"
                value={userinfo.lastname}
                autoComplete = "off"
                readOnly = {true}
                maxLength={60}
              />
            </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle"> * Display Name: </label>
              <Input
                placeholder="Display Name" 
                type="text" 
                name="displayname"
                value={ userinfo.displayname}
                autoComplete = "off"
                maxLength={60}
                readOnly = {true}
                />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Address:</label>
              <Input
                value={userinfo.address}
                placeholder="Address"
                type="text"
                name="address"
                autoComplete = "off"
                readOnly = {true}
                maxLength={400}
              />
            </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle">* City:</label>
              <Input
                value={userinfo.city}
                placeholder="City"
                type="text"
                name="city"
                readOnly = {true}
                autoComplete = "off"
                maxLength={60}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="subTitle">* State:</label>
              <select className={ ' form-control'}
                name="state"
                readOnly = {true}
                value={userinfo.state}
                onChange={ e => UpdateCustomerOnChange(e) }
                maxLength={2}
              >
                <option value="">Select State</option>
                {stateArray.map((value, key) => {
                  return (
                    <option value={value.abbreviation} key={key}>
                      {value.name}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Zip Code:</label>
              <Input
                placeholder="12345"
                type="number"
                name="zip"
                readOnly = {true}
                value={userinfo.zip}
                autoComplete = "off"
              />
            </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle"> Address 2: </label>
              <Input
                placeholder="Address 2" 
                type="text" 
                name="address2"
                readOnly = {true}
                value={userinfo.address2}
                autoComplete = "off"
                maxLength={400}
                />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="subTitle">* Country:</label>
              <select 
              className={' form-control'}
                name="country"
                readOnly = {true}
                maxLength={4}
              >
                {countryArray.map((value, key) => {
                  return (
                    <option value={value.code} key={key}>
                      {value.name}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Phone:</label>
              <Input
                placeholder="Phone"
                type="text"
                name="phone"
                readOnly = {true}
                value={userinfo.phone}
                autoComplete = "off"
                maxLength={20}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="subTitle">* Email:</label>
              <Input
                value={userinfo.email}
                placeholder="Email Address"
                type="text"
                name="email"
                readOnly = {true}
                autoComplete = "off"
                maxLength={100}
              />
            </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle"> Email Opt In: </label>
              <select className='form-control'
                name="emailoptin"
                readOnly = {true}
                value={userinfo.emailoptin}
              >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
              </select>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="pr-md-1" md="4">
          <FormGroup>
            <label className="subTitle"> * Birth Date </label>
            <Datetime 
              value={parseISO(userinfo.birthdate)}
              timeFormat = {false}
              inputProps={{readOnly: true}}
              onChange={(e) => {
                if(e._d !== undefined){
                  setUserInfo(prevState => {
                    return { ...prevState, birthdate: formatDate(e._d)}
                  })
                }
              }}
            /> 
          </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle"> Gender: </label>
              <select className='form-control'
                name="gender"
                readOnly = {true}
                value={userinfo.gender}
              >
              <option value="other">Other</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              </select>
            </FormGroup>
          </Col>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle">Referrer Username:</label>
              <Input
                value={userinfo.refererid}
                placeholder="Referrer"
                readOnly = {true}
                type="text"
                name="refererid"
                maxLength={60}
                autoComplete = "off"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col  md="4">
            <FormGroup>
              <label className="subTitle">* How did you find us?:</label>
              <select className={(CheckError("findus") ? 'error' : '') + ' form-control'}
                  name="findus"
                  value={userinfo.findus}
                  readOnly = {true}
                >
                <option value="">select an answer</option>
                {props.findusReason.map((value, key) => {
                  return(
                    <option value={value.name} key={key}>{value.name}</option>
                  )
                })}
              </select>
            </FormGroup>
          </Col>
        </Row>
        </CardBody>
        <CardFooter>
        <Button 
          className="btn-fill floatLeft" 
          color="info" 
          type="button" 
          onClick = {(e) => props.DisableCustomer({customerid: props.customerdata.customerid, active: props.customerdata.active})}
          >
            {props.customerdata.active ? "Disable" : "Enable"}
        </Button> <br style={{clear:"both"}}/>
        <span style={{color:"lime", fontSize:"smaller"}}>Current Status: {props.customerdata.active ? "Active" : "Inactive" }</span>


        </CardFooter>
      </Card>
    </div>
  )
}

export default AdminCustomerProfile;