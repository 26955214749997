import { gql } from '@apollo/client';

export const CREATE_CURRENCY = gql`
mutation createCurrency($data : AdminCurrency!) {
  createCurrency(data: $data) 
}
`;

export const UPDATE_CURRENCY = gql`
mutation updateCurrency($data : AdminCurrency!) {
  updateCurrency(data: $data) 
}
`;

export const ADD_CURRENCY_IMAGE = gql`
mutation uploadCurrencyImage($file: Upload!, $currencyid: String!){
  uploadCurrencyImage(file: $file, currencyid: $currencyid)
}
`;

export const DELETE_CURRENCY_IMAGE = gql`
mutation deleteCurrencyImage($imageid: String!, $currencyid: String!){
  deleteCurrencyImage(imageid: $imageid, currencyid: $currencyid)
}
`;