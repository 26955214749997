import { SubDirectory } from "utils/defaults";
import { useHistory } from "react-router-dom";
import { APPCOOKIE } from "utils/defaults";
import { useAuth } from "components/custom/authprovider";

function Logout(props)  { 
  const {setWT, setIsAuthenticated, setUserData}  = useAuth();

  let history = useHistory();
  localStorage.clear(APPCOOKIE);
  setIsAuthenticated(false)
  setWT(null);
  setUserData({});
  // window.location = "login";
  history.push("/" + SubDirectory + "login");

  return <></>
}

export default Logout;