import { useQuery } from "@apollo/client";
import XTable from "components/custom/table";
import { GET_PRODUCTS } from "graphql/admin/queries/products";
import { GET_CATEGORIES } from "graphql/admin/queries/categories";
import { formatNumber } from "utils/functions";
import React, { useEffect, useState } from "react";
import { getDeviceFingerprint } from "utils/functions";
import { useAuth } from "components/custom/authprovider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { CardHeader, CardBody, Card, CardTitle, Button } = require("reactstrap");

function ProductList(props){
  const {wt}  = useAuth();
  const [displaylist, setList] = useState();
  const [categorylist, setCategoryList] = useState();
  const [currentcategory, setCurrentCategory] = useState("all");
  const history = useHistory();

  const { loading, error, data, onError, refetch } = useQuery( GET_PRODUCTS,{
    variables:{categoryid: currentcategory, ignorebp: true},
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const { loading: cloading, error: cerror, data: cdata, onError: conerror } = useQuery( GET_CATEGORIES,{
    variables:{ ignorebp: true},
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  useEffect(() => {
    const onCompleted = (cdata) => { 
      if(cdata){
        console.log("CDATA", cdata);
        setCategoryList(() => cdata.getAdminCategories);
      }
    }
    if (onCompleted || conerror) {
      if (onCompleted && !cloading && !cerror) {
        onCompleted(cdata);
      } else if (conerror && !cloading && cerror) {
        conerror(cerror);
      }
    }
  }, [cloading, cdata, cerror, conerror]);

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setList(() => data.getAdminProducts);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);


  const listHeader = [
    {
      Header: "Name",
      accessor: "name",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Code",
      accessor: "productcode",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Category",
      accessor: "categoryname",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Price",
      accessor: "retailprice",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>{formatNumber(props.value)}</span>
      )
    },
    {
      Header: "Status",
      accessor: "active",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <span>
          {props.value ? "Active" : "Inactive"}
        </span>
      )
    },
    {
      Header: "Actions",
      accessor: "categoryid",
      className: "t-cell-3 text-centered mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (propsa) =>(
        <Button
        className="btn-fill specialbutton" 
        color="info" 
        type="button" 
        onClick ={ (e) => props.ViewDetails(propsa.row.original) }
        >Edit</Button>
      )
    },
  ];

  return (
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">
          Product List
          <span style={{float:"right"}}>
            {/* <label>Category: </label> */}
            {" "} <select name="selectcategory"
              className="form-control"
              onChange={ (e) => { 
              setCurrentCategory(e.target.value);
              refetch();
            }}>
              <option value={"all"}>All Products</option>
              {categorylist && categorylist.length > 0 &&
                categorylist.map((value, key) => {
                  return <option value={value.categoryid} key={key}>{value.name}</option>
                })
              }
            </select>
          </span>
        </CardTitle>
      </CardHeader>
      <CardBody className={ ' wonauctions'} >
        {displaylist &&
          <XTable columns={listHeader} loading={false} data={displaylist} filterbutton={false} />
        }
      </CardBody>
    </Card>
  )
}

export default ProductList;