import { useQuery } from "@apollo/client";
import { useAuth } from "components/custom/authprovider";
import { GET_AFFILATES } from "graphql/customer/queries/affilate";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDeviceFingerprint } from "utils/functions";

function Affilates(){
  const history = useHistory();
  const {wt}  = useAuth();

  const { loading, error, data, onError } = useQuery(GET_AFFILATES,{
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });

  const [affilates, setAffilates] = useState([]);
  // const [tooltipOpen, setToolTip] = useState([{}]);

  // const toggleTooltip = () =>{
  //   setToolTip(() => !tooltipOpen)
  // }

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setAffilates(() => data.getAffilateLinks);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  return (
    <>
      {affilates && affilates.length > 0 &&
        <ul className="nav">
          {affilates.map((value, key) => {
              return (
                <li className="nav-item" key={key} id={"Link" + key}>
                  <a title={value.description} className="nav-link" href={value.link} target="_blank" rel="noreferrer">
                    {value.name}
                  </a>
                  {/* <Tooltip placement="right" isOpen={tooltipOpen} target={"Link" + key} toggle={toggleTooltip}>
                    {value.description}
                  </Tooltip> */}
                </li>
              );
            })
          }
        </ul>
      }
    </>
  )
}

export default Affilates;