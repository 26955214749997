import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
query getAdminProducts($categoryid : String, $ignorebp: Boolean){
  getAdminProducts(categoryid : $categoryid, ignorebp: $ignorebp) {
    productid
    name
    shortdescription
    longdescription
    keywords
    qty
    retailprice
    productcode
    active
    categoryname
    category
    lastupdatedby
    createdby
    datetimeupdated
    datetimecreated
  }
}
`;

export const GET_PRODUCT_IMAGES = gql`
query getAdminProductImages($productid : String!){
  getAdminProductImages(productid : $productid) {
    mainimgid
    additionalimgids
  }
}
`;