import { gql } from '@apollo/client';

export const GET_FINDUS = gql`
query getAdminFindus {
  getAdminFindus {
    name
    description
    active
    findusid
    datetimecreated
  }
}
`;