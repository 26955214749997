import { gql } from '@apollo/client';

export const GET_STATUSES = gql`
query getAdminStatuses {
  getAdminStatuses {
    name
    description
    active
    statusid
    lastupdatedby
    createdby
    datetimeupdated
    datetimecreated
  }
}
`;