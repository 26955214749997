import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import request from "graphql-request";
import { useMutation, useQuery } from "@apollo/client";
import { SERVERURL } from "utils/defaults";
import { SAText } from "utils/functions";
import { ADD_CURRENCY_IMAGE } from "graphql/admin/mutations/currencies";
import { DELETE_CURRENCY_IMAGE } from "graphql/admin/mutations/currencies";
import { IMAGEUPLOAD } from "utils/defaults";
import { getDeviceFingerprint } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "components/custom/authprovider";

function AdminCurrencyImage(props){
  const [picerror, setPicError] = useState();
  const [currentpic, setCurrentPic] = useState();
  const history = useHistory();
  const {wt}  = useAuth();

  const hiddenFileInput = React.useRef(null);
  
  const [deleteCurrencyImage] = useMutation(DELETE_CURRENCY_IMAGE, {
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
  });


  const requestHeaders = {
    authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
    'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
  }

  const deleteImage = (imgid) => {
    deleteCurrencyImage( { variables: {imageid: imgid.toLowerCase(), currencyid: props.currencyid.toLowerCase()} } )
    .then((e)=> setCurrentPic(null))
  }

  const handleClick = event => hiddenFileInput.current.click();

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    if(fileUploaded.size < 20000000 && (fileUploaded.type === 'image/jpeg' || fileUploaded.type === 'image/jpg' || fileUploaded.type === 'image/png') ){
      request(
        IMAGEUPLOAD, 
        ADD_CURRENCY_IMAGE, 
        { file: fileUploaded, currencyid: props.currencyid},
        requestHeaders
      )
      .then((e)=> {
        setCurrentPic(e.uploadCurrencyImage)
      })
    }
    else
      setPicError("Image must be less than 20mb and be a jpg or png file")
  };



  useEffect(() => {
    console.log("PROPS", props)
    if(props.imgid !== undefined)
    setCurrentPic(props.imgid)
  }, [props.imgid]);

  return (
    <Row style={{marginTop:"20px"}}>
      <Col md="12">
        <Card className="auctionDetails" >
          <CardHeader>
            <CardTitle tag="h4">Image Mangement </CardTitle>
          </CardHeader>
          <CardBody>
            {props &&
              <Row>
                {currentpic != "" && currentpic && currentpic != null &&
                  <Col md="1">
                    <Button className="productImageDeleteButton" onClick={() => deleteImage(currentpic, true)}> Delete </Button>
                    <div className='mainproductimg'>
                      <img className = "productimg" src={SERVERURL + "/images/currency/" + currentpic} />
                    </div>
                  </Col>
                }
              </Row>
            }

            <br /><br />
            <Button onClick={handleClick}
              className="btn-fill specialbutton" 
              color="info" 
              type="button" >
              Upload a New Picture
            </Button>
            <input type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              accept="image/jpeg, image/jpg, image/png" 
              style={{display:'none'}} 
            /> 
            <br />
            <small style={{color:"lime"}}>JPG and PNG Files only. Max Size = 20 mb</small>
            <br />
            <span className='errorMessage'>{picerror}</span> <br />
          </CardBody>
        </Card>

       
      </Col>
    </Row>
  )
}

export default AdminCurrencyImage;