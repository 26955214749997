import Autobid from "../shared/autobids";

const { CardHeader, CardBody, Card, CardTitle } = require("reactstrap");

function Autobidder(props){
  return (
    <Card className='auctionDetails'>
      <CardHeader>
        <CardTitle tag="h4">AutoBid</CardTitle>
      </CardHeader>
      <CardBody>
        {props.active &&
        <>
          <p>
            Autobid allows you to set the number of times you wish you automatically place a bid on this auction after you are outbid by another user. The bid amount will be multiplied by the auction bid multiplier. 
          </p>
          <p>
            For example: If you want to place 10 bids and the auction bid multiplier is x2, you will spend a minimum of 2 bids and a maximum of 20 bids.
          </p>
          <p>
            In order for the autobids to be fulfilled, you must have valid balance of the auction bidding currency in your account.
          </p>
          <p>
            Once an autobid is added or updated, if you are not the current buyer of the auction, a bid will be initiated on the auction.
          </p>

          <Autobid auctionid = {props.auctionid} />
        </>
        }

        {!props.active &&
          <p>Autobidders can't be added to this auction because it has ended</p>
        }
      </CardBody>
  </Card>
  )
}

export default Autobidder;