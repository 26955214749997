import SideNav from "components/admin/shared/sidenav";

const { CardHeader, CardBody, CardFooter, Card, Col, Row, Button } = require("reactstrap");

function Reports(){
  return (
    <div className="content">
      <Row>
        <Col md="3" lg="2"> <SideNav /> </Col>
        <Col md="9" lg="10">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    >Report1
                  </Button>

                  <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    >Report2
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              <Card>
                <CardHeader></CardHeader>
                <CardBody></CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Reports;