import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { formatDate } from "utils/date";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { handleInputFocus } from "utils/functions";
import { GET_CURRENCIES } from "graphql/admin/queries/currencies";
import { useQuery } from "@apollo/client";
import AdminBidpackImage from "./image";
import { getDeviceFingerprint } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "components/custom/authprovider";
// import AdminBidpackImage from "./image";

function AdminBidpack(props){
  const history = useHistory();
  const {wt}  = useAuth();

  const [fielderrors, setFieldError] = useState([]);
  const [bidpackinfo, setBidpackInfo] = useState({
    "name": "",
    "shortdescription": "",
    "longdescription": "",
    "keywords": "",
    "productcode": "",
    "bidpackcurrency": "",
    "amount": 1,
    "price": 1,
    "active": true,
    "datetimecreated": "",
    "datetimeupdated": "",
    "bidpackid": "",
    "mainimgid": "",
  })
  
  useEffect(() => {
      if(props.data){
        setBidpackInfo(() => props.data);
      }
  }, [props.data]);

  useEffect(() => {
    if(props.mode === "add"){
      setBidpackInfo({    
        "name": "",
        "shortdescription": "",
        "longdescription": "",
        "keywords": "",
        "productcode": "",
        "bidpackcurrency": "",
        "amount": 1,
        "price": 1,
        "active": true,
        "datetimecreated": "",
        "datetimeupdated": "",
        "bidpackid": "",
        "mainimgid": "",
      })
    }
}, [ props.mode]);

const { loading: cloading, error: cerror, data: cdata, onError: conerror } = useQuery( GET_CURRENCIES,{
  notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: wt && wt.wt ? `Bearer ${wt.wt}` : '', // Include the token in the request header
        'X-Device-Fingerprint': getDeviceFingerprint(), // Include the device fingerprint in a custom header
      },
    },
    onError: (errors) => {
      if (errors.networkError) {
        const serverError = errors.networkError.result.errors[0].message;
        if(serverError === "Authentication required."){
          history.push({pathname:"/logout"})
        }
      } 
    }
});

const [currencylist, setCurrencyList] = useState();

useEffect(() => {
  const onCompleted = (cdata) => { 
    if(cdata){
      setCurrencyList(() => cdata.getAdminCurrencies);
    }
  }
  if (onCompleted || conerror) {
    if (onCompleted && !cloading && !cerror) {
      onCompleted(cdata);
    } else if (conerror && !cloading && cerror) {
      conerror(cerror);
    }
  }
}, [cloading, cdata, cerror, conerror]);


  const UpdateChange = (e, type = '') => {
    if(type !== "array"){
      let value = e.target.value;
      if(e.target.type === 'number'){
        if(value === '') value = 0;
        else value = parseInt(value);
      }
  
      if(type === 'boolean') {
        if(typeof(value) === "string"){
          const a = (value === 'true')
          value = a;
        }
      }
  
      setBidpackInfo(prevState => {
        return { ...prevState, [e.target.name]: value}
      })
    }
    else{
      setBidpackInfo(prevState => {
        return { ...prevState, [e.name]:e.value}
      })   
    }
  }

  const CheckError = (e) =>{
    if(fielderrors.length > 0){
      const q = fielderrors.find((a)=>a.field === e);
      if(q) return q.error; else return false;
    }
    else return false;
  }

  const SubmitForm = () => {
    let valid = true;
    setFieldError(() => [])

    if(bidpackinfo.name.trim() === "" || bidpackinfo.name === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "name", error: "Name is required"}] })
    }

    if(bidpackinfo.shortdescription.trim() === "" || bidpackinfo.shortdescription === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "shortdescription", error: "Description is required"}] })
    }
    
    if(bidpackinfo.longdescription.trim() === "" || bidpackinfo.longdescription === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "longdescription", error: "Description is required"}] })
    }

    if(bidpackinfo.keywords.trim() === "" || bidpackinfo.keywords === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "keywords", error: "Keywords are required"}] })
    }

    if(bidpackinfo.productcode.trim() === "" || bidpackinfo.productcode === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "productcode", error: "Product Code is required"}] })
    }

    if(bidpackinfo.bidpackcurrency.trim() === "" || bidpackinfo.bidpackcurrency === undefined) {
      valid = false;
      setFieldError(prevState => { return [ ...prevState, {field: "bidpackcurrency", error: "Currency is required"}] })
    }

    if(valid){
      props.FinalizeFunction(bidpackinfo);
    }
  }

  useEffect(() => {
    if(props.customerrors !== undefined)
      setFieldError(prevState => { return [ ...prevState, props.customerrors] })
  }, [props.customerrors]);

  return (
    <div className="content">
      <Card className="auctionDetails">
        <CardHeader>
          <CardTitle tag="h4">{props.title} </CardTitle>
        </CardHeader>
        <CardBody>
          {props.mode === "edit" &&
            <Row>
              <Col md="6">
                <p>Created: {formatDate(bidpackinfo.datetimecreated)}</p>
              </Col>
              <Col md="6">
                <p>Last Modified: {formatDate(bidpackinfo.datetimeupdated)}</p>
              </Col>
            </Row>
          }
        <Row>
          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Name:</label>
              <Input
                placeholder="Name"
                type="text"
                name="name"
                className={CheckError("name") ? 'error' : ''}
                value={bidpackinfo.name}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => UpdateChange(e) }
              />
              <span className='errorMessage'>{CheckError("name") ? CheckError("name") : ''}</span>
            </FormGroup>
          </Col>

          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Product Code:</label>
              <Input
                placeholder="Code"
                type="text"
                name="productcode"
                className={CheckError("productcode") ? 'error' : ''}
                value={bidpackinfo.productcode}
                autoComplete = "off"
                maxLength={60}
                onChange={ e => UpdateChange(e) }
              />
              <span className='errorMessage'>{CheckError("productcode") ? CheckError("productcode") : ''}</span>
            </FormGroup>
          </Col>

          <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Currency: </label>
                <select name="bidpackcurrency" 
                  className={CheckError("bidpackcurrency") ? 'form-control error' : 'form-control'}
                  onChange={ e => UpdateChange(e) }
                  value={bidpackinfo.bidpackcurrency}
                >
                <option value={""}>Select a Currency</option>
                {currencylist && currencylist.length > 0 &&
                  currencylist.map((value, key) => {
                    return <option value={value.currencyid} key={key}>{value.name}</option>
                  })
                }
              </select>
              <span className='errorMessage'>{CheckError("bidpackcurrency") ? CheckError("bidpackcurrency") : ''}</span>
            </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <label className="subTitle"> * Short Description: </label>
                <Input
                  placeholder="Short Description" 
                  cols="80"
                  rows="4"
                  type="textarea"
                  name="shortdescription"
                  value={bidpackinfo.shortdescription}
                  autoComplete = "off"
                  className={CheckError("shortdescription") ? 'error' : ''}
                  onChange={ e => UpdateChange(e) }
                  />
                  <span className='errorMessage'>{CheckError("shortdescription") ? CheckError("shortdescription") : ''}</span>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="subTitle"> * Long Description: </label>
                <Input
                  placeholder="Long Description" 
                  cols="80"
                  rows="4"
                  type="textarea"
                  name="longdescription"
                  value={bidpackinfo.longdescription}
                  autoComplete = "off"
                  className={CheckError("longdescription") ? 'error' : ''}
                  onChange={ e => UpdateChange(e) }
                  />
                  <span className='errorMessage'>{CheckError("longdescription") ? CheckError("longdescription") : ''}</span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label className="subTitle"> * Keywords (Seperate with commas): </label>
                <Input
                  placeholder="Keywords" 
                  cols="80"
                  rows="2"
                  type="textarea"
                  name="keywords"
                  value={bidpackinfo.keywords}
                  autoComplete = "off"
                  className={CheckError("keywords") ? 'error' : ''}
                  onChange={ e => UpdateChange(e) }
                  />
                  <span className='errorMessage'>{CheckError("keywords") ? CheckError("keywords") : ''}</span>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col className="pr-md-1" md="4">
              <FormGroup>
                <label className="subTitle">* Retail Price:</label>
                <Input
                  placeholder="1"
                  type="number"
                  onFocus={handleInputFocus}
                  name="price"
                  step="0.01"
                  className={CheckError("price") ? 'error' : ''}
                  value={bidpackinfo.price}
                  autoComplete = "off"
                  maxLength={60}
                  onChange={ e => UpdateChange(e) }
                />
                <span className='errorMessage'>{CheckError("price") ? CheckError("price") : ''}</span>
              </FormGroup>
            </Col>
            <Col className="pr-md-1" md="4">
              <FormGroup>
                <label className="subTitle">* Amount:</label>
                <Input
                  placeholder="1"
                  type="number"
                  onFocus={handleInputFocus}
                  name="amount"
                  step="0.01"
                  className={CheckError("amount") ? 'error' : ''}
                  value={bidpackinfo.amount}
                  autoComplete = "off"
                  maxLength={60}
                  onChange={ e => UpdateChange(e) }
                />
                <span className='errorMessage'>{CheckError("amount") ? CheckError("amount") : ''}</span>
              </FormGroup>
            </Col>
            <Col className="pr-md-1" md="4">
            <FormGroup>
              <label className="subTitle">* Status: </label>
              <select className='form-control'
                name="active"
                value={bidpackinfo.active}
                onChange={ e => UpdateChange(e, "boolean") }
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
        </CardBody>
        <CardFooter>
        <Button 
          className="btn-fill floatRight" 
          color="info" 
          type="button" 
          onClick = {(e) => SubmitForm()}
          >
            {props.mode === "add" ? "Add New Bidpack" : "Update Bidpack"}
          </Button>
        </CardFooter>
      </Card>

      {props.mode === "edit" &&  props.data.bidpackid && <AdminBidpackImage bidpackid = {props.data.bidpackid} mainimgid={bidpackinfo.mainimgid} /> }
    </div>
  )
}

export default AdminBidpack;